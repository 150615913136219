<template>
    <v-footer color="transparent" padless>
        <v-container class="pt-8">
            <v-row no-gutters>
                <v-flex xs12 md4 class="d-flex justify-center">
                    <div>
                        <ul class="menu-list">
                            <!-- <li>
                                <router-link class="mouse-hover hover-hide" to="/">Home</router-link>
                            </li> -->
                            <li v-for="(item, i) in menu" :key="i">
                                <router-link class="mouse-hover hover-hide" v-if="!item.dropdown" :to="item.to" v-text="item.name"></router-link>
                            </li>
                        </ul>
                    </div>
                </v-flex>
                <!-- <v-flex xs6 md4 class="d-flex justify-center" :class="!$vuetify.breakpoint.mdAndUp ? 'pt-4' : ''">
                    <div>
                        <ul class="menu-list">
                            <li v-for="(item, i) in secondary_menu" :key="i">
                                <router-link v-if="item.to" class="mouse-hover hover-hide" :to="item.to" v-text="item.name"></router-link>
                                <a v-else class="mouse-hover hover-hide" :href="item.href" v-text="item.name" target="blank"></a>
                            </li>
                        </ul>
                    </div>
                </v-flex> -->
                <v-flex xs12 md4 class="text-center">
                    <router-link to="/" class="home mouse-hover" style="height: 96px;">
                        <img class="mx-auto my-6 mouse-hover" style="height: 96px;" :src="require('@/assets/logo_white.svg')"/><br>
                    </router-link>
                    <!-- <v-btn icon x-large v-for="(network, i) in social" :key="i" class="social-icon mouse-hover hover-hide" color="white" :href="network.url" target="_blank"><v-icon>{{ network.icon }}</v-icon></v-btn> -->
                </v-flex>
                <v-flex xs12 md4 class="text-center">
                    <h5>“Supported by a grant from Iceland, Liechtenstein and Norway through the EEA Financial Mechanism 2014-2021, in the framework of the “Business Innovation Greece” Programme.”</h5>
                    <!-- <v-btn icon x-large v-for="(network, i) in social" :key="i" class="social-icon mouse-hover hover-hide" color="white" :href="network.url" target="_blank"><v-icon>{{ network.icon }}</v-icon></v-btn> -->
                </v-flex>
            </v-row>
            <v-row class="bottom-links">
                <v-flex>
                    <span class="mouse-hover">&copy; {{ new Date().getFullYear() }} {{ $appName }}</span>
                </v-flex>
            </v-row>
        </v-container>
    </v-footer>
</template>

<style scoped>
.v-footer {
    position: relative;
    z-index: 5;
    padding-top: 1rem;
}
.v-footer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0.2rem;
    width: 100%;
    background-color: var(--v-primary-base);
}
.v-footer .container {
    position: relative;
}

.menu-list {
    list-style: none;
    padding: 0;
}
.menu-title, .menu-list a, .bottom-links a {
    font-weight: 500;
    text-decoration: none;
    font-size: 1.1rem;
    line-height: 2.2rem;
}
.info-container {
    width: 100%;
}

.bottom-links {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}
.bottom-links .flex {
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 2.2rem;
    padding: 1rem;
}

.payments-container .everypay-logo {
    display: block;
    padding: 1rem;
    height: 4rem;
    object-fit: contain;
}
.payments-container .v-icon {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.8rem;
}
.payments-container .v-icon:last-of-type {
    margin-right: 0;
}
.social-icon .v-icon {
    font-size: 1.2rem;
}
</style>

<script>
export default {
    name: 'Footer',
    props: {
        menu: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        secondary_menu: [
            {
                name: 'Extra link 1',
                href: 'https://example.org'
            },
            {
                name: 'Extra link 2',
                href: 'https://example.org'
            },
        ],
        social: [
            {
                icon: 'fab fa-facebook',
                url: 'https://facebook.com/#'
            },
            {
                icon: 'fab fa-instagram',
                url: 'https://instagram.com/#'
            },
            {
                icon: 'fab fa-linkedin-in',
                url: 'https://linkedin.com/company/#/'
            }
        ]
    }),
    computed: {
        xmas () {
            return this.$store.state.xmas
        }
    }
}
</script>