<template>
    <div class="team mouse-hover hover-invert">
        <v-container>
            <v-layout row class="justify-center">
                <v-flex xs12>
                    <h1 class="text-center mb-8 mouse-hover hover-invert">Company Structure</h1>
                </v-flex>
            </v-layout>
            <v-layout row class="justify-center">
                <v-flex xs12>
                    <h2 class="primary--text text-center mb-4 mouse-hover hover-invert">Management</h2>
                </v-flex>
                <v-flex xs12 md4 v-for="(person, i) in management" :key="i">
                    <div class="person-container flippable">
                        <div class="flipper">
                            <v-card color="background" elevation="0" class="person">
                                <v-img :src="person.photo" height="10rem" width="10rem" class="mx-auto mouse-hover hover-hide"></v-img>
                                <v-card-title class="mouse-hover hover-hide">
                                    {{ person.name }} <v-btn v-if="person.linkedin" icon class="ml-2" :href="person.linkedin" target="_blank"><v-icon class="small-icon mouse-hover hover-hide">fab fa-linkedin</v-icon></v-btn>
                                </v-card-title>
                                <v-card-subtitle v-text="person.position" class="mouse-hover hover-hide"></v-card-subtitle>
                                <v-card-text v-if="person.short_bio" v-text="person.short_bio" class="mouse-hover hover-hide"></v-card-text>
                            </v-card>
                            <v-card color="background" elevation="0" class="person-bio">
                                <v-card-title v-text="person.name" class="mouse-hover hover-hide"></v-card-title>
                                <v-card-text v-text="person.bio" class="mouse-hover hover-hide"></v-card-text>
                            </v-card>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout row class="justify-center">
                <v-flex xs12>
                    <h2 class="primary--text text-center mb-4 mouse-hover hover-invert">Technical</h2>
                </v-flex>
                <v-flex xs12 md4 v-for="(person, i) in technical" :key="i">
                    <div class="person-container">
                        <v-card color="background" elevation="0" class="person">
                            <v-img :src="person.photo" height="10rem" width="10rem" class="mx-auto mouse-hover hover-hide"></v-img>
                            <v-card-title class="mouse-hover hover-hide">
                                {{ person.name }} <v-btn v-if="person.linkedin" icon class="ml-2" :href="person.linkedin" target="_blank"><v-icon class="small-icon mouse-hover hover-hide">fab fa-linkedin</v-icon></v-btn>
                            </v-card-title>
                            <v-card-subtitle v-text="person.position" class="mouse-hover hover-hide"></v-card-subtitle>
                            <v-card-text v-if="person.short_bio" v-text="person.short_bio" class="mouse-hover hover-hide"></v-card-text>
                        </v-card>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout row class="justify-center">
                <v-flex xs12>
                    <h2 class="primary--text text-center mb-4 mouse-hover hover-invert">Advisory</h2>
                </v-flex>
                <v-flex xs12 md4 v-for="(person, i) in advisory" :key="i">
                    <div class="person-container flippable">
                        <div class="flipper">
                            <v-card color="background" elevation="0" class="person">
                                <v-img :src="person.photo" height="10rem" width="10rem" class="mx-auto mouse-hover hover-hide"></v-img>
                                <v-card-title class="mouse-hover hover-hide">
                                    {{ person.name }} <v-btn v-if="person.linkedin" icon class="ml-2" :href="person.linkedin" target="_blank"><v-icon class="small-icon mouse-hover hover-hide">fab fa-linkedin</v-icon></v-btn>
                                </v-card-title>
                                <v-card-subtitle v-text="person.position" class="mouse-hover hover-hide"></v-card-subtitle>
                                <v-card-text v-if="person.short_bio" v-text="person.short_bio" class="mouse-hover hover-hide"></v-card-text>
                            </v-card>
                            <v-card color="background" elevation="4" class="person-bio">
                                <v-card-title v-text="person.name" class="mouse-hover hover-hide"></v-card-title>
                                <v-card-text v-text="person.bio" class="mouse-hover hover-hide"></v-card-text>
                            </v-card>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<style scoped>
.team {
    padding: 3rem 0;
}
.team h1, .team h2 {
    margin: 0 auto;
    width: fit-content;
}
.person-container {
    padding: 1rem;
}
.person, .person-bio, .v-image {
    border-radius: 12px !important;
}
.person .v-icon:hover {
    color: var(--v-primary-base);
}

.v-card__subtitle, .v-card__text {
    white-space: pre-line;
}

/* Flip Person Cards */
.person-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.person-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.person,
.person-bio {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.person-bio {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
  box-shadow: 0px 2px 4px -1px #fa7c03, 0px 4px 5px 0px #fa7c03, 0px 1px 10px 0px #fa7c03 !important;
  height: 100%;
  overflow-y: scroll;
  padding: 0.5rem;
}
.person-container.flippable:hover .person-bio {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  z-index: 3;
}
.person-container.flippable:hover .person {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.person {
  z-index: 2;
}
</style>

<script>
export default {
    name: 'Team',
    data: () => ({
        management: [
            {
                name: 'Nick Arapkoules',
                photo: require('@/assets/team/Nick_Arapkoules.jpg'),
                position: 'Chief Executive Officer',
                short_bio: 'Mechanical Engineer, NTUA\nForbes 30u30 Europe innovator',
                bio: 'Nick, has been the Director of Innovation for IKH SA from 2018 to 2021 and has been responsible for 14+ R&D projects (>3M€) portfolio management, overlooking the Product roadmap development and monitoring, IP and go-to-market strategy development.  He is an asset managing multi-disciplinary engineering teams in the fields of inspection robotics and industrial mechatronics with a proven record in B2B sales pipeline development and execution.\n\nHe was featured in Forbes 30 under 30 Europe 2016 list, for taking a project from personal to scalable, influencing EU industry on the way. He was also awarded best Business Plan of the year and funded by The Hellenic Initiative Hellenic Entrepreneurship Award (now Envolve Award) 2015.',
            },
            {
                name: 'Katerina Fotopoulou',
                photo: require('@/assets/team/Kat.jpg'),
                position: 'Chief Financial Officer',
                short_bio: 'MSc. Applied Economics and Finance AUEB',
                bio: 'Katerina, over the past decade she has worn multiple hats; started out as an external consultant, hired into the pre-sales department, promoted to project manager, and ended coordinating the financial aspects of R&D and EU projects.\n\nKaterina grew into the CFO position naturally, since she fully commands the financial strengths and weaknesses of our organization.\n\nShe received her BA in Management of Science & Technology and her MA in Applied Economics from the Athens University of Economics & Business.',
            },
            {
                name: 'Xenia Bania',
                photo: require('@/assets/team/xenia.jpeg'),
                position: 'Chief Marketing Officer, Business Development',
                short_bio: 'BSc. Shipping Economics and MSc. Applied Economics AUEB',
                // bio: 'BSc. Shipping Economics and MSc. Applied Economics AUEB'
            }
        ],
        technical: [
            {
                name: 'Michalis Logothetis',
                photo: require('@/assets/team/Logothetis.jpg'),
                position: 'Robotics Engineer',
                linkedin: 'https://linkedin.com/in/michalis-d-logothetis',
            },
            {
                name: 'Aggeliki Pilalitou',
                photo: require('@/assets/team/pilalitou.jpg'),
                position: 'Mechanical Engineer',
                linkedin: 'https://gr.linkedin.com/in/pilalitou',
            },
            {
                name: 'Konstantinos Alevizos',
                photo: require('@/assets/team/Alevizos.jpg'),
                position: 'Robotics Engineer',
                linkedin: 'https://www.linkedin.com/in/konstantinos-alevizos-89a963168/',
            },
            {
                name: 'Nikos Vellis',
                photo: require('@/assets/team/Vellis.jpg'),
                position: 'Mechanical Engineer',
                linkedin: 'https://linkedin.com/in/nikos-vellis-a2a87436/',
            },
            {
                name: 'Eleftherios Gryparis',
                photo: require('@/assets/team/eleftherios_gryparis.jpeg'),
                position: 'Robotics Integration Engineer',
                linkedin: 'https://www.linkedin.com/in/eleftherios-gryparis-9bb106196/',
            },
            {
                name: 'Thanos Sakelliou',
                photo: require('@/assets/team/Sakelliou.jpg'),
                position: 'Computer Vision Engineer',
                linkedin: 'https://linkedin.com/in/athanasios-sakelliou/',
            }
        ],
        advisory: [
            {
                name: 'Vincent Bretagne',
                photo: require('@/assets/team/team-9.png'),
                position: 'Consultant on Core process development and integration | Leaders and Teams development at Kinetic Consulting Ltd, Switzerland',
                bio: 'Vincent has been working for Nestlé for almost fourteen years, where he last held the position of Business Excellence Integration Lead for the coordination and integration of the Nestlé Business Excellence program across Nestlé in Switzerland for the implementation of the latest FMCG "End to End” processes, systems and organizations, supporting the E2Es (H2R, R2R, S2P, O2C, P2E and I2L) transformation.\n\nHe previously held the position of Head of Nestlé Business Services, Switzerland where the main realization was the implementation of the "Nestlé in the Market" concept for Switzerland to mutualize the various ISIT and back-office departments at Nestlé in Switzerland for a better strategic direction and for a rationalization of the resource utilization.\n\nVincent has been a mentor to the founding members of Scrufy, since the Mass Challenge Accelerator Program in 2020 and is an invaluable member of the team, with a strong passion for the benefits of the application of Scrufy.\n\nHe is acting as our Strategy Advisor with more than thirty years of operational experience, in management of support functions in international companies, steering transformation and continuous improvement, to assist us to progress towards our vision relying on people empowerment, promoting values such as trust in human capital, social responsibility and preservation of the environment for future generations.',
            }
            // {
            //     name: 'Evangelos Boutsianis',
            //     photo: require('@/assets/team/team-11.png'),
            //     position: 'Energy Performance Manager at THENAMARIS, Greece',
            //     bio: 'Evangelos is managing the Energy Performance department for a diverse fleet of more than eighty ships in operation, with experience from both industrial and academic positions with an emphasis on energy performance, optimization.\n\nHe has worked with and managed Maritime Advisory projects on ship energy efficiency both for ship newbuildings, retrofits and operational matters and has been involved in strategic Research & Innovation unit, managing Innovation Processes with the Norwegian Ship Classification Society (DNV).\n\nAs a senior researcher he participated in and managed several strategic research projects on technical innovation processes and novel technologies and was responsible  in writing and reviewing technical reports, journal publications and project proposals, as well as assisting in patent portfolio management and brings a wealth of experience to the table, to assist in the relative activities in Scrufy’s product roadmap.',
            // }
        ]
    })
}
</script>