<template>
    <div id="company-profile">
        <v-container>
            <h1 class="page-title mouse-hover hover-invert">Company Profile</h1>
            <div class="company-text">
                <h2 class="mouse-hover hover-invert" :class="$vuetify.breakpoint.mdAndUp ? 'text-justify' : 'text-center'">
                    <span>Scrufy</span> is a Deep Tech company, with the expressed objective of providing technology solutions, based on substantial engineering challenges for the deCarbonisation of the Shipping Industry.
                </h2>
            </div>
        </v-container>
        <v-container fluid class="timeline-container" ref="timelineContainer">
            <CompanyTimeline class="company-timeline" ref="timeline"></CompanyTimeline>
        </v-container>
        <Team></Team>
        <Video data-aos="fade" data-aos-duration="3000"></Video>
        <Contact></Contact>
    </div>
</template>

<style scoped>
#company-profile {
    padding-top: var(--abh);
}
.company-text {
    position: relative;
    color: var(--v-accent-base);
    margin: 1rem auto;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 500 !important;
    padding: 0.5rem;
    max-width: 720px;
}
.company-text > h2 {
    position: relative;
    z-index: 1;
}
.company-text:before {
    position: absolute;
    top: 25%;
    left: -20%;
    content: '';
    background-image: url('~@/assets/scrufy_shape.svg');
    background-position: left;
    background-size: contain;
    width: 100%;
    max-width: 320px;
    height: 100%;
    max-height: 320px;
    margin: 2rem;
    z-index: 0;
}
.company-text span {
    color: var(--v-primary-base);
	font-size: 3rem;
}

.timeline-container {
    position: relative;
    padding: 0;
    padding-top: var(--abh);
    margin: 0;
    height: calc(var(--vh, 1vh) * 500);
    transition: all 1s linear;

    /* background-color: yellow; */
}
.company-timeline {
    height: calc(var(--vh, 1vh) * 100 - var(--abh));

    /* background-color: red !important; */
}
.company-timeline.fixed {
    position: fixed;
    top: var(--abh);
    left: 0;
    width: 100%;
}
.company-timeline.absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
@media screen and (max-height: 900px) {
    .timeline-container {
        padding-top: 0;
    }
    .company-timeline {
        height: calc(var(--vh, 1vh) * 100);
    }
    .company-timeline.fixed {
        top: 0;
    }
}
</style>

<script>
import CompanyTimeline from '@/components/CompanyProfile/CompanyTimeline'
import Contact from '@/components/CompanyProfile/Contact'
import Team from '@/components/CompanyProfile/Team'
import Video from '@/components/CompanyProfile/Video'
export default {
    name: 'CompanyProfile',
    components: {
        CompanyTimeline,
        Contact,
        Team,
        Video
    },
    methods: {
        on_scroll () {
            let offsetTop = this.$refs.timelineContainer.offsetTop
            let offsetHeight = this.$refs.timelineContainer.offsetHeight
            let scrollY = window.scrollY
            let timeline_scroll = scrollY - offsetTop
            timeline_scroll = timeline_scroll > 0 ? timeline_scroll : 0
            let timeline_classes = this.$refs.timeline.$el.classList
            let scrollable = this.$refs.timeline.$el.getElementsByClassName('timeline')[0]
            let vh = window.innerHeight * 0.01

            // console.log(timeline_scroll / vh)

            let timelineScroll = (top, bottom) => {
                return (timeline_scroll >= top) && (timeline_scroll <= (bottom ? bottom : offsetHeight ))
            }

            if(timelineScroll(0.1, offsetHeight - (100 * vh))) {
                if(!timeline_classes.contains('fixed')) timeline_classes.add('fixed')
            } else {
                if(timeline_classes.contains('fixed')) timeline_classes.remove('fixed')
            }

            if(timelineScroll(400 * vh)) {
                if(!timeline_classes.contains('absolute')) timeline_classes.add('absolute')
            } else {
                if(timeline_classes.contains('absolute')) timeline_classes.remove('absolute')
            }

            if(timelineScroll(25 * vh)) {
                let result = ((timeline_scroll - 25 * vh) / (offsetHeight - 100 * vh - 25 * vh)) * scrollable.scrollWidth

                if (result <= 0) result = 0
                if (result >= scrollable.scrollWidth) result = scrollable.scrollWidth

                scrollable.scrollLeft = result

                this.$store.state.app_bar_visible = timelineScroll(25 * vh, offsetHeight - 100 * vh) ? window.innerHeight > 900 : true // Hide AppBar so it does not obstruct the timeline
            } else if (scrollY < offsetTop) {
                this.$store.state.app_bar_visible = true
                scrollable.scrollLeft = 0
            } else if (offsetTop + offsetHeight < scrollY) {
                this.$store.state.app_bar_visible = true
                scrollable.scrollLeft = scrollable.scrollWidth
            } else {
                this.$store.state.app_bar_visible = true
            }
        }
    }
}
</script>