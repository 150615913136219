import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Tech from '@/views/Tech'
import CompanyProfile from '@/views/CompanyProfile'
import Financials from '@/views/Financials'
import Demo from '@/views/Demo'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: "Home"
		}
	},
	{
		path: '/tech',
		name: 'Tech',
		component: Tech,
		meta: {
			title: "The Tech"
		}
	},
	{
		path: '/company',
		name: 'Company Profile',
		component: CompanyProfile,
		meta: {
			title: "Company Profile"
		}
	},
	{
		path: '/financials',
		name: 'Financials',
		component: Financials,
		meta: {
			title: "Financials"
		}
	},
	{
		path: '/demo',
		name: 'Demo',
		component: Demo,
		meta: {
			title: "Arrange A Demo"
		}
	}
]

const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title + " - " + Vue.prototype.$appName : Vue.prototype.$appName
	next()
})

export default router