<template>
  <v-app
    :class="
      `${$vuetify.breakpoint.smAndDown ? 'on-mobile' : ''}${
        drawer ? ' drawer-open' : ''
      }${app_bar_visible ? ' app-bar-visible' : ''}`
    "
  >
    <AppBar :menu="menu" :drawer="drawer" @drawer="drawer = true"></AppBar>
    <AppDrawer v-model="drawer" :menu="menu"></AppDrawer>
    <v-main class="background">
      <router-view ref="routerview" />
    </v-main>
    <ArrangeDemo v-if="$route.path != '/demo'"></ArrangeDemo>
    <Footer :menu="menu" ref="footer"></Footer>
    <span
      id="mouse"
      :class="
        `${mouse_follow.hover ? 'hover' : ''}${
          mouse_follow.invert ? ' invert' : ''
        }${mouse_follow.hide ? ' hide' : ''}`
      "
    ></span>
  </v-app>
</template>

<style>
html,
body,
.v-application {
  background: var(--v-background-base) !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  overflow-x: hidden;
}
@media screen and (max-width: 375px) {
  html,
  body,
  .v-application {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 320px) {
  html,
  body,
  .v-application {
    font-size: 14px !important;
  }
}

.v-application.drawer-open {
  overflow-y: hidden;
}

/* Helper Classes */
.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fill-width {
  width: 100%;
}
.small-icon {
  font-size: 0.8rem !important;
}

/* General Custom Styles */
a {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: var(--v-accent-base) !important;
  text-decoration: none;
}
a.hover-hide:hover {
  color: var(--v-primary-base) !important;
}
h1,
h2,
h3,
a,
.v-card__title {
  font-family: "Economica", sans-serif;
}
h1 {
  font-size: 3rem;
  font-weight: 400 !important;
}
h2 {
  font-size: 2rem;
  font-weight: 400 !important;
}
.v-btn {
  /* text-transform: none; */
  font-weight: 700 !important;
}
::selection {
  background-color: var(--v-primary-base) !important;
  color: var(--v-accent-base) !important;
  text-shadow: none !important;
}
.page-title {
  margin: 2rem auto;
  width: fit-content;
  color: var(--v-primary-base);
}

/* Custom Shaped Background */
.shaped-image {
  position: relative;
  margin: 3rem;
  border-radius: 24px;
  overflow: visible;
  max-width: 512px;
}
.shaped-image.glow {
  box-shadow: 0px 2px 4px -1px #333, 0px 4px 5px 0px #333, 0px 1px 10px 0px #333 !important;
}
.shaped-image > * {
  border-radius: inherit;
}
.shaped-image:before {
  content: "";
  position: absolute;
  top: 2rem;
  left: -2rem;
  width: 100%;
  height: 100%;
  border: 0.2rem solid var(--v-primary-base);
  border-radius: inherit;
  z-index: -1;
}
.shaped-image.right:before {
  left: auto;
  right: -2rem;
}

/* App Bar Background */
.v-application:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--abh);
  background-color: rgba(7, 28, 52, 0.8);
  transition: background-color 0.25s linear;
  z-index: 4;
}
.v-application:before,
.v-application .v-app-bar {
  opacity: 0;
  transition: opacity 1s linear;
}
.v-application.app-bar-visible:before,
.v-application.app-bar-visible .v-app-bar {
  opacity: 1;
}
.v-application.drawer-open:before {
  background-color: var(--v-background-base);
}

/* Footer Background */
.v-application:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--afh);
  background-color: var(--v-background-base);
  z-index: 3;
}

/* Custom Scrollbars */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  display: none;
}
::-webkit-scrollbar-track:hover {
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #fa7c03;
  border-radius: 7px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: #e67003;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

/* Input type number, do not display up-down arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Custom Mouse Circle */
#mouse {
  content: "";
  position: fixed;
  width: 48px;
  height: 48px;
  margin: -24px 0 0 -24px;
  /* border: 1px solid var(--v-primary-base); */
  background-color: var(--v-primary-base);
  /* background-color: #007ed3; */
  border-radius: 50%;
  pointer-events: none;
  transform: scale(1);
  transition: transform 0.15s ease;
  z-index: 4;
  display: none;
}
#mouse.hover {
  transform: scale(2);
}
#mouse.invert {
  z-index: 9;
  mix-blend-mode: difference;
}
#mouse.hide {
  transform: scale(0);
}

.v-btn.v-btn--outlined {
  overflow: hidden;
}
.v-btn.v-btn--outlined:hover > .v-btn__content {
  color: #ffffff;
}
.v-btn.v-btn--outlined:before {
  transform: scale(0);
  transition: transform 0.3s ease;
}
.v-btn.v-btn--outlined:hover:before {
  opacity: 1;
  transform: scale(1.2);
}
</style>

<script>
import AppBar from "@/components/Layout/AppBar";
import AppDrawer from "@/components/Layout/AppDrawer";
import ArrangeDemo from "@/components/ArrangeDemo";
import Footer from "@/components/Layout/Footer";
export default {
  name: "App",
  components: {
    AppBar,
    AppDrawer,
    ArrangeDemo,
    Footer,
  },
  data: () => ({
    drawer: false,
    mouse_follow: {
      mousePosX: 0,
      mousePosY: 0,
      mouseCircle: null,

      delay: 12,
      revisedMousePosX: 0,
      revisedMousePosY: 0,
      hover: false,
      invert: false,
      hide: false,
    },
    menu: [
      {
        name: "Home",
        to: "/",
      },
      // {
      // 	name: 'The Story',
      // 	to: '/story'
      // },
      {
        name: "The Tech",
        to: "/tech",
      },
      // {
      // 	name: 'Services',
      // 	to: '/services'
      // },
      {
        name: "Company Profile",
        to: "/company",
      },
      {
        name: "Financials",
        to: "/financials",
      },
      {
        name: "Arrange A Demo",
        to: "/demo",
        action: true,
      },
    ],
  }),
  computed: {
    app_bar_visible() {
      return this.$store.state.app_bar_visible;
    },
  },
  mounted() {
    this.calcViewportHeight();
    this.initMouseFollow();
    window.onscroll = (e) => {
      if (this.$refs.routerview.on_scroll) this.$refs.routerview.on_scroll();
    };
  },
  methods: {
    calcViewportHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      document.documentElement.style.setProperty(
        "--afh",
        `${this.$refs.footer.$el.offsetHeight}px`
      );

      document.documentElement.style.setProperty(
        "--abh",
        this.$vuetify.breakpoint.smAndDown
          ? `${this.$app_bar_height + 52}px`
          : `${this.$app_bar_height}px`
      );

      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        document.documentElement.style.setProperty(
          "--afh",
          `${this.$refs.footer.$el.offsetHeight}px`
        );

        document.documentElement.style.setProperty(
          "--abh",
          this.$vuetify.breakpoint.smAndDown
            ? `${this.$app_bar_height + 52}px`
            : `${this.$app_bar_height}px`
        );
      });
    },
    initMouseFollow() {
      this.mouse_follow.mouseCircle = document.getElementById("mouse");

      document.onmousemove = (e) => {
        if (
          (e.sourceCapabilities && e.sourceCapabilities.firesTouchEvents) ||
          typeof window.ontouchstart !== "undefined"
        )
          return;

        this.mouse_follow.mousePosX = e.x;
        this.mouse_follow.mousePosY = e.y;
        this.mouse_follow.mouseCircle.style.display = "block";
      };
      document.onmouseover = (e) => {
        if (
          (e.sourceCapabilities && e.sourceCapabilities.firesTouchEvents) ||
          typeof window.ontouchstart !== "undefined"
        )
          return;

        let link = this.checkTarget(e, "mouse-hover");

        if (link) {
          this.mouse_follow.invert = link.classList.contains("hover-invert");
          this.mouse_follow.hide = link.classList.contains("hover-hide");
          this.mouse_follow.hover = true;
        } else {
          this.mouse_follow.invert = false;
          this.mouse_follow.hide = false;
          this.mouse_follow.hover = false;
        }
      };
      this.delayMouseFollow();
    },
    delayMouseFollow() {
      requestAnimationFrame(this.delayMouseFollow);

      this.mouse_follow.revisedMousePosX +=
        (this.mouse_follow.mousePosX - this.mouse_follow.revisedMousePosX) /
        this.mouse_follow.delay;
      this.mouse_follow.revisedMousePosY +=
        (this.mouse_follow.mousePosY - this.mouse_follow.revisedMousePosY) /
        this.mouse_follow.delay;

      this.mouse_follow.mouseCircle.style.top =
        this.mouse_follow.revisedMousePosY + "px";
      this.mouse_follow.mouseCircle.style.left =
        this.mouse_follow.revisedMousePosX + "px";
    },
    checkTarget(e, className) {
      if (e.fromElement && e.fromElement.classList.contains(className)) {
        return e.fromElement;
      } else if (
        e.relatedTarget &&
        e.relatedTarget.classList.contains(className)
      ) {
        return e.relatedTarget;
      } else if (e.target && e.target.classList.contains(className)) {
        return e.target;
      } else {
        return null;
      }
    },
  },
};
</script>