<template>

<div style="margin-top:6em;padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/650440046?h=67684cf0b3&autoplay=1&loop=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
  
</template>

<style scoped>


</style>
<script src="https://player.vimeo.com/api/player.js"></script>    
<script>

export default {
  name: 'Video',
}
</script>