<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false"><v-icon>fas fa-times</v-icon></v-btn>
            </v-toolbar>
            <div class="pdf-viewer" ref="pdfViewer">
                <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i" :scale="scale" style="width:100%;margin:20px auto;" :annotation="true" :resize="true" @link-clicked="handle_pdf_link">
                    <template slot="loading">
                        loading content here...
                    </template>
                </pdf>
            </div>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.pdf-viewer {
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - 64px);
    overflow: auto;
    user-select: none;
}
</style>

<script>
import pdfvuer from 'pdfvuer'
export default {
    name: 'PDFDialog',
    components: {
        pdf: pdfvuer
    },
    props: {
        value: {
            type: Boolean,
            required: false
        },
        src: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false
        }
    },
    data: () => ({
        page: 1,
        numPages: 0,
        pdfdata: undefined,
        errors: [],
        scale: 0.5
    }),
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (v) {
                this.$emit('input', v)
            }
        },
        formattedZoom () {
            return Number.parseInt(this.scale * 100)
        },
    },
    watch: {
        dialog (v) {
            if (v) {
                document.documentElement.style.overflow = 'hide'
                this.getPdf()
            } else {
                document.documentElement.style.overflow = 'auto'
            }
            
        },
        show (s) {
            if(s) {
                this.getPdf()
            }
        },
        page (p) {
            if( window.pageYOffset <= this.findPos(document.getElementById(p)) || ( document.getElementById(p+1) && window.pageYOffset >= this.findPos(document.getElementById(p+1)) )) {
                // window.scrollTo(0,this.findPos(document.getElementById(p)))
                document.getElementById(p).scrollIntoView()
            }
        }
    },
    mounted () {
        if (this.dialog) {
            document.documentElement.style.overflow = 'hide'
            this.getPdf()
        } else {
            document.documentElement.style.overflow = 'auto'
        }
    },
    methods: {
        handle_pdf_link: function (params) {
            // Scroll to the appropriate place on our page - the Y component of
            // params.destArray * (div height / ???), from the bottom of the page div
            var page = document.getElementById(String(params.pageNumber))
            page.scrollIntoView()
        },
        getPdf () {
            var self = this;
            self.pdfdata = pdfvuer.createLoadingTask(this.src)
            self.pdfdata.then(pdf => {
                self.numPages = pdf.numPages
                this.$refs.pdfViewer.onscroll = () => {
                    // changePage()
                }

                function changePage () {
                    var i = 1, count = Number(pdf.numPages)
                    do {
                        if(window.pageYOffset >= self.findPos(document.getElementById(i)) && 
                            window.pageYOffset <= self.findPos(document.getElementById(i+1))) {
                        self.page = i
                        }
                        i++
                    } while ( i < count)
                    if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
                        self.page = i
                    }
                }
            })
        },
        findPos(obj) {
            return obj.offsetTop
        }
    }
}
</script>