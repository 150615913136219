<template>
    <div class="story" ref="story" :style="`opacity: ${background_opacity};` + ($vuetify.breakpoint.smAndDown ? ' padding-bottom: 50vh;' : '')">
        <div class="background"></div>
        <v-fade-transition>
            <div v-if="problem || $vuetify.breakpoint.smAndDown" class="problem">
                <v-container>
                    <div class="description text-center mx-auto">
                        <h1 data-aos="fade-up" data-aos-duration="1000" class="mouse-hover hover-hide">
                            The Problem.
                        </h1>
                        <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" class="mouse-hover hover-hide text-justify">
                            The problem we challenge and address, is the natural consequence of life. Marine micro organisms shall attach themselves and populate the exposed areas of the underwater hull of ships and colonize this territory and grow.. A process referred to as biofouling. 
                        </p>
                        <!-- <v-btn rounded large outlined color="primary" class="mt-4 mouse-hover hover-hide" to="/story"
                            data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">Learn More</v-btn> -->
                    </div>
                </v-container>
            </div>
        </v-fade-transition>
        <div class="solution" :style="$vuetify.breakpoint.smAndDown ? `position: fixed; top: ${solution_top ? ('calc((' + (parseInt(solution_top) <= -100 ? '100%' : solution_top) + ') * -1)') : '100%'};` : `left: ${solution_left}`">
            <!-- <div class="solution" :style="$vuetify.breakpoint.smAndDown ? `position: fixed; top: ${solution_top ? ('calc((' + solution_top + ' + 0.5rem) * -1)') : '100%'};` : `left: -${solution_left}`"> -->
            <div class="background" :style="$vuetify.breakpoint.smAndDown ? `top: calc(${solution_top})` : `left: -${solution_left}`"></div>
            <video playsinline loop muted autoplay ref="solutionVideo" @canplay="solution_can_play = true">
                <source :src="require('@/assets/the_solution.mp4')" type="video/mp4">
            </video>
            <div class="shade"></div>
            <v-container>
                <div class="description text-center mx-auto">
                    <h1 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1000" class="mouse-hover hover-hide">
                        The Solution.
                    </h1>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1000" class="mouse-hover hover-hide text-justify">
                        The answer is SCRUFY – a robotic Energy Saving Device addressing the carbon intensity of the vessel - by providing optimum hull performance; as an integral part of each and every ship, performing autonomous and continuous monitoring and hull grooming.<br>
                        By effectively monitoring and addressing biofouling, in the micro stages of development. It does not require capture and disposal, resulting in no risk of invasive species and no risk from biocide release from the coating. In addition, it acts in synergy with the hull coatings by removing silt, organics and incipient micro fouling, maintaining the coating function.
                    </p>
                    <!-- <v-btn rounded large outlined color="primary" class="mt-4 mouse-hover hover-hide" to="/story"
                        data-aos="fade-up" data-aos-duration="1000" data-aos-delay="1000">Learn More</v-btn> -->
                </div>
            </v-container>
            <!-- <v-btn v-if="solution_play && !$refs.solutionVideo.paused" icon x-large outlined color="primary" class="mouse-hover hover-hide" style="background-color: var(--v-accent-base);" @click="$refs.solutionVideo.pause()"><v-icon>fas fa-pause small-icon</v-icon></v-btn>
            <v-btn v-else-if="solution_play && $refs.solutionVideo.paused" icon x-large outlined color="primary" class="mouse-hover hover-hide" style="background-color: var(--v-accent-base);" @click="$refs.solutionVideo.play()"><v-icon>fas fa-play small-icon</v-icon></v-btn> -->
        </div>
        <FadeDelay v-if="chart_visible && $vuetify.breakpoint.mdAndUp" :delay="0">
            <div class="chart">
                <v-container class="fill-height center-all">
                    <div class="labels">
                        <v-container class="text-center">
                            <v-chip class="ma-2" color="white black--text">
                                <v-icon color="#4527A0" left>fas fa-circle</v-icon>
                                <span style="font-size: 0.8rem;">Propulsion Power Increase over Time</span>
                            </v-chip>
                            <v-chip class="ma-2" color="white black--text">
                                <v-icon color="#fe0100" left>fas fa-circle</v-icon>
                                <span style="font-size: 0.8rem;">Typical Threshold of Ship Energy Management Plan (SEEMP)</span>
                            </v-chip>
                            <v-chip class="ma-2" color="white black--text">
                                <v-icon color="#fa7c03" left>fas fa-circle</v-icon>
                                <span style="font-size: 0.8rem;">Excess CO2 emissions and Fuel Over Consumption</span>
                            </v-chip>
                            <v-fade-transition>
                                <v-chip v-if="parseInt(solution_left) < 50" class="ma-2" color="white black--text">
                                    <v-icon color="#86c954" left>fas fa-circle</v-icon>
                                    <span style="font-size: 0.8rem;">Savings in CO2 emissions and Fuel Savings</span>
                                </v-chip>
                            </v-fade-transition>
                        </v-container>
                    </div>
                    <PowerIncreaseChart :side="chart_side" style="max-width: 960px;" ref="PowerIncreaseChart"></PowerIncreaseChart>
                </v-container>
            </div>
        </FadeDelay>
    </div>
</template>

<style scoped>
.story {
    position: relative;
    height: calc(var(--vh, 1vh) * 400);
    /* background-color: var(--v-background-base); */
    margin-bottom: calc(var(--vh, 1vh) * 50);
}
.on-mobile .story {
    height: auto;
    min-height: calc(var(--vh, 1vh) * 300);
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(~@/assets/the_monster.jpg);
    background-size: cover;
    background-position: center;
}

.problem, .solution {
    position: fixed;
    top: 0;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: var(--abh);
}
.on-mobile .problem, .on-mobile .solution {
    position: relative;
}
.problem {
    left: 0;
    width: 100%;
}
.solution {
    left: 100%;
    width: calc(100% + 0.2rem);
    overflow: hidden;
    /* transition: left 0.05s linear, top 0.05s linear; */
    /* background-image: url(~@/assets/the_solution.jpg); */
    /* background-size: cover; */
    /* background-position: center; */
    /* background-attachment: fixed; */
    /* background-color: red; */
}
.on-mobile .solution {
    left: 0 !important;
    height: calc(var(--vh, 1vh) * 100 + 0.2rem);
}
.solution:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.2rem;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--v-primary-base);
}
.on-mobile .solution:after {
    width: 100%;
    height: 0.2rem;
}

.solution .background {
    position: absolute;
    background-image: url(~@/assets/the_solution.jpg);
}

.on-mobile .background, .on-mobile .solution {
    background-position: 75% 50%;
}

.description {
    max-width: 40rem;
    padding: 0.5rem 1rem;
    filter: drop-shadow(2px 4px 6px black);
}

.solution video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}
.on-mobile .solution video {
    object-position: 75% 50%;
}
.solution .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
}

.problem .v-btn, .solution .v-btn {
    background-color: #ffffff;
}

/* .solution .v-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 2rem;
} */

.chart {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35vh;
}
.chart .labels {
    position: absolute;
    top: -3.2rem;
}
</style>

<script>
import PowerIncreaseChart from '@/components/Charts/PowerIncreaseChart'
export default {
    name: 'Story',
    components: {
        PowerIncreaseChart
    },
    data: () => ({
        background_opacity: 0,
        problem: false,
        solution_top: 0,
        solution_left: 100,
        solution_left_last: 100,
        solution_can_play: false,
        chart_visible: false
    }),
    computed: {
        solution_play () {
            return (this.solution_can_play && this.solution_left == '-0.2rem') || (this.solution_can_play && this.solution_top == '0%')
        },
        chart_side () {
            return parseInt(this.solution_left) < 50 ? 'solution' : 'problem'
        }
    },
    watch: {
        solution_play (v) {
            if (v) {
                this.$refs.solutionVideo.style.opacity = 1
                this.$refs.solutionVideo.play()
            } else {
                this.$refs.solutionVideo.style.opacity = 0
                this.$refs.solutionVideo.pause()
                this.$refs.solutionVideo.currentTime = 0
            }
        },
        problem (v) {
            if (v) setTimeout(() => {this.chart_visible = true}, 800)
        },
        background_opacity (v) {
            if (v < 1) this.chart_visible = false
        }
    },
    methods: {
        on_scroll () {
            let offsetTop = this.$refs.story.offsetTop
            let offsetHeight = this.$refs.story.offsetHeight
            let scrollY = window.scrollY
            let story_scroll = scrollY - offsetTop
            story_scroll = story_scroll > 0 ? story_scroll : 0
            let vh = window.innerHeight * 0.01

            let background_fade_start = scrollY - offsetTop + (80 * vh)
            background_fade_start = background_fade_start / 6

            if (background_fade_start > 0) {
                this.background_opacity = background_fade_start >= 100 ? 1 : (background_fade_start / 100)
            } else {
                this.background_opacity = 0
            }

            let storyScroll = (top, bottom) => {
                return (story_scroll >= top) && (story_scroll <= (bottom ? bottom : (offsetHeight + (50 * vh)) )) // 50vh is added here because of css margin-bottom on .story
            }

            // if (this.$vuetify.breakpoint.smAndDown) return

            if (storyScroll(50 * vh)) {
                this.problem = true
            } else {
                this.problem = false
            }

            if (storyScroll(50 * vh) && this.$vuetify.breakpoint.smAndDown) {
                // console.log('test')
                let kak0 = story_scroll - (50 * vh)
                let kak1 = offsetHeight - (150 * vh)
                let result = 100 - parseInt((kak0 / kak1) * 100)

                // console.log(`kak0: ${kak0}`)
                // console.log(`kak1: ${kak1}`)
                // console.log(`result: ${result}`)

                if (result >= 100) {
                    this.solution_top = '-100%'
                } else if (result < 1) {
                    this.solution_top = '0%'
                } else {
                    this.solution_top = `${result * -1}%`
                }
            } else if (storyScroll(150 * vh) && !this.$vuetify.breakpoint.smAndDown) {
                let kak0 = story_scroll - (150 * vh)
                let kak1 = offsetHeight - (250 * vh)
                // let result = 100 - parseInt(kak0 / kak1 * 100 * 2)

                let result = 100 - parseInt((kak0 / kak1) * 100)

                if (result >= 100) {
                    this.solution_left = '100%'
                } else if (result < 1) {
                    this.solution_left = '-0.2rem'
                } else {
                    this.solution_left = `${result}%`
                }
            } else {
                this.solution_top = '100%'
                this.solution_left = '100%'
            }
        }
    }
}
</script>