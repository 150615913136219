<template>
    <div id="demo">
        <v-container>
            <h1 class="page-title mouse-hover hover-invert">Arrange A Demo</h1>
            <v-img :src="require('@/assets/demo_cover.png')" contain></v-img>
            <v-layout row>
                <v-flex xs12 v-if="$vuetify.breakpoint.mdAndUp">
                    <div class="form-c2a my-16">
                        <v-img :src="require('@/assets/form.svg')" height="4rem" contain class="mouse-hover hover-invert"></v-img>
                        <h2 class="text-center mb-8 mouse-hover hover-invert">Fill the form and we will contact you</h2>
                    </div>
                </v-flex>
                <v-flex xs12 md6 class="px-4">
                    <p class="text-justify mt-8">
                        SCRUFY operates on the asset to prevent macrofouling growth. It is attached on the hull via soft magnetic track system and has mounted cameras for inspection and an 800 mm wide soft grooming system, designed for proactive grooming, without causing damage on the hull coating. 
                    </p>
                    <p class="text-justify mt-8">
                        The robot shall operate at a nominal speed of about 15 m/min when grooming. It can be launched through a platform and is connected via umbilical with the station, for power and data distribution. One safety wire is utilized, during the duration of the deployment connected to a recovery winch for emergency recovery, on board the vessel.
                    </p>
                    <p class="text-justify mt-8">
                        Demonstration of SCRUFY is essential and the Demo Test Plan Procedure (DTPP) describes the process to be carried out for the data collection, for evaluation. DTPP shall be lead and controlled by mutually appointed Project Manager(s) (PM) from interested parties, if any - inclusive one (1) PM from SCRUFY PC. 
                    </p>
                    <p class="text-justify mt-8">
                        The effectiveness of the system shall be tested with respect to:

                        <ol class="mt-6">
                            <li>
                                Grooming for prevention of macrofouling growth through time;
                                <ul>
                                    <li>
                                        Success: Pre existing micro Fouling (FR10 to FR20), shall be successfully removed.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Impact to shell coating;
                                <ul>
                                    <li>
                                        Success: No visual deterioration to coating and constant mean DFT and Roughness measurements.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Impact to local water quality.
                                <ul>
                                    <li>
                                        Success: No increase of applied AF biocides, in the sampled water column.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </p>
                    <p class="text-justify mt-8">
                        All data and reports produced from the DTPP may be used (a) by SCRUFY PC for the internal use and exploitation of the product and (b) may be distributed to interested parties, subject to executed Mutual NDA.
                    </p>
                    <p class="text-justify mt-8">
                        In oder to carry out a demo operation, please provide the vessel's itinerary and respective electronic drawings, as detailed below and a member of our demo team, shall make contact with your side, in order to arrange a smooth turnaround.
                    </p>
                </v-flex>
                <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
                    <div class="form-c2a my-16">
                        <v-img :src="require('@/assets/form.svg')" height="4rem" contain class="mouse-hover hover-invert"></v-img>
                        <h2 class="text-center mb-8 mouse-hover hover-invert">Fill the form and we will contact you</h2>
                    </div>
                </v-flex>
                <v-flex xs12 md6 class="px-4">
                    <v-form v-model="form_valid">
                        <h2 class="primary--text text-center mb-8 mouse-hover hover-invert">Demo Request Form</h2>
                        <v-text-field v-model="demo.location" filled :rules="[v => !!v || 'Required Field']" label="Location for Demo *" prepend-inner-icon="fas fa-map-marker-alt small-icon"></v-text-field>
                        <v-text-field v-model="demo.management_company" filled :rules="[v => !!v || 'Required Field']" label="Management Company *" prepend-inner-icon="fas fa-building small-icon"></v-text-field>

                        <h3 class="my-4">Contact Details</h3>
                        <v-text-field v-model="demo.contact_name" filled :rules="[v => !!v || 'Required Field']" label="Person in Charge (Name) *" prepend-inner-icon="fas fa-user-tie small-icon"></v-text-field>
                        <v-text-field v-model="demo.tel" filled :rules="[v => !!v || 'Required Field']" type="number" label="Telephone Number *" prepend-inner-icon="fas fa-phone-alt small-icon"></v-text-field>
                        <v-text-field v-model="demo.mobile" filled type="number" label="Mobile Number" prepend-inner-icon="fas fa-mobile small-icon"></v-text-field>
                        <v-text-field v-model="demo.email" filled :rules="[v => !!v || 'Required Field']" label="Email *" prepend-inner-icon="fas fa-envelope small-icon"></v-text-field>

                        <h3 class="my-4">Vessel Details</h3>
                        <v-text-field v-model="demo.vessel_name" filled :rules="[v => !!v || 'Required Field']" label="Vessel Name *" prepend-inner-icon="fas fa-ship small-icon"></v-text-field>
                        <v-text-field v-model="demo.imo" filled :rules="[v => !!v || 'Required Field', v => (v && v.length >= 7) || 'Must be at least 7 characters']" label="Vessel IMO No. *"></v-text-field>
                        <v-text-field v-model="demo.type" filled :rules="[v => !!v || 'Required Field']" label="Vessel Type *"></v-text-field>
                        <v-text-field v-model="demo.dimensions" filled :rules="[v => !!v || 'Required Field']" label="Principal Dimensions *"></v-text-field>
                        <v-text-field v-model="demo.age" filled label="Vessel Age"></v-text-field>

                        <v-menu v-model="drydocking_menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="demo.last_drydocking" filled readonly label="Last drydocking" prepend-inner-icon="far fa-calendar-alt small-icon" v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="demo.last_drydocking" no-title @input="drydocking_menu = false"></v-date-picker>
                        </v-menu>

                        <h3 class="my-4">Coating Details</h3>
                        <v-select v-model="demo.coating_maker" :items="coating_makers" item-text="name" item-value="name" filled label="Coating Maker" prepend-inner-icon="fas fa-fill small-icon"></v-select>
                        <v-select v-model="demo.coating_type" :items="demo.coating_maker ? coating_makers.find(m => m.name == demo.coating_maker).types : []" item-text="name" item-value="name" filled :disabled="!demo.coating_maker" label="Coating Type" prepend-inner-icon="fas fa-palette small-icon"></v-select>

                        <h3 class="my-4">Documents</h3>
                        <v-file-input v-model="demo.af_certificate" filled show-size :rules="[v => !!v || 'Required Field']" label="AF Certificate *" prepend-icon="" prepend-inner-icon="fas fa-paperclip small-icon" truncate-length="20"></v-file-input>
                        <v-file-input v-model="demo.general_arrangement" filled show-size :rules="[v => !!v || 'Required Field']" label="General Arrangement (GA) *" prepend-icon="" prepend-inner-icon="fas fa-paperclip small-icon" truncate-length="20"></v-file-input>
                        <v-file-input v-model="demo.shell_expansion" filled show-size :rules="[v => !!v || 'Required Field']" label="Shell Expansion *" prepend-icon="" prepend-inner-icon="fas fa-paperclip small-icon" truncate-length="20"></v-file-input>
                        <v-file-input v-model="demo.capacity_plan" filled show-size :rules="[v => !!v || 'Required Field']" label="Capacity Plan (CAPA) *" prepend-icon="" prepend-inner-icon="fas fa-paperclip small-icon" truncate-length="20"></v-file-input>
                        <v-file-input v-model="demo.iafs_certificate" filled show-size :rules="[v => !!v || 'Required Field']" label="International Anti Fouling System (IAFS) Certificate *" prepend-icon="" prepend-inner-icon="fas fa-paperclip small-icon" truncate-length="20"></v-file-input>
                        <v-file-input v-model="demo.antifouling_product_tds" filled show-size :rules="[v => !!v || 'Required Field']" label="Anti Fouling (AF) Product Technical Data Sheet (TDS) *" prepend-icon="" prepend-inner-icon="fas fa-paperclip small-icon" truncate-length="20"></v-file-input>

                        <vue-recaptcha sitekey="6Lcy8iYcAAAAAHwz1UbGtAZeN9hE8byDRe9nCTZX" :loadRecaptchaScript="true" class="recaptcha center-all mb-4" ref="recaptchaContainer"></vue-recaptcha>

                        <div class="text-center my-4">
                            <p v-if="!form_valid" style="font-size: 0.9rem; color: var(--v-primary-base);">Please fill the required* fields to submit the form and arrange a demo.</p>
                            <v-btn rounded large outlined color="primary" class="mouse-hover hover-hide" :disabled="!form_valid" @click="submitForm()">Submit</v-btn>
                        </div>
                    </v-form>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<style scoped>
#demo {
    padding-top: var(--abh);
    margin-bottom: 4rem;
}
.v-form {
    padding: 1rem;
    margin: 2rem auto;
    border: 0.2rem solid var(--v-primary-base);
    border-radius: 24px;
    max-width: 1024px;
}
</style>

<script>
import VueRecaptcha from 'vue-recaptcha'
export default {
    name: 'Demo',
    components: {
        VueRecaptcha
    },
    data: () => ({
        drydocking_menu: false,
        coating_makers: [
            {
                name: 'CMP',
                types: [
                    'SEA GRANDPRIX 330 HS (Basic)',
                    'SEA GRANDPRIX 770 & 660 HS (Medium)',
                    'SEA GRANDPRIX 880 HS (High)',
                    'SEA GRANDPRIX 880 HS+ (State of the Art)'
                ]
            },
            {
                name: 'Hempel',
                types: [
                    'Olympic+ (Basic)',
                    'Oceanic+ (Medium)',
                    'Globic 6000 (High)',
                    'Globic 9000 (State of the Art)'
                ]
            },
            {
                name: 'International',
                types: [
                    'Interspeed 6200 (Basic)',
                    'Interswift 6600 (Medium)',
                    'Intercept 7000 (High)',
                    'Intersmooth 7460HS SPC (State of the Art)'
                ]
            },
            {
                name: 'Jotun',
                types: [
                    'SEAFORCE 30 M (Basic)',
                    'SEAFORCE ACTIVE & 60 M (Medium)',
                    'SEAFORCE ACTIVE+ & SEAMATE M (High)',
                    'SEAQUANTUM PRO U (State of the Art)'
                ]
            },
            {
                name: 'Nanophos',
                types: [
                    'SeaPrince (Basic)',
                    'SeaQueen (Medium)',
                    'SeaQueen Extreme (High)',
                    'SeaKing (State of the Art)'
                ]
            },
            {
                name: 'Nippon',
                types: [
                    'ECO SPC 200 K (Basic)',
                    'ECO SPC 200 K & Z (Medium)',
                    'ECO SPC 150 HYB C & 200 Z (High)',
                    'A-LF-SEA 150 (State of the Art)'
                ]
            },
            {
                name: 'KCC',
                types: [
                    'A/F7830 SPC (Basic)',
                    'A/F7950 SPC (High)',
                    'EgisPacific(M) SPC (State of the Art)'
                ]
            },
        ],
        form_valid: true,
        demo: {
            location: null,
            management_company: null,
            contact_name: null,
            tel: null,
            mobile: null,
            email: null,
            vessel_name: null,
            imo: null,
            type: null,
            dimensions: null,
            age: null,
            last_drydocking: null,
            coating_maker: null,
            coating_type: null,
            af_certificate: null,
            general_arrangement: null,
            shell_expansion: null,
            capacity_plan: null,
            iafs_certificate: null,
            antifouling_product_tds: null
        }
    }),
    methods: {
        submitForm () {
            if (!this.form_valid) return

            let captcha_response = this.$refs.recaptchaContainer.$el.getElementsByClassName('g-recaptcha-response')[0]

            if(!captcha_response || !captcha_response.value) return

            let data = { ...this.demo }
            data['g-recaptcha-response'] = captcha_response.value


            let formData = new FormData()
            Object.keys(data).forEach(key => formData.append(key, data[key]))

            this.axios.post('submit', formData)
				.then(resp => {
					alert('Form Submitted Successfully!')
                    window.location.reload()
				})
        }
    }
}
</script>