<template>
    <v-dialog v-model="drawer" attach fullscreen scrollable class="app-drawer-dialog" content-class="app-drawer" transition="slide-y-transition">
        <v-card tile color="background">
            <v-card-text>
                <v-container class="fill-height">
                    <v-layout row>
                        <v-container fluid>
                            <v-layout row class="justify-center">
                                <v-flex xs12 sm6 md4 v-for="item in menu" :key="item.name">
                                    <v-lazy v-if="drawer" class="center-all" transition="scale-transition">
                                        <h1 v-if="!item.action"><router-link :to="item.to" class="mouse-hover hover-hide" @click.native="drawer = false">{{ $vuetify.lang.t(item.name) }}</router-link></h1>
                                        <v-btn v-else rounded x-large outlined color="primary" class="mouse-hover hover-hide" :to="item.to" :href="item.url" @click.native="drawer = false">{{ item.name }}</v-btn>
                                    </v-lazy>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                    <v-layout row>
                        <v-flex v-if="$vuetify.breakpoint.mdAndUp" xs12 md6 class="d-flex align-end" style="padding: 0 2rem;">
                            <p class="mb-0" style="font-size: 1.1rem; font-weight: 600;">
                                &copy; {{ new Date().getFullYear() }} <router-link to="/" style="text-decoration: none;">{{ $appName }}</router-link>.
                            </p>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped>
h1 {
    text-align: center;
}
h1 > a {
    display: inline-block;
    position: relative;
    color: var(--v-primary-base);
    text-decoration: none;
    font-size: 2rem !important;
    margin: 2rem;
    font-weight: 900;
}
.v-btn {
    margin-top: 1rem;
    font-size: 1.5rem !important;
}
</style>

<style>
.app-drawer-dialog .v-dialog__content--active {
    z-index: 2 !important;
}
.app-drawer {
    top: var(--abh) !important;
    box-shadow: none !important;
}
</style>

<script>
export default {
    name: 'AppDrawer',
    props: {
        value: {
            type: Boolean,
            required: false
        },
        menu: {
            type: Array,
            required: true
        }
    },
    computed: {
        drawer: {
            get () {
                return this.value
            },
            set (v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>