<template>
	<canvas ref="chart"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
	name: 'PowerIncreaseChart',
	props: {
		side: {
			type: String,
			required: false
		}
	},
	data: () => ({
		chart: null,

		chart_data: {
			labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
			datasets: [
				{
					label: "Power Increase Over Time",
					lineTension: 0,
					fill: {
						target: 'origin',
						above: '#fa7c03'
					},
					data: [
						{ x: 0, y: 0 },
						{ x: 0.2, y: 0.333333333 },
						{ x: 0.4, y: 0.666666667 },
						{ x: 0.6, y: 1 },
						{ x: 0.8, y: 1.333333333 },
						{ x: 1, y: 1.666666667 },
						{ x: 1.2, y: 2 },
						{ x: 1.4, y: 2.333333333 },
						{ x: 1.6, y: 2.666666667 },
						{ x: 1.8, y: 3 },
						{ x: 2, y: 3.333333333 },
						{ x: 2.2, y: 3.666666667 },
						{ x: 2.4, y: 4 },
						{ x: 2.6, y: 4.333333333 },
						{ x: 2.8, y: 4.666666667 },
						{ x: 3, y: 5 },
						{ x: 3.2, y: 5.333333333 },
						{ x: 3.4, y: 5.666666667 },
						{ x: 3.6, y: 6 },
						{ x: 3.8, y: 6.333333333 },
						{ x: 4, y: 6.666666667 },
						{ x: 4.2, y: 7 },
						{ x: 4.4, y: 7.333333333 },
						{ x: 4.6, y: 7.666666667 },
						{ x: 4.8, y: 8 },
						{ x: 5, y: 8.333333333 },
						{ x: 5.2, y: 8.666666667 },
						{ x: 5.4, y: 9 },
						{ x: 5.6, y: 9.333333333 },
						{ x: 5.8, y: 9.666666667 },
						{ x: 6, y: 10 },
						{ x: 6.2, y: 10.33333333 },
						{ x: 6.4, y: 10.66666667 },
						{ x: 6.6, y: 11 },
						{ x: 6.8, y: 11.33333333 },
						{ x: 7, y: 11.66666667 },
						{ x: 7.2, y: 12 },
						{ x: 7.4, y: 12.33333333 },
						{ x: 7.6, y: 12.66666667 },
						{ x: 7.8, y: 13 },
						{ x: 8, y: 13.33333333 },
						{ x: 8.2, y: 13.66666667 },
						{ x: 8.4, y: 14 },
						{ x: 8.6, y: 14.33333333 },
						{ x: 8.8, y: 14.66666667 },
						{ x: 9, y: 15 },
						{ x: 9.2, y: 15.33333333 },
						{ x: 9.4, y: 15.66666667 },
						{ x: 9.6, y: 16 },
						{ x: 9.8, y: 16.33333333 },
						{ x: 10, y: 16.66666667 },
						{ x: 10.2, y: 17 },
						{ x: 10.4, y: 17.33333333 },
						{ x: 10.6, y: 17.66666667 },
						{ x: 10.8, y: 18 },
						{ x: 11, y: 18.33333333 },
						{ x: 11.2, y: 18.66666667 },
						{ x: 11.4, y: 19 },
						{ x: 11.6, y: 19.33333333 },
						{ x: 11.8, y: 19.66666667 },
						{ x: 12, y: 20 },
						{ x: 12, y: 3 },
						{ x: 12.3, y: 3.54375 },
						{ x: 12.6, y: 4.0875 },
						{ x: 12.9, y: 4.63125 },
						{ x: 13.2, y: 5.175 },
						{ x: 13.5, y: 5.71875 },
						{ x: 13.8, y: 6.2625 },
						{ x: 14.1, y: 6.80625 },
						{ x: 14.4, y: 7.35 },
						{ x: 14.7, y: 7.89375 },
						{ x: 15, y: 8.4375 },
						{ x: 15.3, y: 8.98125 },
						{ x: 15.6, y: 9.525 },
						{ x: 15.9, y: 10.06875 },
						{ x: 16.2, y: 10.6125 },
						{ x: 16.5, y: 11.15625 },
						{ x: 16.8, y: 11.7 },
						{ x: 17.1, y: 12.24375 },
						{ x: 17.4, y: 12.7875 },
						{ x: 17.7, y: 13.33125 },
						{ x: 18, y: 13.875 },
						{ x: 18.3, y: 14.41875 },
						{ x: 18.6, y: 14.9625 },
						{ x: 18.9, y: 15.50625 },
						{ x: 19.2, y: 16.05 },
						{ x: 19.5, y: 16.59375 },
						{ x: 19.8, y: 17.1375 },
						{ x: 20.1, y: 17.68125 },
						{ x: 20.4, y: 18.225 },
						{ x: 20.7, y: 18.76875 },
						{ x: 21, y: 19.3125 },
						{ x: 21.3, y: 19.85625 },
						{ x: 21.6, y: 20.4 },
						{ x: 21.9, y: 20.94375 },
						{ x: 22.2, y: 21.4875 },
						{ x: 22.5, y: 22.03125 },
						{ x: 22.8, y: 22.575 },
						{ x: 23.1, y: 23.11875 },
						{ x: 23.4, y: 23.6625 },
						{ x: 23.7, y: 24.20625 },
						{ x: 24, y: 24.75 },
						{ x: 24.3, y: 25.29375 },
						{ x: 24.6, y: 25.8375 },
						{ x: 24.9, y: 26.38125 },
						{ x: 25.2, y: 26.925 },
						{ x: 25.5, y: 27.46875 },
						{ x: 25.8, y: 28.0125 },
						{ x: 26.1, y: 28.55625 },
						{ x: 26.4, y: 29.1 },
						{ x: 26.7, y: 29.64375 },
						{ x: 27, y: 30.1875 },
						{ x: 27.3, y: 30.73125 },
						{ x: 27.6, y: 31.275 },
						{ x: 27.9, y: 31.81875 },
						{ x: 28, y: 15 },
						{ x: 28.1, y: 15.16 },
						{ x: 28.2, y: 15.32 },
						{ x: 28.3, y: 15.48 },
						{ x: 28.4, y: 15.64 },
						{ x: 28.5, y: 15.8 },
						{ x: 28.6, y: 15.96 },
						{ x: 28.7, y: 16.12 },
						{ x: 28.8, y: 16.28 },
						{ x: 28.9, y: 16.44 },
						{ x: 29, y: 16.6 },
						{ x: 29.1, y: 16.76 },
						{ x: 29.2, y: 16.92 },
						{ x: 29.3, y: 17.08 },
						{ x: 29.4, y: 17.24 },
						{ x: 29.5, y: 17.4 },
						{ x: 29.6, y: 17.56 },
						{ x: 29.7, y: 17.72 },
						{ x: 29.8, y: 17.88 },
						{ x: 29.9, y: 18.04 },
						{ x: 30, y: 18.2 },
						{ x: 30.1, y: 18.36 },
						{ x: 30.2, y: 18.52 },
						{ x: 30.3, y: 18.68 },
						{ x: 30.4, y: 18.84 },
						{ x: 30.5, y: 19 },
						{ x: 30.6, y: 19.16 },
						{ x: 30.7, y: 19.32 },
						{ x: 30.8, y: 19.48 },
						{ x: 30.9, y: 19.64 },
						{ x: 31, y: 19.8 },
						{ x: 31.1, y: 19.96 },
						{ x: 31.2, y: 20.12 },
						{ x: 31.3, y: 20.28 },
						{ x: 31.4, y: 20.44 },
						{ x: 31.5, y: 20.6 },
						{ x: 31.6, y: 20.76 },
						{ x: 31.7, y: 20.92 },
						{ x: 31.8, y: 21.08 },
						{ x: 31.9, y: 21.24 },
						{ x: 32, y: 21.4 },
						{ x: 32.1, y: 21.56 },
						{ x: 32.2, y: 21.72 },
						{ x: 32.3, y: 21.88 },
						{ x: 32.4, y: 22.04 },
						{ x: 32.5, y: 22.2 },
						{ x: 32.6, y: 22.36 },
						{ x: 32.7, y: 22.52 },
						{ x: 32.8, y: 22.68 },
						{ x: 32.9, y: 22.84 },
						{ x: 33, y: 17 },
						{ x: 33.1, y: 17.2 },
						{ x: 33.2, y: 17.4 },
						{ x: 33.3, y: 17.6 },
						{ x: 33.4, y: 17.8 },
						{ x: 33.5, y: 18 },
						{ x: 33.6, y: 18.2 },
						{ x: 33.7, y: 18.4 },
						{ x: 33.8, y: 18.6 },
						{ x: 33.9, y: 18.8 },
						{ x: 34, y: 19 },
						{ x: 34.1, y: 19.2 },
						{ x: 34.2, y: 19.4 },
						{ x: 34.3, y: 19.6 },
						{ x: 34.4, y: 19.8 },
						{ x: 34.5, y: 20 },
						{ x: 34.6, y: 20.2 },
						{ x: 34.7, y: 20.4 },
						{ x: 34.8, y: 20.6 },
						{ x: 34.9, y: 20.8 },
						{ x: 35, y: 21 },
						{ x: 35.1, y: 21.2 },
						{ x: 35.2, y: 21.4 },
						{ x: 35.3, y: 21.6 },
						{ x: 35.4, y: 21.8 },
						{ x: 35.5, y: 22 },
						{ x: 35.6, y: 22.2 },
						{ x: 35.7, y: 22.4 },
						{ x: 35.8, y: 22.6 },
						{ x: 35.9, y: 22.8 },
						{ x: 36, y: 23 },
						{ x: 36.1, y: 23.2 },
						{ x: 36.2, y: 23.4 },
						{ x: 36.3, y: 23.6 },
						{ x: 36.4, y: 23.8 },
						{ x: 36.5, y: 24 },
						{ x: 36.6, y: 24.2 },
						{ x: 36.7, y: 24.4 },
						{ x: 36.8, y: 24.6 },
						{ x: 36.9, y: 24.8 },
						{ x: 37, y: 25 },
						{ x: 37.1, y: 25.2 },
						{ x: 37.2, y: 25.4 },
						{ x: 37.3, y: 25.6 },
						{ x: 37.4, y: 25.8 },
						{ x: 37.5, y: 26 },
						{ x: 37.6, y: 26.2 },
						{ x: 37.7, y: 26.4 },
						{ x: 37.8, y: 26.6 },
						{ x: 37.9, y: 26.8 },
						{ x: 38, y: 27 },
						{ x: 38.1, y: 27.2 },
						{ x: 38.2, y: 27.4 },
						{ x: 38.3, y: 27.6 },
						{ x: 38.4, y: 27.8 },
						{ x: 38.5, y: 28 },
						{ x: 38.6, y: 28.2 },
						{ x: 38.7, y: 28.4 },
						{ x: 38.8, y: 28.6 },
						{ x: 38.9, y: 28.8 },
						{ x: 39, y: 29 },
						{ x: 39.1, y: 29.2 },
						{ x: 39.2, y: 29.4 },
						{ x: 39.3, y: 29.6 },
						{ x: 39.4, y: 29.8 },
						{ x: 39.5, y: 30 },
						{ x: 39.6, y: 30.2 },
						{ x: 39.7, y: 30.4 },
						{ x: 39.8, y: 30.6 },
						{ x: 39.9, y: 30.8 },
						{ x: 40, y: 31 },
						{ x: 40.1, y: 31.2 },
						{ x: 40.2, y: 31.4 },
						{ x: 40.3, y: 31.6 },
						{ x: 40.4, y: 31.8 },
						{ x: 40.5, y: 32 },
						{ x: 40.6, y: 32.2 },
						{ x: 40.7, y: 32.4 },
						{ x: 40.8, y: 32.6 },
						{ x: 40.9, y: 32.8 },
						{ x: 41, y: 3 },
						{ x: 41.1, y: 3.188888889 },
						{ x: 41.2, y: 3.377777778 },
						{ x: 41.3, y: 3.566666667 },
						{ x: 41.4, y: 3.755555556 },
						{ x: 41.5, y: 3.944444444 },
						{ x: 41.6, y: 4.133333333 },
						{ x: 41.7, y: 4.322222222 },
						{ x: 41.8, y: 4.511111111 },
						{ x: 41.9, y: 4.7 },
						{ x: 42, y: 4.888888889 },
						{ x: 42.1, y: 5.077777778 },
						{ x: 42.2, y: 5.266666667 },
						{ x: 42.3, y: 5.455555556 },
						{ x: 42.4, y: 5.644444444 },
						{ x: 42.5, y: 5.833333333 },
						{ x: 42.6, y: 6.022222222 },
						{ x: 42.7, y: 6.211111111 },
						{ x: 42.8, y: 6.4 },
						{ x: 42.9, y: 6.588888889 },
						{ x: 43, y: 6.777777778 },
						{ x: 43.1, y: 6.966666667 },
						{ x: 43.2, y: 7.155555556 },
						{ x: 43.3, y: 7.344444444 },
						{ x: 43.4, y: 7.533333333 },
						{ x: 43.5, y: 7.722222222 },
						{ x: 43.6, y: 7.911111111 },
						{ x: 43.7, y: 8.1 },
						{ x: 43.8, y: 8.288888889 },
						{ x: 43.9, y: 8.477777778 },
						{ x: 44, y: 8.666666667 },
						{ x: 44.1, y: 8.855555556 },
						{ x: 44.2, y: 9.044444444 },
						{ x: 44.3, y: 9.233333333 },
						{ x: 44.4, y: 9.422222222 },
						{ x: 44.5, y: 9.611111111 },
						{ x: 44.6, y: 9.8 },
						{ x: 44.7, y: 9.988888889 },
						{ x: 44.8, y: 10.17777778 },
						{ x: 44.9, y: 10.36666667 },
						{ x: 45, y: 10.55555556 },
						{ x: 45.1, y: 10.74444444 },
						{ x: 45.2, y: 10.93333333 },
						{ x: 45.3, y: 11.12222222 },
						{ x: 45.4, y: 11.31111111 },
						{ x: 45.5, y: 11.5 },
						{ x: 45.6, y: 11.68888889 },
						{ x: 45.7, y: 11.87777778 },
						{ x: 45.8, y: 12.06666667 },
						{ x: 45.9, y: 12.25555556 },
						{ x: 46, y: 12.44444444 },
						{ x: 46.1, y: 12.63333333 },
						{ x: 46.2, y: 12.82222222 },
						{ x: 46.3, y: 13.01111111 },
						{ x: 46.4, y: 13.2 },
						{ x: 46.5, y: 13.38888889 },
						{ x: 46.6, y: 13.57777778 },
						{ x: 46.7, y: 13.76666667 },
						{ x: 46.8, y: 13.95555556 },
						{ x: 46.9, y: 14.14444444 },
						{ x: 47, y: 14.33333333 },
						{ x: 47.1, y: 14.52222222 },
						{ x: 47.2, y: 14.71111111 },
						{ x: 47.3, y: 14.9 },
						{ x: 47.4, y: 15.08888889 },
						{ x: 47.5, y: 15.27777778 },
						{ x: 47.6, y: 15.46666667 },
						{ x: 47.7, y: 15.65555556 },
						{ x: 47.8, y: 15.84444444 },
						{ x: 47.9, y: 16.03333333 },
						{ x: 48, y: 16.22222222 },
						{ x: 48.1, y: 16.41111111 },
						{ x: 48.2, y: 16.6 },
						{ x: 48.3, y: 16.78888889 },
						{ x: 48.4, y: 16.97777778 },
						{ x: 48.5, y: 17.16666667 },
						{ x: 48.6, y: 17.35555556 },
						{ x: 48.7, y: 17.54444444 },
						{ x: 48.8, y: 17.73333333 },
						{ x: 48.9, y: 17.92222222 },
						{ x: 49, y: 18.11111111 },
						{ x: 49.1, y: 18.3 },
						{ x: 49.2, y: 18.48888889 },
						{ x: 49.3, y: 18.67777778 },
						{ x: 49.4, y: 18.86666667 },
						{ x: 49.5, y: 19.05555556 },
						{ x: 49.6, y: 19.24444444 },
						{ x: 49.7, y: 19.43333333 },
						{ x: 49.8, y: 19.62222222 },
						{ x: 49.9, y: 19.81111111 },
						{ x: 50, y: 12 },
						{ x: 50.03, y: 12.075 },
						{ x: 50.06, y: 12.15 },
						{ x: 50.09, y: 12.225 },
						{ x: 50.12, y: 12.3 },
						{ x: 50.15, y: 12.375 },
						{ x: 50.18, y: 12.45 },
						{ x: 50.21, y: 12.525 },
						{ x: 50.24, y: 12.6 },
						{ x: 50.27, y: 12.675 },
						{ x: 50.3, y: 12.75 },
						{ x: 50.33, y: 12.825 },
						{ x: 50.36, y: 12.9 },
						{ x: 50.39, y: 12.975 },
						{ x: 50.42, y: 13.05 },
						{ x: 50.45, y: 13.125 },
						{ x: 50.48, y: 13.2 },
						{ x: 50.51, y: 13.275 },
						{ x: 50.54, y: 13.35 },
						{ x: 50.57, y: 13.425 },
						{ x: 50.6, y: 13.5 },
						{ x: 50.63, y: 13.575 },
						{ x: 50.66, y: 13.65 },
						{ x: 50.69, y: 13.725 },
						{ x: 50.72, y: 13.8 },
						{ x: 50.75, y: 13.875 },
						{ x: 50.78, y: 13.95 },
						{ x: 50.81, y: 14.025 },
						{ x: 50.84, y: 14.1 },
						{ x: 50.87, y: 14.175 },
						{ x: 50.9, y: 14.25 },
						{ x: 50.93, y: 14.325 },
						{ x: 50.96, y: 14.4 },
						{ x: 50.99, y: 14.475 },
						{ x: 51.02, y: 14.55 },
						{ x: 51.05, y: 14.625 },
						{ x: 51.08, y: 14.7 },
						{ x: 51.11, y: 14.775 },
						{ x: 51.14, y: 14.85 },
						{ x: 51.17, y: 14.925 },
						{ x: 51.2, y: 15 },
						{ x: 51.23, y: 15.075 },
						{ x: 51.26, y: 15.15 },
						{ x: 51.29, y: 15.225 },
						{ x: 51.32, y: 15.3 },
						{ x: 51.35, y: 15.375 },
						{ x: 51.38, y: 15.45 },
						{ x: 51.41, y: 15.525 },
						{ x: 51.44, y: 15.6 },
						{ x: 51.47, y: 15.675 },
						{ x: 51.5, y: 15.75 },
						{ x: 51.53, y: 15.825 },
						{ x: 51.56, y: 15.9 },
						{ x: 51.59, y: 15.975 },
						{ x: 51.62, y: 16.05 },
						{ x: 51.65, y: 16.125 },
						{ x: 51.68, y: 16.2 },
						{ x: 51.71, y: 16.275 },
						{ x: 51.74, y: 16.35 },
						{ x: 51.77, y: 16.425 },
						{ x: 51.8, y: 16.5 },
						{ x: 51.83, y: 16.575 },
						{ x: 51.86, y: 16.65 },
						{ x: 51.89, y: 16.725 },
						{ x: 51.92, y: 16.8 },
						{ x: 51.95, y: 16.875 },
						{ x: 51.98, y: 16.95 },
						{ x: 52, y: 17 },
						{ x: 52.03, y: 17.0225 },
						{ x: 52.06, y: 17.15 },
						{ x: 52.09, y: 17.225 },
						{ x: 52.12, y: 17.3 },
						{ x: 52.15, y: 17.375 },
						{ x: 52.18, y: 17.45 },
						{ x: 52.21, y: 17.525 },
						{ x: 52.24, y: 17.6 },
						{ x: 52.27, y: 17.675 },
						{ x: 52.3, y: 17.75 },
						{ x: 52.33, y: 17.825 },
						{ x: 52.36, y: 17.9 },
						{ x: 52.39, y: 17.975 },
						{ x: 52.42, y: 18.05 },
						{ x: 52.45, y: 18.125 },
						{ x: 52.48, y: 18.2 },
						{ x: 52.51, y: 18.275 },
						{ x: 52.54, y: 18.35 },
						{ x: 52.57, y: 18.425 },
						{ x: 52.6, y: 18.5 },
						{ x: 52.63, y: 18.575 },
						{ x: 52.66, y: 18.65 },
						{ x: 52.69, y: 18.725 },
						{ x: 52.72, y: 18.8 },
						{ x: 52.75, y: 18.875 },
						{ x: 52.78, y: 18.95 },
						{ x: 52.81, y: 19.025 },
						{ x: 52.84, y: 19.1 },
						{ x: 52.87, y: 19.175 },
						{ x: 52.9, y: 19.25 },
						{ x: 52.93, y: 19.325 },
						{ x: 52.96, y: 19.4 },
						{ x: 52.99, y: 19.475 },
						{ x: 53.02, y: 19.55 },
						{ x: 53.05, y: 19.625 },
						{ x: 53.08, y: 19.7 },
						{ x: 53.11, y: 19.775 },
						{ x: 53.14, y: 19.85 },
						{ x: 53.17, y: 19.925 },
						{ x: 53.2, y: 20 },
						{ x: 53.23, y: 20.075 },
						{ x: 53.26, y: 20.15 },
						{ x: 53.29, y: 20.225 },
						{ x: 53.32, y: 20.3 },
						{ x: 53.35, y: 20.375 },
						{ x: 53.38, y: 20.45 },
						{ x: 53.41, y: 20.525 },
						{ x: 53.44, y: 20.6 },
						{ x: 53.47, y: 20.675 },
						{ x: 53.5, y: 20.75 },
						{ x: 53.53, y: 20.825 },
						{ x: 53.56, y: 20.9 },
						{ x: 53.59, y: 20.975 },
						{ x: 53.62, y: 21.05 },
						{ x: 53.65, y: 21.125 },
						{ x: 53.68, y: 21.2 },
						{ x: 53.71, y: 21.275 },
						{ x: 53.74, y: 21.35 },
						{ x: 53.77, y: 21.425 },
						{ x: 53.8, y: 21.5 },
						{ x: 53.83, y: 21.575 },
						{ x: 53.86, y: 21.65 },
						{ x: 53.89, y: 21.725 },
						{ x: 53.92, y: 21.8 },
						{ x: 53.95, y: 21.875 },
						{ x: 53.98, y: 21.95 },
						{ x: 54.01, y: 22.025 },
						{ x: 54.04, y: 22.1 },
						{ x: 54.07, y: 22.175 },
						{ x: 54.1, y: 22.25 },
						{ x: 54.13, y: 22.325 },
						{ x: 54.16, y: 22.4 },
						{ x: 54.19, y: 22.475 },
						{ x: 54.22, y: 22.55 },
						{ x: 54.25, y: 22.625 },
						{ x: 54.28, y: 22.7 },
						{ x: 54.31, y: 22.775 },
						{ x: 54.34, y: 22.85 },
						{ x: 54.37, y: 22.925 },
						{ x: 54.4, y: 23 },
						{ x: 54.43, y: 23.075 },
						{ x: 54.46, y: 23.15 },
						{ x: 54.49, y: 23.225 },
						{ x: 54.52, y: 23.3 },
						{ x: 54.55, y: 23.375 },
						{ x: 54.58, y: 23.45 },
						{ x: 54.61, y: 23.525 },
						{ x: 54.64, y: 23.6 },
						{ x: 54.67, y: 23.675 },
						{ x: 54.7, y: 23.75 },
						{ x: 54.73, y: 23.825 },
						{ x: 54.76, y: 23.9 },
						{ x: 54.79, y: 23.975 },
						{ x: 54.82, y: 24.05 },
						{ x: 54.85, y: 24.125 },
						{ x: 54.88, y: 24.2 },
						{ x: 54.91, y: 24.275 },
						{ x: 54.94, y: 24.35 },
						{ x: 54.97, y: 24.425 },
						{ x: 55, y: 24.5 },
						{ x: 55.03, y: 24.575 },
						{ x: 55.06, y: 24.65 },
						{ x: 55.09, y: 24.725 },
						{ x: 55.12, y: 24.8 },
						{ x: 55.15, y: 24.875 },
						{ x: 55.18, y: 24.95 },
						{ x: 55.21, y: 25.025 },
						{ x: 55.24, y: 25.1 },
						{ x: 55.27, y: 25.175 },
						{ x: 55.3, y: 25.25 },
						{ x: 55.33, y: 25.325 },
						{ x: 55.36, y: 25.4 },
						{ x: 55.39, y: 25.475 },
						{ x: 55.42, y: 25.55 },
						{ x: 55.45, y: 25.625 },
						{ x: 55.48, y: 25.7 },
						{ x: 55.51, y: 25.775 },
						{ x: 55.54, y: 25.85 },
						{ x: 55.57, y: 25.925 },
						{ x: 55.6, y: 26 },
						{ x: 55.63, y: 26.075 },
						{ x: 55.66, y: 26.15 },
						{ x: 55.69, y: 26.225 },
						{ x: 55.72, y: 26.3 },
						{ x: 55.75, y: 26.375 },
						{ x: 55.78, y: 26.45 },
						{ x: 55.81, y: 26.525 },
						{ x: 55.84, y: 26.6 },
						{ x: 55.87, y: 26.675 },
						{ x: 55.9, y: 26.75 },
						{ x: 55.93, y: 26.825 },
						{ x: 55.96, y: 26.9 },
						{ x: 55.99, y: 26.975 },
						{ x: 55.96, y: 26.9 },
						{ x: 55.99, y: 26.975 },
						{ x: 56.02, y: 27.05 },
						{ x: 56.05, y: 27.125 },
						{ x: 56.08, y: 27.2 },
						{ x: 56.11, y: 27.275 },
						{ x: 56.14, y: 27.35 },
						{ x: 56.17, y: 27.425 },
						{ x: 56.2, y: 27.5 },
						{ x: 56.23, y: 27.575 },
						{ x: 56.26, y: 27.65 },
						{ x: 56.29, y: 27.725 },
						{ x: 56.32, y: 27.8 },
						{ x: 56.35, y: 27.875 },
						{ x: 56.38, y: 27.95 },
						{ x: 56.41, y: 28.025 },
						{ x: 56.44, y: 28.1 },
						{ x: 56.47, y: 28.175 },
						{ x: 56.5, y: 28.25 },
						{ x: 56.53, y: 28.325 },
						{ x: 56.56, y: 28.4 },
						{ x: 56.59, y: 28.475 },
						{ x: 56.62, y: 28.55 },
						{ x: 56.65, y: 28.625 },
						{ x: 56.68, y: 28.7 },
						{ x: 56.71, y: 28.775 },
						{ x: 56.74, y: 28.85 },
						{ x: 56.77, y: 28.925 },
						{ x: 56.8, y: 29 },
						{ x: 56.83, y: 29.075 },
						{ x: 56.86, y: 29.15 },
						{ x: 56.89, y: 29.225 },
						{ x: 56.92, y: 29.3 },
						{ x: 56.95, y: 29.375 },
						{ x: 56.98, y: 29.45 },
						{ x: 57.01, y: 29.525 },
						{ x: 57.04, y: 29.6 },
						{ x: 57.07, y: 29.675 },
						{ x: 57.1, y: 29.75 },
						{ x: 57.13, y: 29.825 },
						{ x: 57.16, y: 29.9 },
						{ x: 57.19, y: 29.975 },
						{ x: 57.22, y: 30.05 },
						{ x: 57.25, y: 30.125 },
						{ x: 57.28, y: 30.2 },
						{ x: 57.31, y: 30.275 },
						{ x: 57.34, y: 30.35 },
						{ x: 57.37, y: 30.425 },
						{ x: 57.4, y: 30.5 },
						{ x: 57.43, y: 30.575 },
						{ x: 57.46, y: 30.65 },
						{ x: 57.49, y: 30.725 },
						{ x: 57.52, y: 30.8 },
						{ x: 57.55, y: 30.875 },
						{ x: 57.58, y: 30.95 },
						{ x: 57.61, y: 31.025 },
						{ x: 57.64, y: 31.1 },
						{ x: 57.67, y: 31.175 },
						{ x: 57.7, y: 31.25 },
						{ x: 57.73, y: 31.325 },
						{ x: 57.76, y: 31.4 },
						{ x: 57.79, y: 31.475 },
						{ x: 57.82, y: 31.55 },
						{ x: 57.85, y: 31.625 },
						{ x: 57.88, y: 31.7 },
						{ x: 57.91, y: 31.775 },
						{ x: 57.94, y: 31.85 },
						{ x: 57.97, y: 31.925 },
						{ x: 58, y: 32 },
						{ x: 58.03, y: 32.075 },
						{ x: 58.06, y: 32.15 },
						{ x: 58.09, y: 32.225 },
						{ x: 58.12, y: 32.3 },
						{ x: 58.15, y: 32.375 },
						{ x: 58.18, y: 32.45 },
						{ x: 58.21, y: 32.525 },
						{ x: 58.24, y: 32.6 },
						{ x: 58.27, y: 32.675 },
						{ x: 58.3, y: 32.75 },
						{ x: 58.33, y: 32.825 },
						{ x: 58.36, y: 32.9 },
						{ x: 58.39, y: 32.975 },
						{ x: 58.42, y: 33.05 },
						{ x: 58.45, y: 33.125 },
						{ x: 58.48, y: 33.2 },
						{ x: 58.51, y: 33.275 },
						{ x: 58.54, y: 33.35 },
						{ x: 58.57, y: 33.425 },
						{ x: 58.6, y: 33.5 },
						{ x: 58.63, y: 33.575 },
						{ x: 58.66, y: 33.65 },
						{ x: 58.69, y: 33.725 },
						{ x: 58.72, y: 33.8 },
						{ x: 58.75, y: 33.875 },
						{ x: 58.78, y: 33.95 },
						{ x: 58.81, y: 34.025 },
						{ x: 58.84, y: 34.1 },
						{ x: 58.87, y: 34.175 },
						{ x: 58.9, y: 34.25 },
						{ x: 58.93, y: 34.325 },
						{ x: 58.96, y: 34.4 },
						{ x: 58.99, y: 34.475 },
						{ x: 59.02, y: 34.55 },
						{ x: 59.05, y: 34.625 },
						{ x: 59.08, y: 34.7 },
						{ x: 59.11, y: 34.775 },
						{ x: 59.14, y: 34.85 },
						{ x: 59.17, y: 34.925 },
						{ x: 59.2, y: 35 },
						{ x: 59.23, y: 35.075 },
						{ x: 59.26, y: 35.15 },
						{ x: 59.29, y: 35.225 },
						{ x: 59.32, y: 35.3 },
						{ x: 59.35, y: 35.375 },
						{ x: 59.38, y: 35.45 },
						{ x: 59.41, y: 35.525 },
						{ x: 59.44, y: 35.6 },
						{ x: 59.47, y: 35.675 },
						{ x: 59.5, y: 35.75 },
						{ x: 59.53, y: 35.825 },
						{ x: 59.56, y: 35.9 },
						{ x: 59.59, y: 35.975 },
						{ x: 59.62, y: 36.05 },
						{ x: 59.65, y: 36.125 },
						{ x: 59.68, y: 36.2 },
						{ x: 59.71, y: 36.275 },
						{ x: 59.74, y: 36.35 },
						{ x: 59.77, y: 36.425 },
						{ x: 59.8, y: 36.5 },
						{ x: 59.83, y: 36.575 },
						{ x: 59.86, y: 36.65 },
						{ x: 59.89, y: 36.725 },
						{ x: 59.92, y: 36.8 },
						{ x: 59.95, y: 36.875 },
						{ x: 59.98, y: 36.95 },
						{ x: 60, y: 37 }
					],
					borderColor: "#4527A0",
					borderWidth: 3,
					pointRadius: 0
				},
				{
					label: "Excessive CO2 emission on account of the over consumption in fuel, due to fouling",
					lineTension: 0,
					data: [
						{ x: 0.0, y: 15 },
						{ x: 0.2, y: 15 },
						{ x: 0.4, y: 15 },
						{ x: 0.6, y: 15 },
						{ x: 0.8, y: 15 },
						{ x: 1.0, y: 15 },
						{ x: 1.2, y: 15 },
						{ x: 1.4, y: 15 },
						{ x: 1.6, y: 15 },
						{ x: 1.8, y: 15 },
						{ x: 2.0, y: 15 },
						{ x: 2.2, y: 15 },
						{ x: 2.4, y: 15 },
						{ x: 2.6, y: 15 },
						{ x: 2.8, y: 15 },
						{ x: 3.0, y: 15 },
						{ x: 3.2, y: 15 },
						{ x: 3.4, y: 15 },
						{ x: 3.6, y: 15 },
						{ x: 3.8, y: 15 },
						{ x: 4.0, y: 15 },
						{ x: 4.2, y: 15 },
						{ x: 4.4, y: 15 },
						{ x: 4.6, y: 15 },
						{ x: 4.8, y: 15 },
						{ x: 5.0, y: 15 },
						{ x: 5.2, y: 15 },
						{ x: 5.4, y: 15 },
						{ x: 5.6, y: 15 },
						{ x: 5.8, y: 15 },
						{ x: 6.0, y: 15 },
						{ x: 6.2, y: 15 },
						{ x: 6.4, y: 15 },
						{ x: 6.6, y: 15 },
						{ x: 6.8, y: 15 },
						{ x: 7.0, y: 15 },
						{ x: 7.2, y: 15 },
						{ x: 7.4, y: 15 },
						{ x: 7.6, y: 15 },
						{ x: 7.8, y: 15 },
						{ x: 8.0, y: 15 },
						{ x: 8.2, y: 15 },
						{ x: 8.4, y: 15 },
						{ x: 8.6, y: 15 },
						{ x: 8.8, y: 15 },
						{ x: 9.0, y: 15 },
						{ x: 9.2, y: 15 },
						{ x: 9.4, y: 15 },
						{ x: 9.6, y: 15 },
						{ x: 9.8, y: 15 },
						{ x: 10.0, y: 15 },
						{ x: 10.2, y: 15 },
						{ x: 10.4, y: 15 },
						{ x: 10.6, y: 15 },
						{ x: 10.8, y: 15 },
						{ x: 11.0, y: 15 },
						{ x: 11.2, y: 15 },
						{ x: 11.4, y: 15 },
						{ x: 11.6, y: 15 },
						{ x: 11.8, y: 15 },
						{ x: 12.0, y: 15 },
						{ x: 12.2, y: 15 },
						{ x: 12.4, y: 15 },
						{ x: 12.6, y: 15 },
						{ x: 12.8, y: 15 },
						{ x: 13.0, y: 15 },
						{ x: 13.2, y: 15 },
						{ x: 13.4, y: 15 },
						{ x: 13.6, y: 15 },
						{ x: 13.8, y: 15 },
						{ x: 14.0, y: 15 },
						{ x: 14.2, y: 15 },
						{ x: 14.4, y: 15 },
						{ x: 14.6, y: 15 },
						{ x: 14.8, y: 15 },
						{ x: 15.0, y: 15 },
						{ x: 15.2, y: 15 },
						{ x: 15.4, y: 15 },
						{ x: 15.6, y: 15 },
						{ x: 15.8, y: 15 },
						{ x: 16.0, y: 15 },
						{ x: 16.2, y: 15 },
						{ x: 16.4, y: 15 },
						{ x: 16.6, y: 15 },
						{ x: 16.8, y: 15 },
						{ x: 17.0, y: 15 },
						{ x: 17.2, y: 15 },
						{ x: 17.4, y: 15 },
						{ x: 17.6, y: 15 },
						{ x: 17.8, y: 15 },
						{ x: 18.0, y: 15 },
						{ x: 18.2, y: 15 },
						{ x: 18.4, y: 15 },
						{ x: 18.6, y: 15 },
						{ x: 18.8, y: 15 },
						{ x: 19.0, y: 15 },
						{ x: 19.2, y: 15 },
						{ x: 19.4, y: 15 },
						{ x: 19.6, y: 15 },
						{ x: 19.8, y: 15 },
						{ x: 20.0, y: 15 },
						{ x: 20.2, y: 15 },
						{ x: 20.4, y: 15 },
						{ x: 20.6, y: 15 },
						{ x: 20.8, y: 15 },
						{ x: 21.0, y: 15 },
						{ x: 21.2, y: 15 },
						{ x: 21.4, y: 15 },
						{ x: 21.6, y: 15 },
						{ x: 21.8, y: 15 },
						{ x: 22.0, y: 15 },
						{ x: 22.2, y: 15 },
						{ x: 22.4, y: 15 },
						{ x: 22.6, y: 15 },
						{ x: 22.8, y: 15 },
						{ x: 23.0, y: 15 },
						{ x: 23.2, y: 15 },
						{ x: 23.4, y: 15 },
						{ x: 23.6, y: 15 },
						{ x: 23.8, y: 15 },
						{ x: 24.0, y: 15 },
						{ x: 24.2, y: 15 },
						{ x: 24.4, y: 15 },
						{ x: 24.6, y: 15 },
						{ x: 24.8, y: 15 },
						{ x: 25.0, y: 15 },
						{ x: 25.2, y: 15 },
						{ x: 25.4, y: 15 },
						{ x: 25.6, y: 15 },
						{ x: 25.8, y: 15 },
						{ x: 26.0, y: 15 },
						{ x: 26.2, y: 15 },
						{ x: 26.4, y: 15 },
						{ x: 26.6, y: 15 },
						{ x: 26.8, y: 15 },
						{ x: 27.0, y: 15 },
						{ x: 27.2, y: 15 },
						{ x: 27.4, y: 15 },
						{ x: 27.6, y: 15 },
						{ x: 27.8, y: 15 },
						{ x: 28.0, y: 15 },
						{ x: 28.2, y: 15 },
						{ x: 28.4, y: 15 },
						{ x: 28.6, y: 15 },
						{ x: 28.8, y: 15 },
						{ x: 29.0, y: 15 },
						{ x: 29.2, y: 15 },
						{ x: 29.4, y: 15 },
						{ x: 29.6, y: 15 },
						{ x: 29.8, y: 15 },
						{ x: 30.0, y: 15 },
						{ x: 30.2, y: 15 },
						{ x: 30.4, y: 15 },
						{ x: 30.6, y: 15 },
						{ x: 30.8, y: 15 },
						{ x: 31.0, y: 15 },
						{ x: 31.2, y: 15 },
						{ x: 31.4, y: 15 },
						{ x: 31.6, y: 15 },
						{ x: 31.8, y: 15 },
						{ x: 32.0, y: 15 },
						{ x: 32.2, y: 15 },
						{ x: 32.4, y: 15 },
						{ x: 32.6, y: 15 },
						{ x: 32.8, y: 15 },
						{ x: 33.0, y: 15 },
						{ x: 33.2, y: 15 },
						{ x: 33.4, y: 15 },
						{ x: 33.6, y: 15 },
						{ x: 33.8, y: 15 },
						{ x: 34.0, y: 15 },
						{ x: 34.2, y: 15 },
						{ x: 34.4, y: 15 },
						{ x: 34.6, y: 15 },
						{ x: 34.8, y: 15 },
						{ x: 35.0, y: 15 },
						{ x: 35.2, y: 15 },
						{ x: 35.4, y: 15 },
						{ x: 35.6, y: 15 },
						{ x: 35.8, y: 15 },
						{ x: 36.0, y: 15 },
						{ x: 36.2, y: 15 },
						{ x: 36.4, y: 15 },
						{ x: 36.6, y: 15 },
						{ x: 36.8, y: 15 },
						{ x: 37.0, y: 15 },
						{ x: 37.2, y: 15 },
						{ x: 37.4, y: 15 },
						{ x: 37.6, y: 15 },
						{ x: 37.8, y: 15 },
						{ x: 38.0, y: 15 },
						{ x: 38.2, y: 15 },
						{ x: 38.4, y: 15 },
						{ x: 38.6, y: 15 },
						{ x: 38.8, y: 15 },
						{ x: 39.0, y: 15 },
						{ x: 39.2, y: 15 },
						{ x: 39.4, y: 15 },
						{ x: 39.6, y: 15 },
						{ x: 39.8, y: 15 },
						{ x: 40.0, y: 15 },
						{ x: 40.2, y: 15 },
						{ x: 40.4, y: 15 },
						{ x: 40.6, y: 15 },
						{ x: 40.8, y: 15 },
						{ x: 41.0, y: 15 },
						{ x: 41.2, y: 15 },
						{ x: 41.4, y: 15 },
						{ x: 41.6, y: 15 },
						{ x: 41.8, y: 15 },
						{ x: 42.0, y: 15 },
						{ x: 42.2, y: 15 },
						{ x: 42.4, y: 15 },
						{ x: 42.6, y: 15 },
						{ x: 42.8, y: 15 },
						{ x: 43.0, y: 15 },
						{ x: 43.2, y: 15 },
						{ x: 43.4, y: 15 },
						{ x: 43.6, y: 15 },
						{ x: 43.8, y: 15 },
						{ x: 44.0, y: 15 },
						{ x: 44.2, y: 15 },
						{ x: 44.4, y: 15 },
						{ x: 44.6, y: 15 },
						{ x: 44.8, y: 15 },
						{ x: 45.0, y: 15 },
						{ x: 45.2, y: 15 },
						{ x: 45.4, y: 15 },
						{ x: 45.6, y: 15 },
						{ x: 45.8, y: 15 },
						{ x: 46.0, y: 15 },
						{ x: 46.2, y: 15 },
						{ x: 46.4, y: 15 },
						{ x: 46.6, y: 15 },
						{ x: 46.8, y: 15 },
						{ x: 47.0, y: 15 },
						{ x: 47.2, y: 15 },
						{ x: 47.4, y: 15 },
						{ x: 47.6, y: 15 },
						{ x: 47.8, y: 15 },
						{ x: 48.0, y: 15 },
						{ x: 48.2, y: 15 },
						{ x: 48.4, y: 15 },
						{ x: 48.6, y: 15 },
						{ x: 48.8, y: 15 },
						{ x: 49.0, y: 15 },
						{ x: 49.2, y: 15 },
						{ x: 49.4, y: 15 },
						{ x: 49.6, y: 15 },
						{ x: 49.8, y: 15 },
						{ x: 50.0, y: 15 },
						{ x: 50.2, y: 15 },
						{ x: 50.4, y: 15 },
						{ x: 50.6, y: 15 },
						{ x: 50.8, y: 15 },
						{ x: 51.0, y: 15 },
						{ x: 51.2, y: 15 },
						{ x: 51.4, y: 15 },
						{ x: 51.6, y: 15 },
						{ x: 51.8, y: 15 },
						{ x: 52.0, y: 15 },
						{ x: 52.2, y: 15 },
						{ x: 52.4, y: 15 },
						{ x: 52.6, y: 15 },
						{ x: 52.8, y: 15 },
						{ x: 53.0, y: 15 },
						{ x: 53.2, y: 15 },
						{ x: 53.4, y: 15 },
						{ x: 53.6, y: 15 },
						{ x: 53.8, y: 15 },
						{ x: 54.0, y: 15 },
						{ x: 54.2, y: 15 },
						{ x: 54.4, y: 15 },
						{ x: 54.6, y: 15 },
						{ x: 54.8, y: 15 },
						{ x: 55.0, y: 15 },
						{ x: 55.2, y: 15 },
						{ x: 55.4, y: 15 },
						{ x: 55.6, y: 15 },
						{ x: 55.8, y: 15 },
						{ x: 56.0, y: 15 },
						{ x: 56.2, y: 15 },
						{ x: 56.4, y: 15 },
						{ x: 56.6, y: 15 },
						{ x: 56.8, y: 15 },
						{ x: 57.0, y: 15 },
						{ x: 57.2, y: 15 },
						{ x: 57.4, y: 15 },
						{ x: 57.6, y: 15 },
						{ x: 57.8, y: 15 },
						{ x: 58.0, y: 15 },
						{ x: 58.2, y: 15 },
						{ x: 58.4, y: 15 },
						{ x: 58.6, y: 15 },
						{ x: 58.8, y: 15 },
						{ x: 59.0, y: 15 },
						{ x: 59.2, y: 15 },
						{ x: 59.4, y: 15 },
						{ x: 59.6, y: 15 },
						{ x: 59.8, y: 15 },
						{ x: 60.0, y: 15 }
					],
					borderColor: "#ff0000",
					borderWidth: 3,
					pointRadius: 0
				}
			]
		}
	}),
	mounted () {
		Chart.defaults.color = '#ffffff'
		Chart.defaults.borderColor	= 'rgba(255, 255, 255, 0.5)'

		/* Animation Related Stuff Start */
		const delayBetweenPoints = 10;
		const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
		const animation = {
			x: {
				type: 'number',
				easing: 'linear',
				duration: delayBetweenPoints,
				from: NaN, // the point is initially skipped
				delay(ctx) {
					if (ctx.type !== 'data' || ctx.xStarted) {
						return 0;
					}
					ctx.xStarted = true;
					return ctx.index * delayBetweenPoints;
				}
			},
			y: {
				type: 'number',
				easing: 'linear',
				duration: delayBetweenPoints,
				from: previousY,
				delay(ctx) {
					if (ctx.type !== 'data' || ctx.yStarted) {
						return 0;
					}
					ctx.yStarted = true;
					return ctx.index * delayBetweenPoints;
				}
			}
		}
		/* Animation Related Stuff End */

    	this.chart = new Chart(this.$refs.chart.getContext('2d'), {
			type: 'line',
			data: this.chart_data,
			options: {
				interaction: {
					intersect: false
				},
				responsive: true,
				maintainAspectRatio: false,
				lineTension: 1,
				spanGaps: true,
				bezierCurve: false,
				scales: {
					x: {
						type: 'linear',
						ticks: {
							beginAtZero: true,
							padding: 25,
							min: 0,
							max: 60,
							callback: function(value, index, values) {
								if (value % 5 === 0 || value == 0) return value
							}
						}
					},
					y: {
						type: 'linear',
						ticks: {
							beginAtZero: true,
							padding: 25,
							min: 0,
							max: 100,
							callback: function(value, index, values) {
								return value + '%'
							}
						}
					}
				},
				animation,
				plugins: {
					legend: {
						display: false
					}
				}
			}
		})

		if (this.side == 'solution') this.updateChart('solution')
	},
	watch: {
		side (v) {
			// console.log(v)
			this.updateChart(v)
		}
	},
	methods: {
		updateChart (side) {
			let savings_dataset = this.chart.data.datasets.find(dataset => dataset.label == 'Savings in emission & fuel')
			if (savings_dataset && side == 'problem') {
				let i = this.chart.data.datasets.indexOf(savings_dataset)
				if (i > -1) this.chart.data.datasets.splice(i, 1)
			} else if (!savings_dataset && side == 'solution') {
				this.chart.data.datasets.push({
					label: "Savings in emission & fuel",
					lineTension: 0,
					fill: {
						target: 'origin',
						above: '#86c954'
					},
					data: [
						{ x: 0, y: 0 },
						{ x: 0.2, y: 0.333333333 },
						{ x: 0.4, y: 0.666666667 },
						{ x: 0.6, y: 1 },
						{ x: 0.8, y: 1.333333333 },
						{ x: 1, y: 1.666666667 },
						{ x: 1.2, y: 2 },
						{ x: 1.4, y: 2.333333333 },
						{ x: 1.6, y: 2.666666667 },
						{ x: 1.8, y: 3 },
						{ x: 2, y: 3.333333333 },
						{ x: 2.2, y: 3.666666667 },
						{ x: 2.4, y: 4 },
						{ x: 2.6, y: 4.333333333 },
						{ x: 2.8, y: 4.666666667 },
						{ x: 3, y: 5 },
						{ x: 3.2, y: 5.333333333 },
						{ x: 3.4, y: 5.666666667 },
						{ x: 3.6, y: 6 },
						{ x: 3.8, y: 6.333333333 },
						{ x: 4, y: 6.666666667 },
						{ x: 4.2, y: 7 },
						{ x: 4.4, y: 7.333333333 },
						{ x: 4.6, y: 7.666666667 },
						{ x: 4.8, y: 8 },
						{ x: 5, y: 8.333333333 },
						{ x: 5.2, y: 8.666666667 },
						{ x: 5.4, y: 9 },
						{ x: 5.6, y: 9.333333333 },
						{ x: 5.8, y: 9.666666667 },
						{ x: 6, y: 10 },
						{ x: 6.2, y: 10.33333333 },
						{ x: 6.4, y: 10.66666667 },
						{ x: 6.6, y: 11 },
						{ x: 6.8, y: 11.33333333 },
						{ x: 7, y: 11.66666667 },
						{ x: 7.2, y: 12 },
						{ x: 7.4, y: 12.33333333 },
						{ x: 7.6, y: 12.66666667 },
						{ x: 7.8, y: 13 },
						{ x: 8, y: 13.33333333 },
						{ x: 8.2, y: 13.66666667 },
						{ x: 8.4, y: 14 },
						{ x: 8.6, y: 14.33333333 },
						{ x: 8.8, y: 14.66666667 },
						{ x: 9, y: 15 },
						{ x: 9.2, y: 15.33333333 },
						{ x: 9.4, y: 15.66666667 },
						{ x: 9.6, y: 16 },
						{ x: 9.8, y: 16.33333333 },
						{ x: 10, y: 16.66666667 },
						{ x: 10.2, y: 17 },
						{ x: 10.4, y: 17.33333333 },
						{ x: 10.6, y: 17.66666667 },
						{ x: 10.8, y: 18 },
						{ x: 11, y: 18.33333333 },
						{ x: 11.2, y: 18.66666667 },
						{ x: 11.4, y: 19 },
						{ x: 11.6, y: 19.33333333 },
						{ x: 11.8, y: 19.66666667 },
						{ x: 12, y: 20 },
						{ x: 12, y: 3 },
						{ x: 12.3, y: 3.54375 },
						{ x: 12.6, y: 4.0875 },
						{ x: 12.9, y: 4.63125 },
						{ x: 13.2, y: 5.175 },
						{ x: 13.5, y: 5.71875 },
						{ x: 13.8, y: 6.2625 },
						{ x: 14.1, y: 6.80625 },
						{ x: 14.4, y: 7.35 },
						{ x: 14.7, y: 7.89375 },
						{ x: 15, y: 8.4375 },
						{ x: 15.3, y: 8.98125 },
						{ x: 15.6, y: 9.525 },
						{ x: 15.9, y: 10.06875 },
						{ x: 16.2, y: 10.6125 },
						{ x: 16.5, y: 11.15625 },
						{ x: 16.8, y: 11.7 },
						{ x: 17.1, y: 12.24375 },
						{ x: 17.4, y: 12.7875 },
						{ x: 17.7, y: 13.33125 },
						{ x: 18, y: 13.875 },
						{ x: 18.3, y: 14.41875 },
						{ x: 18.6, y: 14.9625 },
						{ x: 18.9, y: 15.50625 },
						{ x: 19.2, y: 16.05 },
						{ x: 19.5, y: 16.59375 },
						{ x: 19.8, y: 17.1375 },
						{ x: 20.1, y: 17.68125 },
						{ x: 20.4, y: 18.225 },
						{ x: 20.7, y: 18.76875 },
						{ x: 21, y: 19.3125 },
						{ x: 21.3, y: 19.85625 },
						{ x: 21.6, y: 20.4 },
						{ x: 21.9, y: 20.94375 },
						{ x: 22.2, y: 21.4875 },
						{ x: 22.5, y: 22.03125 },
						{ x: 22.8, y: 22.575 },
						{ x: 23.1, y: 23.11875 },
						{ x: 23.4, y: 23.6625 },
						{ x: 23.7, y: 24.20625 },
						{ x: 24, y: 24.75 },
						{ x: 24.3, y: 25.29375 },
						{ x: 24.6, y: 25.8375 },
						{ x: 24.9, y: 26.38125 },
						{ x: 25.2, y: 26.925 },
						{ x: 25.5, y: 27.46875 },
						{ x: 25.8, y: 28.0125 },
						{ x: 26.1, y: 28.55625 },
						{ x: 26.4, y: 29.1 },
						{ x: 26.7, y: 29.64375 },
						{ x: 27, y: 30.1875 },
						{ x: 27.3, y: 30.73125 },
						{ x: 27.6, y: 31.275 },
						{ x: 27.9, y: 31.81875 },
						{ x: 28, y: 15 },
						{ x: 28.1, y: 15.16 },
						{ x: 28.2, y: 15.32 },
						{ x: 28.3, y: 15.48 },
						{ x: 28.4, y: 15.64 },
						{ x: 28.5, y: 15.8 },
						{ x: 28.6, y: 15.96 },
						{ x: 28.7, y: 16.12 },
						{ x: 28.8, y: 16.28 },
						{ x: 28.9, y: 16.44 },
						{ x: 29, y: 16.6 },
						{ x: 29.1, y: 16.76 },
						{ x: 29.2, y: 16.92 },
						{ x: 29.3, y: 17.08 },
						{ x: 29.4, y: 17.24 },
						{ x: 29.5, y: 17.4 },
						{ x: 29.6, y: 17.56 },
						{ x: 29.7, y: 17.72 },
						{ x: 29.8, y: 17.88 },
						{ x: 29.9, y: 18.04 },
						{ x: 30, y: 18.2 },
						{ x: 30.1, y: 18.36 },
						{ x: 30.2, y: 18.52 },
						{ x: 30.3, y: 18.68 },
						{ x: 30.4, y: 18.84 },
						{ x: 30.5, y: 19 },
						{ x: 30.6, y: 19.16 },
						{ x: 30.7, y: 19.32 },
						{ x: 30.8, y: 19.48 },
						{ x: 30.9, y: 19.64 },
						{ x: 31, y: 19.8 },
						{ x: 31.1, y: 19.96 },
						{ x: 31.2, y: 20.12 },
						{ x: 31.3, y: 20.28 },
						{ x: 31.4, y: 20.44 },
						{ x: 31.5, y: 20.6 },
						{ x: 31.6, y: 20.76 },
						{ x: 31.7, y: 20.92 },
						{ x: 31.8, y: 21.08 },
						{ x: 31.9, y: 21.24 },
						{ x: 32, y: 21.4 },
						{ x: 32.1, y: 21.56 },
						{ x: 32.2, y: 21.72 },
						{ x: 32.3, y: 21.88 },
						{ x: 32.4, y: 22.04 },
						{ x: 32.5, y: 22.2 },
						{ x: 32.6, y: 22.36 },
						{ x: 32.7, y: 22.52 },
						{ x: 32.8, y: 22.68 },
						{ x: 32.9, y: 22.84 },
						{ x: 33, y: 17 },
						{ x: 33.1, y: 17.2 },
						{ x: 33.2, y: 17.4 },
						{ x: 33.3, y: 17.6 },
						{ x: 33.4, y: 17.8 },
						{ x: 33.5, y: 18 },
						{ x: 33.6, y: 18.2 },
						{ x: 33.7, y: 18.4 },
						{ x: 33.8, y: 18.6 },
						{ x: 33.9, y: 18.8 },
						{ x: 34, y: 19 },
						{ x: 34.1, y: 19.2 },
						{ x: 34.2, y: 19.4 },
						{ x: 34.3, y: 19.6 },
						{ x: 34.4, y: 19.8 },
						{ x: 34.5, y: 20 },
						{ x: 34.6, y: 20.2 },
						{ x: 34.7, y: 20.4 },
						{ x: 34.8, y: 20.6 },
						{ x: 34.9, y: 20.8 },
						{ x: 35, y: 21 },
						{ x: 35.1, y: 21.2 },
						{ x: 35.2, y: 21.4 },
						{ x: 35.3, y: 21.6 },
						{ x: 35.4, y: 21.8 },
						{ x: 35.5, y: 22 },
						{ x: 35.6, y: 22.2 },
						{ x: 35.7, y: 22.4 },
						{ x: 35.8, y: 22.6 },
						{ x: 35.9, y: 22.8 },
						{ x: 36, y: 23 },
						{ x: 36.1, y: 23.2 },
						{ x: 36.2, y: 23.4 },
						{ x: 36.3, y: 23.6 },
						{ x: 36.4, y: 23.8 },
						{ x: 36.5, y: 24 },
						{ x: 36.6, y: 24.2 },
						{ x: 36.7, y: 24.4 },
						{ x: 36.8, y: 24.6 },
						{ x: 36.9, y: 24.8 },
						{ x: 37, y: 25 },
						{ x: 37.1, y: 25.2 },
						{ x: 37.2, y: 25.4 },
						{ x: 37.3, y: 25.6 },
						{ x: 37.4, y: 25.8 },
						{ x: 37.5, y: 26 },
						{ x: 37.6, y: 26.2 },
						{ x: 37.7, y: 26.4 },
						{ x: 37.8, y: 26.6 },
						{ x: 37.9, y: 26.8 },
						{ x: 38, y: 27 },
						{ x: 38.1, y: 27.2 },
						{ x: 38.2, y: 27.4 },
						{ x: 38.3, y: 27.6 },
						{ x: 38.4, y: 27.8 },
						{ x: 38.5, y: 28 },
						{ x: 38.6, y: 28.2 },
						{ x: 38.7, y: 28.4 },
						{ x: 38.8, y: 28.6 },
						{ x: 38.9, y: 28.8 },
						{ x: 39, y: 29 },
						{ x: 39.1, y: 29.2 },
						{ x: 39.2, y: 29.4 },
						{ x: 39.3, y: 29.6 },
						{ x: 39.4, y: 29.8 },
						{ x: 39.5, y: 30 },
						{ x: 39.6, y: 30.2 },
						{ x: 39.7, y: 30.4 },
						{ x: 39.8, y: 30.6 },
						{ x: 39.9, y: 30.8 },
						{ x: 40, y: 31 },
						{ x: 40.1, y: 31.2 },
						{ x: 40.2, y: 31.4 },
						{ x: 40.3, y: 31.6 },
						{ x: 40.4, y: 31.8 },
						{ x: 40.5, y: 32 },
						{ x: 40.6, y: 32.2 },
						{ x: 40.7, y: 32.4 },
						{ x: 40.8, y: 32.6 },
						{ x: 40.9, y: 32.8 },
						{ x: 41, y: 3 },
						{ x: 41.1, y: 3.188888889 },
						{ x: 41.2, y: 3.377777778 },
						{ x: 41.3, y: 3.566666667 },
						{ x: 41.4, y: 3.755555556 },
						{ x: 41.5, y: 3.944444444 },
						{ x: 41.6, y: 4.133333333 },
						{ x: 41.7, y: 4.322222222 },
						{ x: 41.8, y: 4.511111111 },
						{ x: 41.9, y: 4.7 },
						{ x: 42, y: 4.888888889 },
						{ x: 42.1, y: 5.077777778 },
						{ x: 42.2, y: 5.266666667 },
						{ x: 42.3, y: 5.455555556 },
						{ x: 42.4, y: 5.644444444 },
						{ x: 42.5, y: 5.833333333 },
						{ x: 42.6, y: 6.022222222 },
						{ x: 42.7, y: 6.211111111 },
						{ x: 42.8, y: 6.4 },
						{ x: 42.9, y: 6.588888889 },
						{ x: 43, y: 6.777777778 },
						{ x: 43.1, y: 6.966666667 },
						{ x: 43.2, y: 7.155555556 },
						{ x: 43.3, y: 7.344444444 },
						{ x: 43.4, y: 7.533333333 },
						{ x: 43.5, y: 7.722222222 },
						{ x: 43.6, y: 7.911111111 },
						{ x: 43.7, y: 8.1 },
						{ x: 43.8, y: 8.288888889 },
						{ x: 43.9, y: 8.477777778 },
						{ x: 44, y: 8.666666667 },
						{ x: 44.1, y: 8.855555556 },
						{ x: 44.2, y: 9.044444444 },
						{ x: 44.3, y: 9.233333333 },
						{ x: 44.4, y: 9.422222222 },
						{ x: 44.5, y: 9.611111111 },
						{ x: 44.6, y: 9.8 },
						{ x: 44.7, y: 9.988888889 },
						{ x: 44.8, y: 10.17777778 },
						{ x: 44.9, y: 10.36666667 },
						{ x: 45, y: 10.55555556 },
						{ x: 45.1, y: 10.74444444 },
						{ x: 45.2, y: 10.93333333 },
						{ x: 45.3, y: 11.12222222 },
						{ x: 45.4, y: 11.31111111 },
						{ x: 45.5, y: 11.5 },
						{ x: 45.6, y: 11.68888889 },
						{ x: 45.7, y: 11.87777778 },
						{ x: 45.8, y: 12.06666667 },
						{ x: 45.9, y: 12.25555556 },
						{ x: 46, y: 12.44444444 },
						{ x: 46.1, y: 12.63333333 },
						{ x: 46.2, y: 12.82222222 },
						{ x: 46.3, y: 13.01111111 },
						{ x: 46.4, y: 13.2 },
						{ x: 46.5, y: 13.38888889 },
						{ x: 46.6, y: 13.57777778 },
						{ x: 46.7, y: 13.76666667 },
						{ x: 46.8, y: 13.95555556 },
						{ x: 46.9, y: 14.14444444 },
						{ x: 47, y: 14.33333333 },
						{ x: 47.1, y: 14.52222222 },
						{ x: 47.2, y: 14.71111111 },
						{ x: 47.3, y: 14.9 },
						{ x: 47.4, y: 15.08888889 },
						{ x: 47.5, y: 15.27777778 },
						{ x: 47.6, y: 15.46666667 },
						{ x: 47.7, y: 15.65555556 },
						{ x: 47.8, y: 15.84444444 },
						{ x: 47.9, y: 16.03333333 },
						{ x: 48, y: 16.22222222 },
						{ x: 48.1, y: 16.41111111 },
						{ x: 48.2, y: 16.6 },
						{ x: 48.3, y: 16.78888889 },
						{ x: 48.4, y: 16.97777778 },
						{ x: 48.5, y: 17.16666667 },
						{ x: 48.6, y: 17.35555556 },
						{ x: 48.7, y: 17.54444444 },
						{ x: 48.8, y: 17.73333333 },
						{ x: 48.9, y: 17.92222222 },
						{ x: 49, y: 18.11111111 },
						{ x: 49.1, y: 18.3 },
						{ x: 49.2, y: 18.48888889 },
						{ x: 49.3, y: 18.67777778 },
						{ x: 49.4, y: 18.86666667 },
						{ x: 49.5, y: 19.05555556 },
						{ x: 49.6, y: 19.24444444 },
						{ x: 49.7, y: 19.43333333 },
						{ x: 49.8, y: 19.62222222 },
						{ x: 49.9, y: 19.81111111 },
						{ x: 50, y: 12 },
						{ x: 50.03, y: 12.075 },
						{ x: 50.06, y: 12.15 },
						{ x: 50.09, y: 12.225 },
						{ x: 50.12, y: 12.3 },
						{ x: 50.15, y: 12.375 },
						{ x: 50.18, y: 12.45 },
						{ x: 50.21, y: 12.525 },
						{ x: 50.24, y: 12.6 },
						{ x: 50.27, y: 12.675 },
						{ x: 50.3, y: 12.75 },
						{ x: 50.33, y: 12.825 },
						{ x: 50.36, y: 12.9 },
						{ x: 50.39, y: 12.975 },
						{ x: 50.42, y: 13.05 },
						{ x: 50.45, y: 13.125 },
						{ x: 50.48, y: 13.2 },
						{ x: 50.51, y: 13.275 },
						{ x: 50.54, y: 13.35 },
						{ x: 50.57, y: 13.425 },
						{ x: 50.6, y: 13.5 },
						{ x: 50.63, y: 13.575 },
						{ x: 50.66, y: 13.65 },
						{ x: 50.69, y: 13.725 },
						{ x: 50.72, y: 13.8 },
						{ x: 50.75, y: 13.875 },
						{ x: 50.78, y: 13.95 },
						{ x: 50.81, y: 14.025 },
						{ x: 50.84, y: 14.1 },
						{ x: 50.87, y: 14.175 },
						{ x: 50.9, y: 14.25 },
						{ x: 50.93, y: 14.325 },
						{ x: 50.96, y: 14.4 },
						{ x: 50.99, y: 14.475 },
						{ x: 51.02, y: 14.55 },
						{ x: 51.05, y: 14.625 },
						{ x: 51.08, y: 14.7 },
						{ x: 51.11, y: 14.775 },
						{ x: 51.14, y: 14.85 },
						{ x: 51.17, y: 14.925 },
						{ x: 51.2, y: 15 },
						{ x: 51.23, y: 15.075 },
						{ x: 51.26, y: 15.15 },
						{ x: 51.29, y: 15.225 },
						{ x: 51.32, y: 15.3 },
						{ x: 51.35, y: 15.375 },
						{ x: 51.38, y: 15.45 },
						{ x: 51.41, y: 15.525 },
						{ x: 51.44, y: 15.6 },
						{ x: 51.47, y: 15.675 },
						{ x: 51.5, y: 15.75 },
						{ x: 51.53, y: 15.825 },
						{ x: 51.56, y: 15.9 },
						{ x: 51.59, y: 15.975 },
						{ x: 51.62, y: 16.05 },
						{ x: 51.65, y: 16.125 },
						{ x: 51.68, y: 16.2 },
						{ x: 51.71, y: 16.275 },
						{ x: 51.74, y: 16.35 },
						{ x: 51.77, y: 16.425 },
						{ x: 51.8, y: 16.5 },
						{ x: 51.83, y: 16.575 },
						{ x: 51.86, y: 16.65 },
						{ x: 51.89, y: 16.725 },
						{ x: 51.92, y: 16.8 },
						{ x: 51.95, y: 16.875 },
						{ x: 51.98, y: 16.95 },
						{ x: 52, y: 17 },
						{ x: 52.03, y: 17.0225 },
						{ x: 52.06, y: 17.15 },
						{ x: 52.09, y: 17.225 },
						{ x: 52.12, y: 17.3 },
						{ x: 52.15, y: 17.375 },
						{ x: 52.18, y: 17.45 },
						{ x: 52.21, y: 17.525 },
						{ x: 52.24, y: 17.6 },
						{ x: 52.27, y: 17.675 },
						{ x: 52.3, y: 17.75 },
						{ x: 52.33, y: 17.825 },
						{ x: 52.36, y: 17.9 },
						{ x: 52.39, y: 17.975 },
						{ x: 52.42, y: 18.05 },
						{ x: 52.45, y: 18.125 },
						{ x: 52.48, y: 18.2 },
						{ x: 52.51, y: 18.275 },
						{ x: 52.54, y: 18.35 },
						{ x: 52.57, y: 18.425 },
						{ x: 52.6, y: 18.5 },
						{ x: 52.63, y: 18.575 },
						{ x: 52.66, y: 18.65 },
						{ x: 52.69, y: 18.725 },
						{ x: 52.72, y: 18.8 },
						{ x: 52.75, y: 18.875 },
						{ x: 52.78, y: 18.95 },
						{ x: 52.81, y: 19.025 },
						{ x: 52.84, y: 19.1 },
						{ x: 52.87, y: 19.175 },
						{ x: 52.9, y: 19.25 },
						{ x: 52.93, y: 19.325 },
						{ x: 52.96, y: 19.4 },
						{ x: 52.99, y: 19.475 },
						{ x: 53.02, y: 19.55 },
						{ x: 53.05, y: 19.625 },
						{ x: 53.08, y: 19.7 },
						{ x: 53.11, y: 19.775 },
						{ x: 53.14, y: 19.85 },
						{ x: 53.17, y: 19.925 },
						{ x: 53.2, y: 20 },
						{ x: 53.23, y: 20.075 },
						{ x: 53.26, y: 20.15 },
						{ x: 53.29, y: 20.225 },
						{ x: 53.32, y: 20.3 },
						{ x: 53.35, y: 20.375 },
						{ x: 53.38, y: 20.45 },
						{ x: 53.41, y: 20.525 },
						{ x: 53.44, y: 20.6 },
						{ x: 53.47, y: 20.675 },
						{ x: 53.5, y: 20.75 },
						{ x: 53.53, y: 20.825 },
						{ x: 53.56, y: 20.9 },
						{ x: 53.59, y: 20.975 },
						{ x: 53.62, y: 21.05 },
						{ x: 53.65, y: 21.125 },
						{ x: 53.68, y: 21.2 },
						{ x: 53.71, y: 21.275 },
						{ x: 53.74, y: 21.35 },
						{ x: 53.77, y: 21.425 },
						{ x: 53.8, y: 21.5 },
						{ x: 53.83, y: 21.575 },
						{ x: 53.86, y: 21.65 },
						{ x: 53.89, y: 21.725 },
						{ x: 53.92, y: 21.8 },
						{ x: 53.95, y: 21.875 },
						{ x: 53.98, y: 21.95 },
						{ x: 54.01, y: 22.025 },
						{ x: 54.04, y: 22.1 },
						{ x: 54.07, y: 22.175 },
						{ x: 54.1, y: 22.25 },
						{ x: 54.13, y: 22.325 },
						{ x: 54.16, y: 22.4 },
						{ x: 54.19, y: 22.475 },
						{ x: 54.22, y: 22.55 },
						{ x: 54.25, y: 22.625 },
						{ x: 54.28, y: 22.7 },
						{ x: 54.31, y: 22.775 },
						{ x: 54.34, y: 22.85 },
						{ x: 54.37, y: 22.925 },
						{ x: 54.4, y: 23 },
						{ x: 54.43, y: 23.075 },
						{ x: 54.46, y: 23.15 },
						{ x: 54.49, y: 23.225 },
						{ x: 54.52, y: 23.3 },
						{ x: 54.55, y: 23.375 },
						{ x: 54.58, y: 23.45 },
						{ x: 54.61, y: 23.525 },
						{ x: 54.64, y: 23.6 },
						{ x: 54.67, y: 23.675 },
						{ x: 54.7, y: 23.75 },
						{ x: 54.73, y: 23.825 },
						{ x: 54.76, y: 23.9 },
						{ x: 54.79, y: 23.975 },
						{ x: 54.82, y: 24.05 },
						{ x: 54.85, y: 24.125 },
						{ x: 54.88, y: 24.2 },
						{ x: 54.91, y: 24.275 },
						{ x: 54.94, y: 24.35 },
						{ x: 54.97, y: 24.425 },
						{ x: 55, y: 24.5 },
						{ x: 55.03, y: 24.575 },
						{ x: 55.06, y: 24.65 },
						{ x: 55.09, y: 24.725 },
						{ x: 55.12, y: 24.8 },
						{ x: 55.15, y: 24.875 },
						{ x: 55.18, y: 24.95 },
						{ x: 55.21, y: 25.025 },
						{ x: 55.24, y: 25.1 },
						{ x: 55.27, y: 25.175 },
						{ x: 55.3, y: 25.25 },
						{ x: 55.33, y: 25.325 },
						{ x: 55.36, y: 25.4 },
						{ x: 55.39, y: 25.475 },
						{ x: 55.42, y: 25.55 },
						{ x: 55.45, y: 25.625 },
						{ x: 55.48, y: 25.7 },
						{ x: 55.51, y: 25.775 },
						{ x: 55.54, y: 25.85 },
						{ x: 55.57, y: 25.925 },
						{ x: 55.6, y: 26 },
						{ x: 55.63, y: 26.075 },
						{ x: 55.66, y: 26.15 },
						{ x: 55.69, y: 26.225 },
						{ x: 55.72, y: 26.3 },
						{ x: 55.75, y: 26.375 },
						{ x: 55.78, y: 26.45 },
						{ x: 55.81, y: 26.525 },
						{ x: 55.84, y: 26.6 },
						{ x: 55.87, y: 26.675 },
						{ x: 55.9, y: 26.75 },
						{ x: 55.93, y: 26.825 },
						{ x: 55.96, y: 26.9 },
						{ x: 55.99, y: 26.975 },
						{ x: 55.96, y: 26.9 },
						{ x: 55.99, y: 26.975 },
						{ x: 56.02, y: 27.05 },
						{ x: 56.05, y: 27.125 },
						{ x: 56.08, y: 27.2 },
						{ x: 56.11, y: 27.275 },
						{ x: 56.14, y: 27.35 },
						{ x: 56.17, y: 27.425 },
						{ x: 56.2, y: 27.5 },
						{ x: 56.23, y: 27.575 },
						{ x: 56.26, y: 27.65 },
						{ x: 56.29, y: 27.725 },
						{ x: 56.32, y: 27.8 },
						{ x: 56.35, y: 27.875 },
						{ x: 56.38, y: 27.95 },
						{ x: 56.41, y: 28.025 },
						{ x: 56.44, y: 28.1 },
						{ x: 56.47, y: 28.175 },
						{ x: 56.5, y: 28.25 },
						{ x: 56.53, y: 28.325 },
						{ x: 56.56, y: 28.4 },
						{ x: 56.59, y: 28.475 },
						{ x: 56.62, y: 28.55 },
						{ x: 56.65, y: 28.625 },
						{ x: 56.68, y: 28.7 },
						{ x: 56.71, y: 28.775 },
						{ x: 56.74, y: 28.85 },
						{ x: 56.77, y: 28.925 },
						{ x: 56.8, y: 29 },
						{ x: 56.83, y: 29.075 },
						{ x: 56.86, y: 29.15 },
						{ x: 56.89, y: 29.225 },
						{ x: 56.92, y: 29.3 },
						{ x: 56.95, y: 29.375 },
						{ x: 56.98, y: 29.45 },
						{ x: 57.01, y: 29.525 },
						{ x: 57.04, y: 29.6 },
						{ x: 57.07, y: 29.675 },
						{ x: 57.1, y: 29.75 },
						{ x: 57.13, y: 29.825 },
						{ x: 57.16, y: 29.9 },
						{ x: 57.19, y: 29.975 },
						{ x: 57.22, y: 30.05 },
						{ x: 57.25, y: 30.125 },
						{ x: 57.28, y: 30.2 },
						{ x: 57.31, y: 30.275 },
						{ x: 57.34, y: 30.35 },
						{ x: 57.37, y: 30.425 },
						{ x: 57.4, y: 30.5 },
						{ x: 57.43, y: 30.575 },
						{ x: 57.46, y: 30.65 },
						{ x: 57.49, y: 30.725 },
						{ x: 57.52, y: 30.8 },
						{ x: 57.55, y: 30.875 },
						{ x: 57.58, y: 30.95 },
						{ x: 57.61, y: 31.025 },
						{ x: 57.64, y: 31.1 },
						{ x: 57.67, y: 31.175 },
						{ x: 57.7, y: 31.25 },
						{ x: 57.73, y: 31.325 },
						{ x: 57.76, y: 31.4 },
						{ x: 57.79, y: 31.475 },
						{ x: 57.82, y: 31.55 },
						{ x: 57.85, y: 31.625 },
						{ x: 57.88, y: 31.7 },
						{ x: 57.91, y: 31.775 },
						{ x: 57.94, y: 31.85 },
						{ x: 57.97, y: 31.925 },
						{ x: 58, y: 32 },
						{ x: 58.03, y: 32.075 },
						{ x: 58.06, y: 32.15 },
						{ x: 58.09, y: 32.225 },
						{ x: 58.12, y: 32.3 },
						{ x: 58.15, y: 32.375 },
						{ x: 58.18, y: 32.45 },
						{ x: 58.21, y: 32.525 },
						{ x: 58.24, y: 32.6 },
						{ x: 58.27, y: 32.675 },
						{ x: 58.3, y: 32.75 },
						{ x: 58.33, y: 32.825 },
						{ x: 58.36, y: 32.9 },
						{ x: 58.39, y: 32.975 },
						{ x: 58.42, y: 33.05 },
						{ x: 58.45, y: 33.125 },
						{ x: 58.48, y: 33.2 },
						{ x: 58.51, y: 33.275 },
						{ x: 58.54, y: 33.35 },
						{ x: 58.57, y: 33.425 },
						{ x: 58.6, y: 33.5 },
						{ x: 58.63, y: 33.575 },
						{ x: 58.66, y: 33.65 },
						{ x: 58.69, y: 33.725 },
						{ x: 58.72, y: 33.8 },
						{ x: 58.75, y: 33.875 },
						{ x: 58.78, y: 33.95 },
						{ x: 58.81, y: 34.025 },
						{ x: 58.84, y: 34.1 },
						{ x: 58.87, y: 34.175 },
						{ x: 58.9, y: 34.25 },
						{ x: 58.93, y: 34.325 },
						{ x: 58.96, y: 34.4 },
						{ x: 58.99, y: 34.475 },
						{ x: 59.02, y: 34.55 },
						{ x: 59.05, y: 34.625 },
						{ x: 59.08, y: 34.7 },
						{ x: 59.11, y: 34.775 },
						{ x: 59.14, y: 34.85 },
						{ x: 59.17, y: 34.925 },
						{ x: 59.2, y: 35 },
						{ x: 59.23, y: 35.075 },
						{ x: 59.26, y: 35.15 },
						{ x: 59.29, y: 35.225 },
						{ x: 59.32, y: 35.3 },
						{ x: 59.35, y: 35.375 },
						{ x: 59.38, y: 35.45 },
						{ x: 59.41, y: 35.525 },
						{ x: 59.44, y: 35.6 },
						{ x: 59.47, y: 35.675 },
						{ x: 59.5, y: 35.75 },
						{ x: 59.53, y: 35.825 },
						{ x: 59.56, y: 35.9 },
						{ x: 59.59, y: 35.975 },
						{ x: 59.62, y: 36.05 },
						{ x: 59.65, y: 36.125 },
						{ x: 59.68, y: 36.2 },
						{ x: 59.71, y: 36.275 },
						{ x: 59.74, y: 36.35 },
						{ x: 59.77, y: 36.425 },
						{ x: 59.8, y: 36.5 },
						{ x: 59.83, y: 36.575 },
						{ x: 59.86, y: 36.65 },
						{ x: 59.89, y: 36.725 },
						{ x: 59.92, y: 36.8 },
						{ x: 59.95, y: 36.875 },
						{ x: 59.98, y: 36.95 },
						{ x: 60, y: 37 }
					],
					borderColor: "#86c954",
					borderWidth: 3,
					pointRadius: 0
				})
			}
			// else {
			// 	console.log('if you see this chart is broken')
			// }

			let power_increase_dataset = this.chart.data.datasets.find(dataset => dataset.label == 'Power Increase Over Time')

			// power_increase_dataset.data.find(item => item == { x: 1.5, y: 2 })
			if (!power_increase_dataset.data.find(item => JSON.stringify(item) == JSON.stringify({ x: 0.2, y: 0.333333333 }))) {
				power_increase_dataset.data = [
					{ x: 0, y: 0 },
					{ x: 0.2, y: 0.333333333 },
					{ x: 0.4, y: 0.666666667 },
					{ x: 0.6, y: 1 },
					{ x: 0.8, y: 1.333333333 },
					{ x: 1, y: 1.666666667 },
					{ x: 1.2, y: 2 },
					{ x: 1.4, y: 2.333333333 },
					{ x: 1.6, y: 2.666666667 },
					{ x: 1.8, y: 3 },
					{ x: 2, y: 3.333333333 },
					{ x: 2.2, y: 3.666666667 },
					{ x: 2.4, y: 4 },
					{ x: 2.6, y: 4.333333333 },
					{ x: 2.8, y: 4.666666667 },
					{ x: 3, y: 5 },
					{ x: 3.2, y: 5.333333333 },
					{ x: 3.4, y: 5.666666667 },
					{ x: 3.6, y: 6 },
					{ x: 3.8, y: 6.333333333 },
					{ x: 4, y: 6.666666667 },
					{ x: 4.2, y: 7 },
					{ x: 4.4, y: 7.333333333 },
					{ x: 4.6, y: 7.666666667 },
					{ x: 4.8, y: 8 },
					{ x: 5, y: 8.333333333 },
					{ x: 5.2, y: 8.666666667 },
					{ x: 5.4, y: 9 },
					{ x: 5.6, y: 9.333333333 },
					{ x: 5.8, y: 9.666666667 },
					{ x: 6, y: 10 },
					{ x: 6.2, y: 10.33333333 },
					{ x: 6.4, y: 10.66666667 },
					{ x: 6.6, y: 11 },
					{ x: 6.8, y: 11.33333333 },
					{ x: 7, y: 11.66666667 },
					{ x: 7.2, y: 12 },
					{ x: 7.4, y: 12.33333333 },
					{ x: 7.6, y: 12.66666667 },
					{ x: 7.8, y: 13 },
					{ x: 8, y: 13.33333333 },
					{ x: 8.2, y: 13.66666667 },
					{ x: 8.4, y: 14 },
					{ x: 8.6, y: 14.33333333 },
					{ x: 8.8, y: 14.66666667 },
					{ x: 9, y: 15 },
					{ x: 9.2, y: 15.33333333 },
					{ x: 9.4, y: 15.66666667 },
					{ x: 9.6, y: 16 },
					{ x: 9.8, y: 16.33333333 },
					{ x: 10, y: 16.66666667 },
					{ x: 10.2, y: 17 },
					{ x: 10.4, y: 17.33333333 },
					{ x: 10.6, y: 17.66666667 },
					{ x: 10.8, y: 18 },
					{ x: 11, y: 18.33333333 },
					{ x: 11.2, y: 18.66666667 },
					{ x: 11.4, y: 19 },
					{ x: 11.6, y: 19.33333333 },
					{ x: 11.8, y: 19.66666667 },
					{ x: 12, y: 20 },
					{ x: 12, y: 3 },
					{ x: 12.3, y: 3.54375 },
					{ x: 12.6, y: 4.0875 },
					{ x: 12.9, y: 4.63125 },
					{ x: 13.2, y: 5.175 },
					{ x: 13.5, y: 5.71875 },
					{ x: 13.8, y: 6.2625 },
					{ x: 14.1, y: 6.80625 },
					{ x: 14.4, y: 7.35 },
					{ x: 14.7, y: 7.89375 },
					{ x: 15, y: 8.4375 },
					{ x: 15.3, y: 8.98125 },
					{ x: 15.6, y: 9.525 },
					{ x: 15.9, y: 10.06875 },
					{ x: 16.2, y: 10.6125 },
					{ x: 16.5, y: 11.15625 },
					{ x: 16.8, y: 11.7 },
					{ x: 17.1, y: 12.24375 },
					{ x: 17.4, y: 12.7875 },
					{ x: 17.7, y: 13.33125 },
					{ x: 18, y: 13.875 },
					{ x: 18.3, y: 14.41875 },
					{ x: 18.6, y: 14.9625 },
					{ x: 18.9, y: 15.50625 },
					{ x: 19.2, y: 16.05 },
					{ x: 19.5, y: 16.59375 },
					{ x: 19.8, y: 17.1375 },
					{ x: 20.1, y: 17.68125 },
					{ x: 20.4, y: 18.225 },
					{ x: 20.7, y: 18.76875 },
					{ x: 21, y: 19.3125 },
					{ x: 21.3, y: 19.85625 },
					{ x: 21.6, y: 20.4 },
					{ x: 21.9, y: 20.94375 },
					{ x: 22.2, y: 21.4875 },
					{ x: 22.5, y: 22.03125 },
					{ x: 22.8, y: 22.575 },
					{ x: 23.1, y: 23.11875 },
					{ x: 23.4, y: 23.6625 },
					{ x: 23.7, y: 24.20625 },
					{ x: 24, y: 24.75 },
					{ x: 24.3, y: 25.29375 },
					{ x: 24.6, y: 25.8375 },
					{ x: 24.9, y: 26.38125 },
					{ x: 25.2, y: 26.925 },
					{ x: 25.5, y: 27.46875 },
					{ x: 25.8, y: 28.0125 },
					{ x: 26.1, y: 28.55625 },
					{ x: 26.4, y: 29.1 },
					{ x: 26.7, y: 29.64375 },
					{ x: 27, y: 30.1875 },
					{ x: 27.3, y: 30.73125 },
					{ x: 27.6, y: 31.275 },
					{ x: 27.9, y: 31.81875 },
					{ x: 28, y: 15 },
					{ x: 28.1, y: 15.16 },
					{ x: 28.2, y: 15.32 },
					{ x: 28.3, y: 15.48 },
					{ x: 28.4, y: 15.64 },
					{ x: 28.5, y: 15.8 },
					{ x: 28.6, y: 15.96 },
					{ x: 28.7, y: 16.12 },
					{ x: 28.8, y: 16.28 },
					{ x: 28.9, y: 16.44 },
					{ x: 29, y: 16.6 },
					{ x: 29.1, y: 16.76 },
					{ x: 29.2, y: 16.92 },
					{ x: 29.3, y: 17.08 },
					{ x: 29.4, y: 17.24 },
					{ x: 29.5, y: 17.4 },
					{ x: 29.6, y: 17.56 },
					{ x: 29.7, y: 17.72 },
					{ x: 29.8, y: 17.88 },
					{ x: 29.9, y: 18.04 },
					{ x: 30, y: 18.2 },
					{ x: 30.1, y: 18.36 },
					{ x: 30.2, y: 18.52 },
					{ x: 30.3, y: 18.68 },
					{ x: 30.4, y: 18.84 },
					{ x: 30.5, y: 19 },
					{ x: 30.6, y: 19.16 },
					{ x: 30.7, y: 19.32 },
					{ x: 30.8, y: 19.48 },
					{ x: 30.9, y: 19.64 },
					{ x: 31, y: 19.8 },
					{ x: 31.1, y: 19.96 },
					{ x: 31.2, y: 20.12 },
					{ x: 31.3, y: 20.28 },
					{ x: 31.4, y: 20.44 },
					{ x: 31.5, y: 20.6 },
					{ x: 31.6, y: 20.76 },
					{ x: 31.7, y: 20.92 },
					{ x: 31.8, y: 21.08 },
					{ x: 31.9, y: 21.24 },
					{ x: 32, y: 21.4 },
					{ x: 32.1, y: 21.56 },
					{ x: 32.2, y: 21.72 },
					{ x: 32.3, y: 21.88 },
					{ x: 32.4, y: 22.04 },
					{ x: 32.5, y: 22.2 },
					{ x: 32.6, y: 22.36 },
					{ x: 32.7, y: 22.52 },
					{ x: 32.8, y: 22.68 },
					{ x: 32.9, y: 22.84 },
					{ x: 33, y: 17 },
					{ x: 33.1, y: 17.2 },
					{ x: 33.2, y: 17.4 },
					{ x: 33.3, y: 17.6 },
					{ x: 33.4, y: 17.8 },
					{ x: 33.5, y: 18 },
					{ x: 33.6, y: 18.2 },
					{ x: 33.7, y: 18.4 },
					{ x: 33.8, y: 18.6 },
					{ x: 33.9, y: 18.8 },
					{ x: 34, y: 19 },
					{ x: 34.1, y: 19.2 },
					{ x: 34.2, y: 19.4 },
					{ x: 34.3, y: 19.6 },
					{ x: 34.4, y: 19.8 },
					{ x: 34.5, y: 20 },
					{ x: 34.6, y: 20.2 },
					{ x: 34.7, y: 20.4 },
					{ x: 34.8, y: 20.6 },
					{ x: 34.9, y: 20.8 },
					{ x: 35, y: 21 },
					{ x: 35.1, y: 21.2 },
					{ x: 35.2, y: 21.4 },
					{ x: 35.3, y: 21.6 },
					{ x: 35.4, y: 21.8 },
					{ x: 35.5, y: 22 },
					{ x: 35.6, y: 22.2 },
					{ x: 35.7, y: 22.4 },
					{ x: 35.8, y: 22.6 },
					{ x: 35.9, y: 22.8 },
					{ x: 36, y: 23 },
					{ x: 36.1, y: 23.2 },
					{ x: 36.2, y: 23.4 },
					{ x: 36.3, y: 23.6 },
					{ x: 36.4, y: 23.8 },
					{ x: 36.5, y: 24 },
					{ x: 36.6, y: 24.2 },
					{ x: 36.7, y: 24.4 },
					{ x: 36.8, y: 24.6 },
					{ x: 36.9, y: 24.8 },
					{ x: 37, y: 25 },
					{ x: 37.1, y: 25.2 },
					{ x: 37.2, y: 25.4 },
					{ x: 37.3, y: 25.6 },
					{ x: 37.4, y: 25.8 },
					{ x: 37.5, y: 26 },
					{ x: 37.6, y: 26.2 },
					{ x: 37.7, y: 26.4 },
					{ x: 37.8, y: 26.6 },
					{ x: 37.9, y: 26.8 },
					{ x: 38, y: 27 },
					{ x: 38.1, y: 27.2 },
					{ x: 38.2, y: 27.4 },
					{ x: 38.3, y: 27.6 },
					{ x: 38.4, y: 27.8 },
					{ x: 38.5, y: 28 },
					{ x: 38.6, y: 28.2 },
					{ x: 38.7, y: 28.4 },
					{ x: 38.8, y: 28.6 },
					{ x: 38.9, y: 28.8 },
					{ x: 39, y: 29 },
					{ x: 39.1, y: 29.2 },
					{ x: 39.2, y: 29.4 },
					{ x: 39.3, y: 29.6 },
					{ x: 39.4, y: 29.8 },
					{ x: 39.5, y: 30 },
					{ x: 39.6, y: 30.2 },
					{ x: 39.7, y: 30.4 },
					{ x: 39.8, y: 30.6 },
					{ x: 39.9, y: 30.8 },
					{ x: 40, y: 31 },
					{ x: 40.1, y: 31.2 },
					{ x: 40.2, y: 31.4 },
					{ x: 40.3, y: 31.6 },
					{ x: 40.4, y: 31.8 },
					{ x: 40.5, y: 32 },
					{ x: 40.6, y: 32.2 },
					{ x: 40.7, y: 32.4 },
					{ x: 40.8, y: 32.6 },
					{ x: 40.9, y: 32.8 },
					{ x: 41, y: 3 },
					{ x: 41.1, y: 3.188888889 },
					{ x: 41.2, y: 3.377777778 },
					{ x: 41.3, y: 3.566666667 },
					{ x: 41.4, y: 3.755555556 },
					{ x: 41.5, y: 3.944444444 },
					{ x: 41.6, y: 4.133333333 },
					{ x: 41.7, y: 4.322222222 },
					{ x: 41.8, y: 4.511111111 },
					{ x: 41.9, y: 4.7 },
					{ x: 42, y: 4.888888889 },
					{ x: 42.1, y: 5.077777778 },
					{ x: 42.2, y: 5.266666667 },
					{ x: 42.3, y: 5.455555556 },
					{ x: 42.4, y: 5.644444444 },
					{ x: 42.5, y: 5.833333333 },
					{ x: 42.6, y: 6.022222222 },
					{ x: 42.7, y: 6.211111111 },
					{ x: 42.8, y: 6.4 },
					{ x: 42.9, y: 6.588888889 },
					{ x: 43, y: 6.777777778 },
					{ x: 43.1, y: 6.966666667 },
					{ x: 43.2, y: 7.155555556 },
					{ x: 43.3, y: 7.344444444 },
					{ x: 43.4, y: 7.533333333 },
					{ x: 43.5, y: 7.722222222 },
					{ x: 43.6, y: 7.911111111 },
					{ x: 43.7, y: 8.1 },
					{ x: 43.8, y: 8.288888889 },
					{ x: 43.9, y: 8.477777778 },
					{ x: 44, y: 8.666666667 },
					{ x: 44.1, y: 8.855555556 },
					{ x: 44.2, y: 9.044444444 },
					{ x: 44.3, y: 9.233333333 },
					{ x: 44.4, y: 9.422222222 },
					{ x: 44.5, y: 9.611111111 },
					{ x: 44.6, y: 9.8 },
					{ x: 44.7, y: 9.988888889 },
					{ x: 44.8, y: 10.17777778 },
					{ x: 44.9, y: 10.36666667 },
					{ x: 45, y: 10.55555556 },
					{ x: 45.1, y: 10.74444444 },
					{ x: 45.2, y: 10.93333333 },
					{ x: 45.3, y: 11.12222222 },
					{ x: 45.4, y: 11.31111111 },
					{ x: 45.5, y: 11.5 },
					{ x: 45.6, y: 11.68888889 },
					{ x: 45.7, y: 11.87777778 },
					{ x: 45.8, y: 12.06666667 },
					{ x: 45.9, y: 12.25555556 },
					{ x: 46, y: 12.44444444 },
					{ x: 46.1, y: 12.63333333 },
					{ x: 46.2, y: 12.82222222 },
					{ x: 46.3, y: 13.01111111 },
					{ x: 46.4, y: 13.2 },
					{ x: 46.5, y: 13.38888889 },
					{ x: 46.6, y: 13.57777778 },
					{ x: 46.7, y: 13.76666667 },
					{ x: 46.8, y: 13.95555556 },
					{ x: 46.9, y: 14.14444444 },
					{ x: 47, y: 14.33333333 },
					{ x: 47.1, y: 14.52222222 },
					{ x: 47.2, y: 14.71111111 },
					{ x: 47.3, y: 14.9 },
					{ x: 47.4, y: 15.08888889 },
					{ x: 47.5, y: 15.27777778 },
					{ x: 47.6, y: 15.46666667 },
					{ x: 47.7, y: 15.65555556 },
					{ x: 47.8, y: 15.84444444 },
					{ x: 47.9, y: 16.03333333 },
					{ x: 48, y: 16.22222222 },
					{ x: 48.1, y: 16.41111111 },
					{ x: 48.2, y: 16.6 },
					{ x: 48.3, y: 16.78888889 },
					{ x: 48.4, y: 16.97777778 },
					{ x: 48.5, y: 17.16666667 },
					{ x: 48.6, y: 17.35555556 },
					{ x: 48.7, y: 17.54444444 },
					{ x: 48.8, y: 17.73333333 },
					{ x: 48.9, y: 17.92222222 },
					{ x: 49, y: 18.11111111 },
					{ x: 49.1, y: 18.3 },
					{ x: 49.2, y: 18.48888889 },
					{ x: 49.3, y: 18.67777778 },
					{ x: 49.4, y: 18.86666667 },
					{ x: 49.5, y: 19.05555556 },
					{ x: 49.6, y: 19.24444444 },
					{ x: 49.7, y: 19.43333333 },
					{ x: 49.8, y: 19.62222222 },
					{ x: 49.9, y: 19.81111111 },
					{ x: 50, y: 12 },
					{ x: 50.03, y: 12.075 },
					{ x: 50.06, y: 12.15 },
					{ x: 50.09, y: 12.225 },
					{ x: 50.12, y: 12.3 },
					{ x: 50.15, y: 12.375 },
					{ x: 50.18, y: 12.45 },
					{ x: 50.21, y: 12.525 },
					{ x: 50.24, y: 12.6 },
					{ x: 50.27, y: 12.675 },
					{ x: 50.3, y: 12.75 },
					{ x: 50.33, y: 12.825 },
					{ x: 50.36, y: 12.9 },
					{ x: 50.39, y: 12.975 },
					{ x: 50.42, y: 13.05 },
					{ x: 50.45, y: 13.125 },
					{ x: 50.48, y: 13.2 },
					{ x: 50.51, y: 13.275 },
					{ x: 50.54, y: 13.35 },
					{ x: 50.57, y: 13.425 },
					{ x: 50.6, y: 13.5 },
					{ x: 50.63, y: 13.575 },
					{ x: 50.66, y: 13.65 },
					{ x: 50.69, y: 13.725 },
					{ x: 50.72, y: 13.8 },
					{ x: 50.75, y: 13.875 },
					{ x: 50.78, y: 13.95 },
					{ x: 50.81, y: 14.025 },
					{ x: 50.84, y: 14.1 },
					{ x: 50.87, y: 14.175 },
					{ x: 50.9, y: 14.25 },
					{ x: 50.93, y: 14.325 },
					{ x: 50.96, y: 14.4 },
					{ x: 50.99, y: 14.475 },
					{ x: 51.02, y: 14.55 },
					{ x: 51.05, y: 14.625 },
					{ x: 51.08, y: 14.7 },
					{ x: 51.11, y: 14.775 },
					{ x: 51.14, y: 14.85 },
					{ x: 51.17, y: 14.925 },
					{ x: 51.2, y: 15 },
					{ x: 51.23, y: 15.075 },
					{ x: 51.26, y: 15.15 },
					{ x: 51.29, y: 15.225 },
					{ x: 51.32, y: 15.3 },
					{ x: 51.35, y: 15.375 },
					{ x: 51.38, y: 15.45 },
					{ x: 51.41, y: 15.525 },
					{ x: 51.44, y: 15.6 },
					{ x: 51.47, y: 15.675 },
					{ x: 51.5, y: 15.75 },
					{ x: 51.53, y: 15.825 },
					{ x: 51.56, y: 15.9 },
					{ x: 51.59, y: 15.975 },
					{ x: 51.62, y: 16.05 },
					{ x: 51.65, y: 16.125 },
					{ x: 51.68, y: 16.2 },
					{ x: 51.71, y: 16.275 },
					{ x: 51.74, y: 16.35 },
					{ x: 51.77, y: 16.425 },
					{ x: 51.8, y: 16.5 },
					{ x: 51.83, y: 16.575 },
					{ x: 51.86, y: 16.65 },
					{ x: 51.89, y: 16.725 },
					{ x: 51.92, y: 16.8 },
					{ x: 51.95, y: 16.875 },
					{ x: 51.98, y: 16.95 },
					{ x: 52, y: 17 },
					{ x: 52.03, y: 17.0225 },
					{ x: 52.06, y: 17.15 },
					{ x: 52.09, y: 17.225 },
					{ x: 52.12, y: 17.3 },
					{ x: 52.15, y: 17.375 },
					{ x: 52.18, y: 17.45 },
					{ x: 52.21, y: 17.525 },
					{ x: 52.24, y: 17.6 },
					{ x: 52.27, y: 17.675 },
					{ x: 52.3, y: 17.75 },
					{ x: 52.33, y: 17.825 },
					{ x: 52.36, y: 17.9 },
					{ x: 52.39, y: 17.975 },
					{ x: 52.42, y: 18.05 },
					{ x: 52.45, y: 18.125 },
					{ x: 52.48, y: 18.2 },
					{ x: 52.51, y: 18.275 },
					{ x: 52.54, y: 18.35 },
					{ x: 52.57, y: 18.425 },
					{ x: 52.6, y: 18.5 },
					{ x: 52.63, y: 18.575 },
					{ x: 52.66, y: 18.65 },
					{ x: 52.69, y: 18.725 },
					{ x: 52.72, y: 18.8 },
					{ x: 52.75, y: 18.875 },
					{ x: 52.78, y: 18.95 },
					{ x: 52.81, y: 19.025 },
					{ x: 52.84, y: 19.1 },
					{ x: 52.87, y: 19.175 },
					{ x: 52.9, y: 19.25 },
					{ x: 52.93, y: 19.325 },
					{ x: 52.96, y: 19.4 },
					{ x: 52.99, y: 19.475 },
					{ x: 53.02, y: 19.55 },
					{ x: 53.05, y: 19.625 },
					{ x: 53.08, y: 19.7 },
					{ x: 53.11, y: 19.775 },
					{ x: 53.14, y: 19.85 },
					{ x: 53.17, y: 19.925 },
					{ x: 53.2, y: 20 },
					{ x: 53.23, y: 20.075 },
					{ x: 53.26, y: 20.15 },
					{ x: 53.29, y: 20.225 },
					{ x: 53.32, y: 20.3 },
					{ x: 53.35, y: 20.375 },
					{ x: 53.38, y: 20.45 },
					{ x: 53.41, y: 20.525 },
					{ x: 53.44, y: 20.6 },
					{ x: 53.47, y: 20.675 },
					{ x: 53.5, y: 20.75 },
					{ x: 53.53, y: 20.825 },
					{ x: 53.56, y: 20.9 },
					{ x: 53.59, y: 20.975 },
					{ x: 53.62, y: 21.05 },
					{ x: 53.65, y: 21.125 },
					{ x: 53.68, y: 21.2 },
					{ x: 53.71, y: 21.275 },
					{ x: 53.74, y: 21.35 },
					{ x: 53.77, y: 21.425 },
					{ x: 53.8, y: 21.5 },
					{ x: 53.83, y: 21.575 },
					{ x: 53.86, y: 21.65 },
					{ x: 53.89, y: 21.725 },
					{ x: 53.92, y: 21.8 },
					{ x: 53.95, y: 21.875 },
					{ x: 53.98, y: 21.95 },
					{ x: 54.01, y: 22.025 },
					{ x: 54.04, y: 22.1 },
					{ x: 54.07, y: 22.175 },
					{ x: 54.1, y: 22.25 },
					{ x: 54.13, y: 22.325 },
					{ x: 54.16, y: 22.4 },
					{ x: 54.19, y: 22.475 },
					{ x: 54.22, y: 22.55 },
					{ x: 54.25, y: 22.625 },
					{ x: 54.28, y: 22.7 },
					{ x: 54.31, y: 22.775 },
					{ x: 54.34, y: 22.85 },
					{ x: 54.37, y: 22.925 },
					{ x: 54.4, y: 23 },
					{ x: 54.43, y: 23.075 },
					{ x: 54.46, y: 23.15 },
					{ x: 54.49, y: 23.225 },
					{ x: 54.52, y: 23.3 },
					{ x: 54.55, y: 23.375 },
					{ x: 54.58, y: 23.45 },
					{ x: 54.61, y: 23.525 },
					{ x: 54.64, y: 23.6 },
					{ x: 54.67, y: 23.675 },
					{ x: 54.7, y: 23.75 },
					{ x: 54.73, y: 23.825 },
					{ x: 54.76, y: 23.9 },
					{ x: 54.79, y: 23.975 },
					{ x: 54.82, y: 24.05 },
					{ x: 54.85, y: 24.125 },
					{ x: 54.88, y: 24.2 },
					{ x: 54.91, y: 24.275 },
					{ x: 54.94, y: 24.35 },
					{ x: 54.97, y: 24.425 },
					{ x: 55, y: 24.5 },
					{ x: 55.03, y: 24.575 },
					{ x: 55.06, y: 24.65 },
					{ x: 55.09, y: 24.725 },
					{ x: 55.12, y: 24.8 },
					{ x: 55.15, y: 24.875 },
					{ x: 55.18, y: 24.95 },
					{ x: 55.21, y: 25.025 },
					{ x: 55.24, y: 25.1 },
					{ x: 55.27, y: 25.175 },
					{ x: 55.3, y: 25.25 },
					{ x: 55.33, y: 25.325 },
					{ x: 55.36, y: 25.4 },
					{ x: 55.39, y: 25.475 },
					{ x: 55.42, y: 25.55 },
					{ x: 55.45, y: 25.625 },
					{ x: 55.48, y: 25.7 },
					{ x: 55.51, y: 25.775 },
					{ x: 55.54, y: 25.85 },
					{ x: 55.57, y: 25.925 },
					{ x: 55.6, y: 26 },
					{ x: 55.63, y: 26.075 },
					{ x: 55.66, y: 26.15 },
					{ x: 55.69, y: 26.225 },
					{ x: 55.72, y: 26.3 },
					{ x: 55.75, y: 26.375 },
					{ x: 55.78, y: 26.45 },
					{ x: 55.81, y: 26.525 },
					{ x: 55.84, y: 26.6 },
					{ x: 55.87, y: 26.675 },
					{ x: 55.9, y: 26.75 },
					{ x: 55.93, y: 26.825 },
					{ x: 55.96, y: 26.9 },
					{ x: 55.99, y: 26.975 },
					{ x: 55.96, y: 26.9 },
					{ x: 55.99, y: 26.975 },
					{ x: 56.02, y: 27.05 },
					{ x: 56.05, y: 27.125 },
					{ x: 56.08, y: 27.2 },
					{ x: 56.11, y: 27.275 },
					{ x: 56.14, y: 27.35 },
					{ x: 56.17, y: 27.425 },
					{ x: 56.2, y: 27.5 },
					{ x: 56.23, y: 27.575 },
					{ x: 56.26, y: 27.65 },
					{ x: 56.29, y: 27.725 },
					{ x: 56.32, y: 27.8 },
					{ x: 56.35, y: 27.875 },
					{ x: 56.38, y: 27.95 },
					{ x: 56.41, y: 28.025 },
					{ x: 56.44, y: 28.1 },
					{ x: 56.47, y: 28.175 },
					{ x: 56.5, y: 28.25 },
					{ x: 56.53, y: 28.325 },
					{ x: 56.56, y: 28.4 },
					{ x: 56.59, y: 28.475 },
					{ x: 56.62, y: 28.55 },
					{ x: 56.65, y: 28.625 },
					{ x: 56.68, y: 28.7 },
					{ x: 56.71, y: 28.775 },
					{ x: 56.74, y: 28.85 },
					{ x: 56.77, y: 28.925 },
					{ x: 56.8, y: 29 },
					{ x: 56.83, y: 29.075 },
					{ x: 56.86, y: 29.15 },
					{ x: 56.89, y: 29.225 },
					{ x: 56.92, y: 29.3 },
					{ x: 56.95, y: 29.375 },
					{ x: 56.98, y: 29.45 },
					{ x: 57.01, y: 29.525 },
					{ x: 57.04, y: 29.6 },
					{ x: 57.07, y: 29.675 },
					{ x: 57.1, y: 29.75 },
					{ x: 57.13, y: 29.825 },
					{ x: 57.16, y: 29.9 },
					{ x: 57.19, y: 29.975 },
					{ x: 57.22, y: 30.05 },
					{ x: 57.25, y: 30.125 },
					{ x: 57.28, y: 30.2 },
					{ x: 57.31, y: 30.275 },
					{ x: 57.34, y: 30.35 },
					{ x: 57.37, y: 30.425 },
					{ x: 57.4, y: 30.5 },
					{ x: 57.43, y: 30.575 },
					{ x: 57.46, y: 30.65 },
					{ x: 57.49, y: 30.725 },
					{ x: 57.52, y: 30.8 },
					{ x: 57.55, y: 30.875 },
					{ x: 57.58, y: 30.95 },
					{ x: 57.61, y: 31.025 },
					{ x: 57.64, y: 31.1 },
					{ x: 57.67, y: 31.175 },
					{ x: 57.7, y: 31.25 },
					{ x: 57.73, y: 31.325 },
					{ x: 57.76, y: 31.4 },
					{ x: 57.79, y: 31.475 },
					{ x: 57.82, y: 31.55 },
					{ x: 57.85, y: 31.625 },
					{ x: 57.88, y: 31.7 },
					{ x: 57.91, y: 31.775 },
					{ x: 57.94, y: 31.85 },
					{ x: 57.97, y: 31.925 },
					{ x: 58, y: 32 },
					{ x: 58.03, y: 32.075 },
					{ x: 58.06, y: 32.15 },
					{ x: 58.09, y: 32.225 },
					{ x: 58.12, y: 32.3 },
					{ x: 58.15, y: 32.375 },
					{ x: 58.18, y: 32.45 },
					{ x: 58.21, y: 32.525 },
					{ x: 58.24, y: 32.6 },
					{ x: 58.27, y: 32.675 },
					{ x: 58.3, y: 32.75 },
					{ x: 58.33, y: 32.825 },
					{ x: 58.36, y: 32.9 },
					{ x: 58.39, y: 32.975 },
					{ x: 58.42, y: 33.05 },
					{ x: 58.45, y: 33.125 },
					{ x: 58.48, y: 33.2 },
					{ x: 58.51, y: 33.275 },
					{ x: 58.54, y: 33.35 },
					{ x: 58.57, y: 33.425 },
					{ x: 58.6, y: 33.5 },
					{ x: 58.63, y: 33.575 },
					{ x: 58.66, y: 33.65 },
					{ x: 58.69, y: 33.725 },
					{ x: 58.72, y: 33.8 },
					{ x: 58.75, y: 33.875 },
					{ x: 58.78, y: 33.95 },
					{ x: 58.81, y: 34.025 },
					{ x: 58.84, y: 34.1 },
					{ x: 58.87, y: 34.175 },
					{ x: 58.9, y: 34.25 },
					{ x: 58.93, y: 34.325 },
					{ x: 58.96, y: 34.4 },
					{ x: 58.99, y: 34.475 },
					{ x: 59.02, y: 34.55 },
					{ x: 59.05, y: 34.625 },
					{ x: 59.08, y: 34.7 },
					{ x: 59.11, y: 34.775 },
					{ x: 59.14, y: 34.85 },
					{ x: 59.17, y: 34.925 },
					{ x: 59.2, y: 35 },
					{ x: 59.23, y: 35.075 },
					{ x: 59.26, y: 35.15 },
					{ x: 59.29, y: 35.225 },
					{ x: 59.32, y: 35.3 },
					{ x: 59.35, y: 35.375 },
					{ x: 59.38, y: 35.45 },
					{ x: 59.41, y: 35.525 },
					{ x: 59.44, y: 35.6 },
					{ x: 59.47, y: 35.675 },
					{ x: 59.5, y: 35.75 },
					{ x: 59.53, y: 35.825 },
					{ x: 59.56, y: 35.9 },
					{ x: 59.59, y: 35.975 },
					{ x: 59.62, y: 36.05 },
					{ x: 59.65, y: 36.125 },
					{ x: 59.68, y: 36.2 },
					{ x: 59.71, y: 36.275 },
					{ x: 59.74, y: 36.35 },
					{ x: 59.77, y: 36.425 },
					{ x: 59.8, y: 36.5 },
					{ x: 59.83, y: 36.575 },
					{ x: 59.86, y: 36.65 },
					{ x: 59.89, y: 36.725 },
					{ x: 59.92, y: 36.8 },
					{ x: 59.95, y: 36.875 },
					{ x: 59.98, y: 36.95 },
					{ x: 60, y: 37 }
				]
			} else {
				power_increase_dataset.data = [
					{ x: 0, y: 0 },
					{ x: 1.2, y: 2 },
					{ x: 12, y: 2 },
					{ x: 12, y: 2 },
					{ x: 28, y: 2 },
					{ x: 28, y: 2 },
					{ x: 33, y: 2 },
					{ x: 33, y: 2 },
					{ x: 41, y: 2 },
					{ x: 41, y: 2 },
					{ x: 50, y: 2 },
					{ x: 50, y: 2 },
					{ x: 52, y: 2 },
					{ x: 56, y: 2 },
					{ x: 58, y: 2 },
					{ x: 60, y: 2 }
				]
			}
			this.chart.update()
		},
	}
}
</script>