<template>
    <div class="fade" :class="show ? 'shown' : ''">
        <slot></slot>
    </div>
</template>

<style scoped>
.fade {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}
.fade.shown {
    opacity: 1.0 !important;
}
</style>

<script>
export default {
    name: 'FadeDelay',
    props: {
        delay: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data: () => ({
        show: false
    }),
    mounted () {
        // console.log('mounted with delay ' + this.delay)
        setTimeout(() => {
            this.show = true
            // console.log('delay finished')
        }, this.delay)
    }
}
</script>