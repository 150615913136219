<template>
    <div class="arrange-demo">
        <v-container class="py-0 center-all">
            <div class="text">
                <h1 class="mouse-hover hover-invert">Arrange A Demo</h1>
                <p class="mouse-hover hover-invert">
                    By opting for a demo, you get a <span>hands on</span> experience, of the potential and ease of operation of the system.<br><br>
                    Our responsible team will come into contact with your side and coordinate the logistics for the demonstration, at your preferred place and time.
                </p>
                <v-btn rounded large outlined color="primary" class="mouse-hover hover-hide ma-1" to="/demo">Arrange A Demo</v-btn>
            </div>
        </v-container>
    </div>
</template>

<style scoped>
.arrange-demo {
    position: relative;
    z-index: 1;
    background-color: var(--v-background-base);
    overflow: hidden;
}
.text {
    padding: 2rem;
    max-width: 720px;
    text-align: center;
}
.text span {
    font-weight: 800;
}
</style>

<script>
export default {
    name: 'ArrangeDemo'
}
</script>