<template>
    <vue-horizontal-timeline :items="timeline" timeline-background="transparent" content-class="mouse-hover hover-invert text-center" title-class="mouse-hover hover-invert text-center" text-color="var(--v-primary-base)" line-color="var(--v-accent-base)" line-thickness="0.5rem" point-color="var(--v-primary-base)" point-size="1.5rem"></vue-horizontal-timeline>
</template>

<style>
.vue-horizontal-timeline {
    box-shadow: none !important;
}
.vue-horizontal-timeline .time {
    width: 100%;
}
.vue-horizontal-timeline .timeline {
    padding: 14rem 0 !important;
    /* position: absolute; */
    overflow: hidden !important;
}
.vue-horizontal-timeline .timeline img {
    height: auto;
    width: 100%;
    border-radius: 32px;
    padding: 1rem;
}
.vue-horizontal-timeline .timeline .opposite {
    display: block;
    font-weight: 400 !important;
    font-size: 1.6rem !important;
    font-family: 'Economica', sans-serif;
}
.vue-horizontal-timeline .timeline li:nth-child(even) .opposite {
    margin-top: -1.2rem;
}
.vue-horizontal-timeline .timeline .content {
    font-weight: 600 !important;
}

/* Center First And Last On Screen */
.vue-horizontal-timeline .timeline li:first-of-type, .vue-horizontal-timeline .timeline li:last-of-type {
    width: calc(50vw - 360px / 2) !important; /* 360px is the width of each li */
}
@media screen and (max-width: 360px) {
	.vue-horizontal-timeline .timeline li {
        width: 80vw !important;
    }
    .vue-horizontal-timeline .timeline li:first-of-type {
        width: calc((50vw - 80vw / 2) + 15px) !important; /* 80vw is the width of each li */
    }
    .vue-horizontal-timeline .timeline li:last-of-type {
        width: calc(50vw - 80vw / 2) !important; /* 80vw is the width of each li */
    }
}
</style>

<script>
export default {
    name: 'CompanyTimeline',
	data: () => ({
        timeline: [
            {}, // Blank Space at the start
            {
                year: 'Q1 2018',
                image: require('@/assets/history-timeline/q1_2018.png'),
                content: 'Magnetic crawler platform development',
                color: '#9E9E9E'
            },
            {
                year: 'Q3 2018',
                image: require('@/assets/history-timeline/q3_2018.png'),
                content: 'Underwater capability added',
                color: '#9E9E9E'
            },
            {
                year: '2019',
                divider: true,
                color: '#FFFFFF'
            },
            {
                year: 'Q2 2019',
                image: require('@/assets/history-timeline/q2_2019.jpg'),
                content: 'Robotic grooming idea',
                color: '#9E9E9E'
            },
            {
                year: '2020',
                divider: true,
                color: '#FFFFFF'
            },
            {
                year: 'Q2 2020',
                image: require('@/assets/history-timeline/q2_2020.jpg'),
                content: 'Grooming operation completed manually in real environment',
                color: '#9E9E9E'
            },
            {
                year: 'Q3 2020',
                image: require('@/assets/history-timeline/q3_2020.png'),
                content: 'Mass Challenge accelerator finalists & BIG EEA Grant Awardees',
                color: '#9E9E9E'
            },
            {
                year: '2021',
                divider: true,
                color: '#FFFFFF'
            },
            {
                year: 'Q1 2021',
                image: require('@/assets/history-timeline/q1_2021.png'),
                content: 'Company Foundation',
                color: '#9E9E9E'
            },
            {
                year: 'Q1 2021',
                image: require('@/assets/history-timeline/q1_2021.jpg'),
                content: 'First customer commitments',
                color: '#9E9E9E'
            },
            {
                year: 'Q3 2021',
                image: require('@/assets/history-timeline/q3_2021.jpg'),
                content: 'Autonomous navigation feature development'
            },
            {
                year: 'Q4 2021',
                title: 'POC',
                content: 'Pilot Project'
            },
            {
                year: '2022',
                divider: true,
                color: '#FFFFFF'
            },
            {
                year: 'Q1 2022',
                image: require('@/assets/history-timeline/q1_2022.jpg'),
                content: 'Production planning'
            },
            {
                year: 'Q2 2022',
                image: require('@/assets/history-timeline/q2.1_2022.png'),
                content: 'Pilot Product Validation'
            },
            {
                year: 'Q2 2022',
                image: require('@/assets/history-timeline/q2.2_2022.png'),
                content: 'Launch of marketing strategy'
            },
            {
                year: 'Q3 2022',
                title: 'Product Launch'
            },
        ]
	})
}
</script>