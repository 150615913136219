<template>
    <div class="savings-chart" :class="info_text ? 'mouse-hover hover-hide' : ''">
        <canvas ref="chart" :class="info_text ? 'mouse-hover hover-hide' : ''"></canvas>
        <div class="info-text center-all">
            <div v-if="info_text" class="text-center">
                <h1>{{ info_text.title }}</h1>
                <h4>{{ info_text.subtitle }}</h4>
            </div>
            <h4 class="text-center" v-else>Choose a vessel to see savings</h4>
        </div>
    </div>
</template>

<style scoped>
.savings-chart {
    position: relative;
    height: calc(var(--vh, 1vh) * 70);
    padding: 2rem;
}
.savings-chart canvas {
    height: 100% !important;
}
.info-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 9rem;
    height: 8rem;
}
</style>

<script>
import Chart from 'chart.js/auto'

export default {
	name: 'SavingsChart',
	props: {
		data: {
			type: Array,
			required: true
		},
        label: {
            
        },
        value: {
            type: String,
            required: true
        }
	},
	data: () => ({
        chart: null,
        info_text: null
    }),
    computed: {
        chart_data () {
            let resp = {
                labels: [],
                datasets: [
                    {
                        label: 'Annual Savings',
                        data: null,
                        backgroundColor: [],
                        hoverBackgroundColor: '#fa7c03',
                        hoverOffset: 10
                    }
                ]
            }

            resp.labels = this.data.map(vessel => vessel.type)
            resp.datasets[0].backgroundColor = this.data.map(vessel => vessel.color)
            resp.datasets[0].data = this.data.map(vessel => vessel.results[this.value])

            return resp
        }
    },
    mounted () {
        this.chart = new Chart(this.$refs.chart.getContext('2d'), {
			type: 'doughnut',
			data: this.chart_data,
            options: {
				responsive: true,
				maintainAspectRatio: false,
                onHover: (e, item) => {

                    if (item && item[0]) {
                        let vessel = this.data[item[0].index]

                        this.info_text = {
                            title: vessel.results[this.value],
                            subtitle: vessel.type
                        }
                    } else {
                        this.info_text = null
                    }
                },
				plugins: {
					legend: {
						display: false
					},
                    tooltip: {
                        enabled: false
                    }
				}
            }
		})
    }
}
</script>