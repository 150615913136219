<template>
	<div id="home">
		<Intro data-aos="fade" data-aos-duration="3000"></Intro>
		<Story ref="story"></Story>
		<Benefits></Benefits>
	</div>
</template>

<style scoped>
#home {
	overflow: hidden;
	background: var(--v-background-base);
}
#home > *:not(.story) {
	position: relative;
	z-index: 1;
}
.intro {
	padding-top: var(--abh);
}
.intro:after {
	content: '';
	position: absolute;
	bottom: -4rem;
	height: 4rem;
	width: 100%;
	background-image: linear-gradient(to bottom, var(--v-background-base), transparent);
}
</style>

<script>
import Intro from '@/components/Home/Intro'
import Story from '@/components/Home/Story'
import Benefits from '@/components/Home/Benefits'
export default {
	name: 'Home',
	components: {
		Intro,
		Story,
		Benefits
	},
	methods: {
        on_scroll () {
            this.$refs.story.on_scroll()
        }
	}
}
</script>