<template>
    <div class="benefits">
        <v-container class="py-0">
            <v-layout row>
                <v-flex xs12 class="center-all">
                    <div class="text mouse-hover hover-hide">
                        <h1>Benefits</h1>
                    </div>
                </v-flex>
                <v-flex xs12 class="mb-4">
                    <v-container class="text-center">
                        <v-chip v-for="(vessel, i) in vessels_data" :key="i" class="ma-2" color="white black--text">
                            <v-icon :color="vessel.color" left>fas fa-circle</v-icon>
                            <span style="font-size: 0.8rem;">{{ vessel.type }}</span>
                        </v-chip>
                    </v-container>
                </v-flex>
                <v-flex xs12 md6>
                    <h2 class="text-center mouse-hover hover-hide">Annual CO2 Savings, mt</h2>
                    <v-lazy>
                        <div class="savings-chart">
                            <SavingsChart :data="comp_vessels_data" value="annual_co2_savings"></SavingsChart>
                        </div>
                    </v-lazy>
                </v-flex>
                <v-flex xs12 md6>
                    <h2 class="text-center mouse-hover hover-hide">Annual Fuel Savings (KU$)</h2>
                    <v-lazy>
                        <SavingsChart :data="comp_vessels_data" value="annual_fuel_savings"></SavingsChart>
                    </v-lazy>
                </v-flex>
                <v-flex xs12 md6>
                    <div style="width: max-content; margin: 0.5rem auto;">
                        <v-select v-model="fuel_type" hint="Select Fuel Type (Default: HFO)" persistent-hint filled rounded :items="fuel_types"></v-select>
                    </div>
                </v-flex>
                <v-flex xs12 md6>
                    <div style="width: max-content; margin: 0.5rem auto;">
                        <v-text-field v-model="fuel_price" hint="Select Fuel Price (Default: 450)" persistent-hint filled rounded prepend-inner-icon="fas fa-dollar-sign small-icon"></v-text-field>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout row class="justify-center mt-12 pt-12">
                <v-flex xs12 class="mb-8 mx-4 text-justify">
                    <p class="mouse-hover hover-invert">
                        While the world governing body (IMO) pushes for operational measures to reduce emissions, other independent authorities such as the European Union take the lead to include the shipping industry in their Emissions Trade Scheme (ETS). In addition to this turmoil, increasing signatories to the Sea Cargo Charter aim to define ‘responsible ship chartering’ and financial institutions establish their pivotal position to monitor and consolidate their portfolios towards ‘responsible’ and environmentally compliant ship financing, through the Poseidon Principles. 
                    </p>
                    <p class="mouse-hover hover-invert">
                        Recognising the significant pressure on the ship manager, we aim to support the industry by incorporating our robotic underwater proactive grooming energy saving device (ESD), as an integrated part of the vessel’s system to maintain the vessel’s energy efficiency index through time. 
                    </p>
                    <p class="mouse-hover hover-invert">
                        As we envision emission credit system(s) materialising and anticipate the actual Carbon Intensity Index (CII) of an asset, to be heavily scrutinised - through the industry’s financial and commercial channels; we consider our ship’s integrated robotic application to hull performance, the new norm. A new norm, that shall significantly differentiate the innovative, early adopters – who shall capitalise on decarbonising investments; demonstrating their Environmental Social Governance, by effectively preparing their fleet - to navigate the uncertainties approaching their bow. 
                    </p>
                    <p class="mouse-hover hover-invert">
                    Click on the scenarios to find our in-depth analysis, for both the ESD Cost Benefit Analysis and the Effect on the Charter Party DOC Table.
                    </p>
                </v-flex>
                <v-flex v-for="(pdf, i) in pdfs" :key="i" xs12 md6 class="center-all">
                    <v-btn rounded large outlined color="primary" class="mouse-hover hover-hide ma-4" @click="selected_pdf = pdf">
                        <v-icon left>fas fa-file-alt</v-icon>
                        {{ pdf.title }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>

        <PDFDialog v-if="selected_pdf" v-model="pdf_dialog" :src="selected_pdf.src" :title="selected_pdf.title"></PDFDialog>
    </div>
</template>

<style scoped>
.benefits {
    position: relative;
    z-index: 1;
    background-color: var(--v-background-base);
    overflow: hidden;
    padding: 6rem 0;
}
.text {
    padding: 2rem;
    max-width: 480px;
    text-align: center;
}
</style>

<script>
import SavingsChart from '@/components/Charts/SavingsChart'
import PDFDialog from '@/components/PDFDialog'
export default {
    name: 'Benefits',
    components: {
        SavingsChart,
        PDFDialog
    },
    data: () => ({
        // User Inputs
        fuel_price: 450,
        fuel_type: 3.1144,

        fuel_types: [
            {
                text: 'HFO',
                value: 3.1144
            },
            {
                text: 'Hybrid',
                value: 3.151
            },
            {
                text: 'LSFO',
                value: 3.151
            },
            {
                text: 'LNG',
                value: 2.75
            },
            {
                text: 'MDO | MGO',
                value: 3.206
            }
        ],

        // Defaults
        ballast_operation: 24.5 / 100, // from assumptions
        laden_operation: 43 / 100, // from assumptions

        vessels_data: [
            {
                type: 'VLCC',
                color: '#4385d5',
                data: {
                    ballast_dfoc: 70,
                    laden_dfoc: 90,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 15.5 / 100
                }
            },
            {
                type: 'Suezmax',
                color: '#d84441',
                data: {
                    ballast_dfoc: 35.6,
                    laden_dfoc: 38.9,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 15.5 / 100
                }
            },
            {
                type: 'Aframax',
                color: '#9ac04a',
                data: {
                    ballast_dfoc: 29.3,
                    laden_dfoc: 34.3,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 13.3 / 100
                }
            },
            {
                type: 'Panamax',
                color: '#7958a2',
                data: {
                    ballast_dfoc: 23.7,
                    laden_dfoc: 26.0,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 17.9 / 100
                }
            },
            {
                type: 'Handymax',
                color: '#39b2d3',
                data: {
                    ballast_dfoc: 21.5,
                    laden_dfoc: 24.4,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 17.1 / 100
                }
            },
            {
                type: 'Feedermax',
                color: '#ff9630',
                data: {
                    ballast_dfoc: 65.4,
                    laden_dfoc: 23.6,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 17.1 / 100
                }
            },
            {
                type: 'Capesize',
                color: '#244f83',
                data: {
                    ballast_dfoc: 37.4,
                    laden_dfoc: 43.7,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 15.0 / 100
                }
            },
            {
                type: 'Kamsarmax',
                color: '#812421',
                data: {
                    ballast_dfoc: 21.75,
                    laden_dfoc: 24.1,
                    current_ppi: 0.0 / 100,
                    days_till_milestone: 1800,
                    ppi_at_milestone: 1.5 / 100,
                    ppi_at_milestone_no_robot: 14.4 / 100
                }
            }
        ],

        pdfs: [
            {
                title: 'ESD Cost Benefit Analysis',
                src: '/assets/pdf/esd_cost_benefit_analysis.pdf'
            },
            {
                title: 'Effect on Charter Party FOC Table',
                src: '/assets/pdf/effect_on_charter_party_foc_table.pdf'
            }
        ],
        selected_pdf: null
    }),
    computed: {
        comp_vessels_data () {
            let vessels_data = JSON.parse(JSON.stringify(this.vessels_data))
            vessels_data.forEach(vessel => {

                let calculations = this.vesselCalculations(vessel.data)

                let results = this.vesselResults(calculations)

                vessel.calculations = calculations
                vessel.results = results
            })

            return vessels_data
        },
        pdf_dialog: {
            get () {
                return !!this.selected_pdf
            },
            set (v) {
                if (!v) this.selected_pdf = null
            }
        }
    },
    methods: {
        vesselCalculations (data) {
            let resp = {}

            resp.add_hfo_until_milestone = (data.ppi_at_milestone + data.current_ppi) / 2 * data.days_till_milestone * (data.ballast_dfoc * this.ballast_operation + data.laden_dfoc * this.laden_operation)
            resp.cost_of_add_fuel = (resp.add_hfo_until_milestone * this.fuel_price) / 1000

            resp.add_hfo_until_milestone_no_robot = (data.ppi_at_milestone_no_robot + data.current_ppi) / 2 * data.days_till_milestone * (data.ballast_dfoc * this.ballast_operation + data.laden_dfoc * this.laden_operation)
            resp.cost_of_add_fuel_no_robot = (resp.add_hfo_until_milestone_no_robot * this.fuel_price) / 1000

            return resp
        },

        vesselResults (calculations) {
            let resp = {}

            resp.fivey_fuel_savings = parseInt(calculations.cost_of_add_fuel_no_robot - calculations.cost_of_add_fuel)
            resp.fivey_co2_savings = parseInt((resp.fivey_fuel_savings * 1000 / this.fuel_price) * this.fuel_type)

            resp.annual_fuel_savings = parseInt(resp.fivey_fuel_savings / 5)
            resp.annual_co2_savings = parseInt(resp.fivey_co2_savings / 5)

            return resp
        }
    }

}
</script>