import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueKinesis from 'vue-kinesis'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueHorizontalTimeline from "vue-horizontal-timeline"

import FadeDelay from '@/components/FadeDelay'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['model-viewer']
Vue.prototype.$appName = 'Scrufy'
Vue.prototype.$app_bar_height = 96
Vue.prototype.$api_url = 'https://api.scrufy.co/api/'

Vue.use(VueKinesis)
Vue.use(VueHorizontalTimeline)

Vue.component('FadeDelay', FadeDelay)

Vue.use(VueAxios, axios.create({
	baseURL: Vue.prototype.$api_url
}))

new Vue({
	router,
	vuetify,
	store,
	created () {
		AOS.init({
			mirror: true
		})
	},
	render: h => h(App)
}).$mount('#app')
