<template>
    <div class="model-container mouse-hover hover-hide">
        <model-viewer src="/assets/scrufy.glb" camera-controls camera-orbit="7.377deg 90.12deg 1.545m" min-camera-orbit="auto auto auto" max-camera-orbit="auto auto 1.545m" :auto-rotate="!selected_hotspot" disable-zoom="true" environment-image="/assets/lightroom_14b.hdr" skybox-image="/assets/skybox3.jpg" shadow-intensity="0" exposure="0.8" :class="{ loaded: model_loaded }" auto-rotate-delay="5000" v-on:model-visibility="modelVisibility">
            <button v-for="hotspot in hotspots" :key="hotspot.id" class="hotspot mouse-hover hover-hide" :slot="`hotspot-${hotspot.id}`" :data-position="hotspot.position" :data-normal="hotspot.normal" data-visibility-attribute="visible" @click="selected_hotspot = hotspot">
                <div class="hotspot-annotation" @click="selected_hotspot = hotspot">{{ hotspot.name }}</div>
            </button>
            <div class="progress-bar hide" slot="progress-bar">
                <div class="update-bar"></div>
            </div>
        </model-viewer>
        <h1 class="page-title">The Tech</h1>
        <div v-if="selected_hotspot" class="selected-hotspot" :class="{ blur: hotspot_step > 0, show: hotspot_step > 1, details: hotspot_step > 2 }">
            <model-viewer :src="selected_hotspot.model" camera-controls camera-orbit="7.377deg 90.12deg 1.545m" min-camera-orbit="auto auto auto" max-camera-orbit="auto auto 1.545m" auto-rotate environment-image="neutral" shadow-intensity="0" exposure="0.6">
                <div class="progress-bar hide" slot="progress-bar">
                    <div class="update-bar"></div>
                </div>
            </model-viewer>
            <div class="hotspot-details center-all">
                <div>
                    <h2>{{ selected_hotspot.name }}</h2>
                    <p>{{ selected_hotspot.description }}</p>
                </div>
            </div>
            <v-btn outlined icon class="close-btn mouse-hover hover-hide" @click="selected_hotspot = null"><v-icon class="mouse-hover hover-hide">fas fa-times</v-icon></v-btn>
        </div>
    </div>
</template>

<style scoped>
.model-container {
    position: relative;
    height: calc(var(--vh, 1vh) * 100 + 4rem);
}
.model-container:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 4rem;
    width: 100%;
    background-image: linear-gradient(to top, var(--v-background-base), transparent);
}
.model-container h1 {
    position: absolute;
    top: var(--abh);
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}
model-viewer {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--v-background-base);
    --poster-color: var(--v-background-base);
}
.hotspot {
    background: transparent;
    border-radius: 32px;
    border: 3px solid #ffffff;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;
    box-sizing: border-box;
    cursor: pointer;
    height: 12px;
    padding: 4px;
    position: relative;
    transition: opacity 0.3s ease 0s;
    width: 12px;
    overflow: hidden;
    opacity: 0.2;
}
model-viewer:not(.loaded) .hotspot {
    opacity: 0;
}
.hotspot[data-visible] {
    background: #ffffff;
    border: none;
    height: 24px;
    width: 24px;
    opacity: 1;
}
.hotspot[data-visible]:hover {
    overflow: visible;
}

.hotspot:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--v-primary-base);
    width: 100%;
    height: 100%;
    border-radius: 32px;
    transform: scale(0);
}
.hotspot[data-visible]:hover:before {
    transform: scale(1);
}

.hotspot-annotation {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    font-family: 'Economica', sans-serif;
    font-size: 18px;
    font-weight: 700;
    left: calc(100% + 1em);
    max-width: 128px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    top: 50%;
    width: max-content;
    /* display: none; */
    opacity: 0;
    transition: opacity 0.5s ease-in;
}
.hotspot[data-visible]:hover .hotspot-annotation {
    /* display: block; */
    opacity: 1;
}

.selected-hotspot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4rem);
    padding-top: var(--abh);
    backdrop-filter: blur(0);
    transition: backdrop-filter 0.45s linear;
    z-index: 3;
}
.selected-hotspot.blur {
    backdrop-filter: blur(8px) brightness(0.7);
}
.selected-hotspot model-viewer {
    background-color: transparent;
    --poster-color: transparent;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in, width 0.5s ease-in;
}
.selected-hotspot.show model-viewer {
    transform: scale(1);
    opacity: 1;
}
.selected-hotspot.details model-viewer {
    width: 50%;
}
.selected-hotspot .hotspot-details {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in 0.5s;
    margin-top: var(--abh);
    padding: 2rem;
}
@media only screen and (max-width: 960px) {
    .selected-hotspot {
        overflow-y: scroll;
    }
    .selected-hotspot.details model-viewer {
        width: 100%;
        height: 60%;
    }
    .selected-hotspot .hotspot-details {
        position: relative;
        top: unset;
        left: unset;
        width: 100%;
        height: auto;
        margin-top: 0;
        padding: 1rem;
    }
}
.selected-hotspot.details .hotspot-details {
    opacity: 1;
}

.selected-hotspot .hotspot-details h2 {
    margin-bottom: 2rem;
}
.selected-hotspot .close-btn {
    position: absolute;
    top: calc(var(--abh) + 2rem);
    right: 2rem;
}
</style>


<style>
.model-container .selected-hotspot .close-btn.v-btn--outlined:hover > .v-btn__content {
	color: var(--v-primary-base);
}
</style>

<script>
export default {
    name: 'Model3D',
    data: () => ({
        model_loaded: false,
        hotspots: [
            {
                id: 0,
                name: 'Grooming Module',
                position: '0.2264563489410901m 0.024278216942171915m 0.4835114061264743m',
                normal: '-2.35297769493276e-8m 0.21923447728887968m 0.9756722010838841m',
                model: '/assets/exploded/brush.glb',
                description: 'A motorized polyamide brush combo featuring easy attachment to the robot\'s body, provides grooming speed while avoiding coating damage.'
            },
            {
                id: 1,
                name: 'Track System',
                position: '-0.3702411635366374m 0.007607795027445935m 0.23949434512568976m',
                normal: '-0.9999999999999996m -7.58009517281557e-16m 3.4228543333014734e-8m',
                model: '/assets/exploded/belt.glb',
                description: 'Scrufy\'s intelligent track is bult around a permanent magnet assembly and uses soft rubber belt for smooth surface engagement and coating protection.'
            },
            {
                id: 2,
                name: 'Multi-Spectral Imaging Camera',
                position: '-0.07378108872345979m 0.17903191475794125m 0.26790705850921914m',
                normal: '0.8896571912885622m 0.45662904198982535m -3.0451670089921535e-8m',
                model: '/assets/exploded/ebox.glb',
                description: 'The use of multi spectral imaging allows for microfouling level identification at a very early stage (below FR20), which cannot be performed by common optical sensors or even the human eye.'
            },
            {
                id: 3,
                name: 'X - Chassis',
                position: '0.11345010106246581m 2.7755575615628914e-17m 0.00835884827280671m',
                normal: '-2.361633319448492e-16m -1m 7.21896987824332e-16m',
                model: '/assets/exploded/xchassis.glb',
                description: 'Tough but flexible, Scrufy\'s modular chassis provides load carrying capacity, as well as compliance to convex and concave surfaces of down to 1m radius.'
            },
            {
                id: 4,
                name: 'Lighting System',
                position: '0.09989282301399244m 0.17226313323363557m -0.22267412745318033m',
                normal: '-0.34766294900228895m 0.012591887686576072m -0.9375350224154405m',
                model: '/assets/exploded/ebox.glb',
                description: 'Powerful underwater LED lights allow for clear visual inspection of hull even in murky waters or at night.'
            },
            {
                id: 5,
                name: 'Navigational and Control System',
                position: '0.19807911079809495m 0.22099999717201105m 0.1406816333185799m',
                normal: '5.299816535744511e-17m 1m 6.661338147750939e-16m',
                model: '/assets/exploded/ebox2.glb',
                description: 'Cutting edge electronics combined with advanced robotic algorithms developed in-house ensure Scrufy navigates efficiently around the vertical sides and flat bottom, keeping your crew always informed and the vessel\'s hull always clean.'
            },
        ],
        selected_hotspot: null,
        hotspot_step: 0
    }),
    watch: {
        selected_hotspot (v) {
            if (!v) {
                document.documentElement.style.overflow = 'auto'
                return this.hotspot_step = 0
            }

            window.scrollTo(0, 0)
            document.documentElement.style.overflow = 'hidden'

            setTimeout(() => {
                this.hotspot_step = 1
            }, 50)

            setTimeout(() => {
                this.hotspot_step = 2
            }, 300)

            setTimeout(() => {
                this.hotspot_step = 3
            }, 1000)
        }
    },
    methods: {
        modelVisibility () {
            setTimeout(() => { this.model_loaded = true }, 800)
        }
    }
}
</script>