<template>
    <v-container class="contact">
        <v-layout row class="my-6">
            <v-flex xs12 md6 class="center-all">
                <v-container fluid>
                    <v-layout row>
                        <v-flex xs12>
                            <h1 class="mouse-hover hover-invert text-center mb-6">Contact Us</h1>
                        </v-flex>
                    </v-layout>
                    <v-layout row class="contact-container">
                        <v-flex xs3 class="center-all pa-4 mouse-hover hover-invert">
                            <v-img :src="require('@/assets/contact-icons/email.svg')" max-height="6rem" contain></v-img>
                        </v-flex>
                        <v-flex xs9 class="center-all">
                            <div class="contact-info">
                                <h2 class="mouse-hover hover-invert">Send us your enquiries</h2>
                                <a href="mailto:info@scrufy.co" class="mouse-hover hover-hide">info@scrufy.co</a>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row class="contact-container">
                        <v-flex xs3 class="center-all pa-4 mouse-hover hover-invert">
                            <v-img :src="require('@/assets/contact-icons/customer-care.svg')" max-height="6rem" contain></v-img>
                        </v-flex>
                        <v-flex xs9 class="center-all">
                            <div class="contact-info">
                                <h2 class="mouse-hover hover-invert">Get answers to your questions</h2>
                                <a href="tel:+302106041425" class="mouse-hover hover-hide">+30 210 604 1425</a>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row class="contact-container">
                        <v-flex xs3 class="center-all pa-4 mouse-hover hover-invert">
                            <v-img :src="require('@/assets/contact-icons/location.svg')" max-height="6rem" contain></v-img>
                        </v-flex>
                        <v-flex xs9 class="center-all">
                            <div class="contact-info">
                                <h2 class="mouse-hover hover-invert">Visit our offices</h2>
                                <a href="https://goo.gl/maps/URgLHq9xd2XyhUpA8" target="_blank" class="mouse-hover hover-hide">1 Davaki Str, 115 26, Athens, Greece</a>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
            <v-flex xs12 md6 class="center-all pa-12">
                <v-img :src="require('@/assets/contact.png')" contain></v-img>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<style scoped>
.contact-container {
    margin: 2rem 0;
}
.contact-info {
    width: 100%;
    padding-left: 0.5rem;
}
.contact-info h2 {
    color: var(--v-primary-base);
}
.contact-info a {
    display: block;
    margin-top: 0.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400 !important;
    font-size: 1.2rem !important;
}
.contact-info a:hover {
    color: var(--v-primary-base) !important;
}
</style>

<script>
export default {
    name: 'Contact'
}
</script>