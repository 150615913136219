<template>
    <kinesis-container :active="$vuetify.breakpoint.mdAndUp" class="intro background">
            <kinesis-element :strength="8" type="depth_inv">
                <FadeDelay style="padding: 2rem;">
                    <v-img :src="require('@/assets/scrufy_intro.png')" class="scrufy-intro" contain></v-img>
                </FadeDelay>
            </kinesis-element>
        <v-container class="intro-text">
            <v-layout row>
                <v-flex xs12 class="text-center">
                    <FadeDelay :delay="800">
                        <h1 class="mouse-hover hover-invert"><span>Scrufy</span> is here to support the deCarbonisation of the Shipping Industry.</h1>
                    </FadeDelay>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12 md6 class="center-all">
                    <FadeDelay :delay="1200">
                        <router-link class="mouse-hover hover-hide" to="/tech">Product Specification</router-link>
                    </FadeDelay>
                </v-flex>
                <v-flex xs12 md6 class="center-all">
                    <FadeDelay :delay="1500">
                        <v-btn rounded large outlined color="primary" class="mouse-hover hover-hide" to="/demo">Arrange a Demo</v-btn>
                    </FadeDelay>
                </v-flex>
            </v-layout>
        </v-container>
    </kinesis-container>
</template>

<style scoped>
.intro {
    min-height: calc((var(--vh, 1vh) * 100));
}
.scrufy-intro {
    min-height: 256px;
    height: calc(var(--vh, 1vh) * 35);
}
.on-mobile .scrufy-intro {
    height: calc(var(--vh, 1vh) * 30);
}

.intro-text {
    max-width: 720px;
    margin: 1rem auto;
    color: var(--v-primary-base);
}
.intro-text h1 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 500 !important;
    padding: 0.5rem;
	font-size: 2.5rem;
}
.intro-text h1 > span {
    color: var(--v-accent-base);
	font-size: 3rem;
}
.intro-text a {
    margin: 1rem;
}
.intro-text a:not(.v-btn) {
    display: inline-block;
}
</style>

<script>
export default {
    name: 'Intro'
}
</script>