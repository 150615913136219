import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
		iconfont: 'fa',
    },
    theme: {
		themes: {
			light: {
				primary: '#fa7c03',
				secondary: '#f0f0f0',
				background: '#ffffff',
				accent: '#000000',
			},
			dark: {
				primary: '#fa7c03',
				secondary: '#333333',
				background: '#071c34',
				accent: '#ffffff'
			}
		},
		dark: true,

		options: {
			customProperties: true,
		}
    },
})