<template>
    <div id="tech">
        <Model3D></Model3D>
        <!-- <h1 class="page-title">The Tech</h1> -->
        <v-container>
            <h2 class="product-description">
                <span>SCRUFY</span> is a magnetic crawler for biofouling inspection and grooming of ship hulls.<br><br>
                The robot features soft tracks to prevent anti-fouling coating damage.<br><br>
                The cleaning is achieved with the soft polyamide brushes, at the stage of micro-fouling / slime.<br><br>
                Ultra-high resolution cameras allow for remote fouling data acquisition and analysis through exclusively developed AI algorithms which map and quantify the level of fouling on the various parts of the hull.<br>
            </h2>

            <v-layout row>
                <v-flex xs12 md6 class="center-all">
                    <v-img :src="require('@/assets/capabilities.jpg')" class="shaped-image" :aspect-ratio="1"></v-img>
                </v-flex>
                <v-flex xs12 md6 class="center-all">
                    <div class="product-capabilities">
                        <h2 class="primary--text mb-4 mouse-hover hover-hide">Product Capabilities</h2>
                        <ul class="mouse-hover hover-hide">
                            <li>Hull Grooming</li>
                            <li>IWS as per class requirements</li>
                            <li>Quantification of biofouling level</li>
                            <li>Autonomous Mapping and reporting of biofouling coating</li>
                            <li>No Damage of anti-fouling coating</li>
                            <li>Autonomous navigation on hull, based on pre-defined path</li>
                        </ul>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12 md6 class="center-all">
                    <v-img :src="require('@/assets/features.jpg')" class="shaped-image left" :aspect-ratio="1"></v-img>
                </v-flex>
                <v-flex xs12 md6 class="center-all">
                    <div class="product-features">
                        <h2 class="primary--text mb-4 mouse-hover hover-hide">Product Features</h2>
                        <ul class="mouse-hover hover-hide">
                            <li>Easily Swapable Grooming Heads</li>
                            <li>2 x UHD Underwater Cameras for visual inspection</li>
                            <li>Multi-spectral camera for biofouling identification in murky and turbulant waters</li>
                            <li>In-house developed algorithms for automated biofouling quantification and reporting</li>
                            <li>Intelligent localisation system</li>
                            <li>Patent-pending magnetic track compound</li>
                            <li>Advanced Navigation Algorithms</li>
                            <li>Video recording of Grooming Operation</li>
                            <li>Easy deployment at anchorage</li>
                        </ul>
                    </div>
                </v-flex>
                <v-flex v-if="$vuetify.breakpoint.mdAndUp" xs12 md6 class="center-all">
                    <v-img :src="require('@/assets/features.jpg')" class="shaped-image right" :aspect-ratio="1"></v-img>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                    <h2 class="primary--text mt-12 mb-4 mouse-hover hover-hide">Deployment Process</h2>
                    <v-img :src="require('@/assets/vessel_path.png')" contain max-height="50vh"></v-img>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card outlined max-width="320px" class="my-4 mx-4" :class="$vuetify.breakpoint.xsOnly ? 'mx-auto' : ''" elevation="0" color="background lighten-2">
                        <v-card-subtitle class="text-center">LEGEND</v-card-subtitle>
                        <v-card-text class="mouse-hover hover-hide">
                            <v-container fluid>
                                <v-layout row>
                                    <v-flex xs3 class="text-center">
                                        <img class="mx-2" :src="require('@/assets/triangle.png')" height="16px" width="12px"/> <br>
                                        <v-icon class="mx-2" color="#5b9bd5">fas fa-long-arrow-alt-right</v-icon> <br>
                                        <v-icon class="mx-2" color="#4472c4">fas fa-long-arrow-alt-right</v-icon> <br>
                                        <img class="mx-2" :src="require('@/assets/umbilical.png')" height="5px" width="32px"/>
                                    </v-flex>
                                    <v-flex xs9>
                                        Deployment Location <br>
                                        Mission Path #1 <br>
                                        Mission Path #2 <br>
                                        Umbilical
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card outlined max-width="320px" class="my-4 mx-4" :class="$vuetify.breakpoint.smAndUp ? 'float-right' : 'mx-auto'" elevation="0" color="background lighten-2">
                        <v-card-subtitle class="text-center">Items Description</v-card-subtitle>
                        <v-card-text class="mouse-hover hover-hide">
                            <v-container fluid>
                                <v-layout row>
                                    <v-flex xs3 class="text-center">
                                        <img class="mx-2" :src="require('@/assets/power_cabinet.png')" height="40px"/> <br>
                                        <img class="mx-2" :src="require('@/assets/umbilical_system.png')" height="40px"/> <br>
                                        <img class="mx-2" :src="require('@/assets/base_station.png')" height="40px"/>
                                    </v-flex>
                                    <v-flex xs9 style="line-height: 45px;">
                                        Power Supply Cabinet <br>
                                        Umbilical System <br>
                                        Base Station with Crawler
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs12>
                    <h2 class="primary--text mt-12 mb-4 mouse-hover hover-hide">Technical Specifications</h2>
                    <v-simple-table class="background">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Specification</th>
                                <th class="text-left">Value / Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in specs" :key="item.name">
                                <td>{{ item.name }}</td>
                                <td>{{ item.value }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<style scoped>
h1.page-title {
    margin-top: var(--abh);
}
.product-description {
    max-width: 1024px;
    text-align: left;
    line-height: 2.2rem;
    margin: 4rem auto;
}
.product-description span {
    color: var(--v-primary-base);
}
.product-capabilities, .product-features {
    padding: 1rem;
}
ul {
    max-width: 600px;
}
h2 {
    margin: 0 auto;
    width: fit-content;
}
</style>

<script>
import Model3D from '@/components/Tech/Model3D'
export default {
    name: 'Tech',
    components: {
        Model3D
    },
    data: () => ({
        specs: [
            {
                name: 'Overall Dimensions',
                value: 'L=732mm, W=605mm, H=255mm'
            },
            {
                name: 'Crawler Deployment Weight',
                value: '65kg (incl. umbilical cord)'
            },
            {
                name: 'Maximum Inspection Moving Speed',
                value: '30 m/min'
            },
            {
                name: 'Maximum Grooming moving speed',
                value: '20 m/min'
            },
            {
                name: 'Grooming width',
                value: '800mm'
            },
            {
                name: 'Input Voltage',
                value: '230 VAC'
            },
            {
                name: 'Crawler Operating Voltage',
                value: '48 VDC'
            },
            {
                name: 'Nominal Power',
                value: '500 W'
            },
            {
                name: 'Maximum Intermittent current draw',
                value: '30 A'
            },
            {
                name: 'Cleaning method',
                value: 'Soft rotating polyamide brush'
            },
            {
                name: 'Operating temperature range',
                value: '-20 – 50 °C'
            },
            {
                name: 'Compliance to curved surfaces',
                value: 'Concave and convex surfaces of ≥1 m radius'
            }
        ]
    })
}
</script>