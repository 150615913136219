<template>
    <v-app-bar flat fixed :height="`${$app_bar_height}px`">
        <FadeDelay v-if="$vuetify.breakpoint.mdAndUp" :delay="delayOnlyAtHome(1800)">
            <a href="https://eeagrants.org" target="_blank" class="home mouse-hover" style="height: 64px;">
                <img :src="require('@/assets/grants/EEA.svg')" height="64px" width="100%" class="mouse-hover mr-4"/>
            </a>
        </FadeDelay>
		<FadeDelay :delay="delayOnlyAtHome(2000)">
            <v-toolbar-title>
                <router-link to="/" class="home mouse-hover" style="height: 64px;">
                    <img :src="require('@/assets/logo_white.svg')" height="64px" class="mouse-hover ml-4"/>
                </router-link>
            </v-toolbar-title>
        </FadeDelay>
        <v-spacer></v-spacer>
        <template v-for="(item, i) in menu">
		    <FadeDelay :delay="delayOnlyAtHome(2250 + (i * 250))" :key="i">
                <router-link v-if="!item.action && !item.dropdown && $vuetify.breakpoint.mdAndUp" :class="$route.path.startsWith(item.to) && item.to !== '/' ? 'router-link-exact-active' : ''" class="px-4 mouse-hover" :to="item.to" :href="item.url" style="line-height: 2.5rem;">{{ item.name }}</router-link>
                <v-menu v-else-if="!item.action && $vuetify.breakpoint.mdAndUp" offset-y open-on-hover>
                    <template v-slot:activator="{ on }">
                        <a class="px-2" :class="$route.path.startsWith('/services/') ? 'router-link-exact-active' : ''" style="line-height: 2.5rem;" v-on="on">{{ item.name }}</a>
                    </template>
                    <v-card class="dropdown-menu pa-1">
                        <v-container fluid class="py-0">
                            <v-layout row>
                                <v-flex xs12 md6 v-for="(itemd, ii) in item.dropdown" :key="ii" class="pa-2">
                                    <router-link :to="itemd.to" style="text-decoration: none !important;">
                                        <v-card flat class="menu-item pa-2">
                                            <img :src="itemd.icon" style="display: block; height: 5rem; padding: 0.5rem;">
                                            <strong>{{ itemd.name }}</strong>
                                            <p style="font-weight: 400; opacity: 0.5;">{{ itemd.description }}</p>
                                        </v-card>
                                    </router-link>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-menu>
                <v-btn v-else-if="item.action && $vuetify.breakpoint.smAndUp" rounded large outlined color="primary" class="mouse-hover hover-hide ml-4 mr-2" :to="item.to" :href="item.url">{{ item.name }}</v-btn>
            </FadeDelay>
        </template>
        <div class="menu-icon" :class="drawer ? 'open' : ''" @click.stop="$emit('drawer')" v-if="!$vuetify.breakpoint.mdAndUp && menu.length > 0">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
        <FadeDelay v-if="$vuetify.breakpoint.mdAndUp" :delay="delayOnlyAtHome(1900)">
            <a href="https://www.innovasjonnorge.no" target="_blank" class="home mouse-hover" style="height: 64px;">
                <img :src="require('@/assets/grants/IN.svg')" height="64px" width="100%" class="mouse-hover ml-4"/>
            </a>
        </FadeDelay>
        <template v-if="$vuetify.breakpoint.smAndDown" v-slot:extension>
            <FadeDelay :delay="delayOnlyAtHome(1800)">
                <a href="https://eeagrants.org" target="_blank" class="home mouse-hover" style="height: 48px;">
                    <img :src="require('@/assets/grants/EEA.svg')" height="48px" width="100%" class="mouse-hover mr-4"/>
                </a>
            </FadeDelay>
            <v-spacer></v-spacer>
            <FadeDelay :delay="delayOnlyAtHome(1900)">
                <a href="https://www.innovasjonnorge.no" target="_blank" class="home mouse-hover" style="height: 48px;">
                    <img :src="require('@/assets/grants/IN.svg')" height="48px" width="100%" class="mouse-hover ml-4"/>
                </a>
            </FadeDelay>
        </template>
        <!-- <v-app-bar-nav-icon color="primary" @click.stop="drawer" v-if="!$vuetify.breakpoint.mdAndUp && menu.length > 0"></v-app-bar-nav-icon> -->
    </v-app-bar>
</template>

<style scoped>
.v-app-bar {
    background-color: transparent !important;
    padding: 0;
    z-index: 6;
}
a {
    position: relative;
    color: var(--v-accent-base) !important;
    text-decoration: none;
    white-space: nowrap;
}
a.v-btn {
    color: var(--v-primary-base) !important;
}
a.home {
    display: inline-block;
    height: 100%;
}

.menu-icon {
    display: inline-block;
    cursor: pointer;
    margin: 0 1rem;
}
.menu-icon {
    margin: 4rem 1.5rem;
    /* transform: scale(1.3); */
}
.menu-icon .bar1, .menu-icon .bar2, .menu-icon .bar3 {
    width: 24px;
    height: 3px;
    background-color: var(--v-primary-base);
    margin: 6px 0;
    transition: 0.4s;
}

.menu-icon.open .bar1 {
    /* -webkit-transform: rotate(-45deg) translate(-9px, 6px); */
    transform: rotate(-45deg) translate(-6px, 7px);
}

.menu-icon.open .bar2 { opacity: 0; }

.menu-icon.open .bar3 {
    /* -webkit-transform: rotate(45deg) translate(-8px, -8px); */
    transform: rotate(45deg) translate(-5.5px, -7px);
}
</style>

<script>
export default {
    name: 'AppBar',
    props: {
        drawer: {
            type: Boolean,
            required: false
        },
        menu: {
            type: Array,
            required: true
        }
    },
    methods: {
        delayOnlyAtHome(timing) {
            return this.$route.path == '/' ? timing : 0
        }
    }
}
</script>