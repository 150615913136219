import { render, staticRenderFns } from "./Story.vue?vue&type=template&id=77971022&scoped=true&"
import script from "./Story.vue?vue&type=script&lang=js&"
export * from "./Story.vue?vue&type=script&lang=js&"
import style0 from "./Story.vue?vue&type=style&index=0&id=77971022&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77971022",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VContainer,VFadeTransition,VIcon})
